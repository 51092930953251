import { isString, union, uniqBy } from 'lodash';

// types
import { RootState } from '../store';
import { IPointGroup, IShapeGroup } from '@/LineTool/types';

// slices
import { getGroupById, selectSvgGroupSet } from '@/Redux/Slices/CanvasSlice';
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';
import {
  Shape,
  SvgGroup,
  isToolForShape,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Point } from '@shapertools/sherpa-svg-generator/Point';

// information that doesn't need to be persisted
const transientState = {
  mostRecentlyAddedShapeID: null,
  mostRecentlyAddedPointID: null,
};

export function updateTransientState(params: {
  mostRecentlyAddedShapeID?: string;
  mostRecentlyAddedPointID?: string;
}) {
  Object.assign(transientState, params);
}

// finds all shape parents for a selection
export const selectGetShapesForPoints =
  (state: RootState) => (selectedGroups: string[] | SvgGroup[]) => {
    // check for shapes
    const shapes: { [id: string]: SvgGroup } = {};
    for (let i = selectedGroups.length; i-- > 0; ) {
      let group = selectedGroups[i];

      if (isString(group)) {
        group = getGroupById(state, group);
      }

      if ((group as IPointGroup).tool?.type === Shape.POINT) {
        const { belongsTo } = (group as IPointGroup).tool.params;
        shapes[belongsTo] = shapes[belongsTo] || getGroupById(state, belongsTo);
        selectedGroups.splice(i, 1);
      }
    }

    return Object.values(shapes);
  };

// checks if selected points are adjacent points on a shape
export const selectIsTwoAdjacentPoints =
  (state: RootState) => (selectedGroups: string[] | SvgGroup[]) => {
    const groups = selectSvgGroupSet(state);

    // make sure there's only two points
    if (selectedGroups.length !== 2) {
      return false;
    }

    // get each group
    const [a, b] = selectedGroups?.map((obj) =>
      isString(obj) ? getGroupById(state, obj) : obj
    );

    // both must be points
    if (!(a.tool?.type === Shape.POINT && b.tool?.type === Shape.POINT)) {
      return false;
    }

    // both points must be part of the same group
    if (a.tool?.params?.belongsTo !== b.tool?.params?.belongsTo) {
      return false;
    }

    const group = groups.find(
      (item) => item.id === a.tool.params.belongsTo
    ) as IShapeGroup;
    const points = group.tool.params.points;
    const index = points.indexOf(a.id);

    // next find if it is before or after the index
    const { length: total } = points;
    const others = [
      points[(index - 1 + total) % total],
      points[(index + 1 + total) % total],
    ];

    return others.includes(b.id);
  };

export const selectMostRecentlyAddedShapeID = () =>
  transientState.mostRecentlyAddedShapeID;

export const selectMostRecentlyAddedPointID = () =>
  transientState.mostRecentlyAddedPointID;

export const selectSelectedLine = (state: RootState) => {
  const points = state.selection.selectedLine;
  const selectedGroups = selectSelectedGroups(state);

  const isSelected =
    points?.length === 2 &&
    selectedGroups.length === 2 &&
    union(
      points,
      selectedGroups.map((group) => group.id)
    ).length === 2;

  return isSelected ? points : null;
};

export const selectSelectedLineGroups = (state: RootState) => {
  const points = state.selection.selectedLine;
  const selectedGroups = selectSelectedGroups(state);

  return (points || [])
    .flatMap((p) => selectedGroups.find((group) => group.id === p))
    .filter((p) => p !== undefined);
};

// finds the most recently moved point
export const selectMostRecentlyMovedPoint = (state: RootState) => {
  return state.selection.selectedLine?.length === 2
    ? state.selection.mostRecentlyMovedPoint
    : null;
};

// selects a function to check if two points are adjacent
export const selectIsSelectionTwoAdjacentPoints = (state: RootState) => {
  const selectedGroups = selectSelectedGroups(state);
  return selectIsTwoAdjacentPoints(state)(selectedGroups);
};

// gets shape groups without their points
export const selectSelectedGroupsExcludingPoints = (state: RootState) => {
  const selected = selectSelectedGroups(state);
  const groups = selectSvgGroupSet(state);

  // replace any points with the parent group
  for (let i = selected.length; i-- > 0; ) {
    const group = selected[i];

    // if it's a point, replace it
    if (isToolForShape(group.tool, Shape.POINT)) {
      const parent = groups.find(
        (item) =>
          item.id === (group as SvgGroup<Shape.POINT>).tool!.params.belongsTo
      );
      selected[i] = parent!;
    }
  }

  // keep only unique items
  return uniqBy(selected, 'id');
};

// gets the bounds of a shape group
export function getShapeBounds(pointIds: string[], groups: SvgGroup[]) {
  const xs = [];
  const ys = [];
  const points = [];

  // find each point
  for (const id of pointIds) {
    const point = groups.find((item) => item.id === id)!;
    const { x, y } = point.position as Point;

    // capture all points
    xs.push(x);
    ys.push(y);

    // save the point data
    points.push({ id, x, y });
  }

  // get the sizing
  const right = Math.max.apply(Math, xs);
  const left = Math.min.apply(Math, xs);
  const bottom = Math.max.apply(Math, ys);
  const top = Math.min.apply(Math, ys);
  const width = right - left;
  const height = bottom - top;
  const x = (right + left) * 0.5;
  const y = (bottom + top) * 0.5;

  return { x, y, width, height, right, left, top, bottom, points };
}

// determines the bounds for a polyshape
// TODO: need to review this later
export const selectGetShapeBounds =
  (state: RootState) => (pointIds: string[]) => {
    const groups =
      (state.canvas as any)?.svgGroupSet ?? state.canvas?.canvas?.svgGroupSet;
    return getShapeBounds(pointIds, groups);
  };

type ChangeSet = {
  [id: string]: any;
};

type SelectGetPointChangesFromUpdateSetParams = {
  mutateChangeSet?: boolean;
  convertToAbsolute?: boolean;
  src?: string;
};

// gets points from a change set so they can be
// applied separately
export const selectGetPointChangesFromUpdateSet =
  (state: RootState) =>
  (
    changes: ChangeSet,
    {
      mutateChangeSet = false,
      convertToAbsolute = true,
      src,
    }: SelectGetPointChangesFromUpdateSetParams = {}
  ) => {
    const reduced = {};

    // remove all changes that are for a 'point' type
    const points: ChangeSet = {};
    for (const id of Object.keys(changes)) {
      const group = getGroupById(state, id);

      // if it's a point, get the absolute position
      // and then remove it from the change set
      if (group.tool?.type === Shape.POINT) {
        let source = changes[id];
        if (src) {
          source = source[src];
        }

        points[id] = convertToAbsolute
          ? {
              x: group.position.x + source.x,
              y: group.position.y + source.y,
            }
          : { ...source };

        // remove the change
        if (mutateChangeSet) {
          delete changes[id];
        }
      }
    }

    return { changes: reduced, points };
  };
