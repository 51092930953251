import { useSelector } from 'react-redux';

// selectors
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';

// components
import Grid from '@/UILayer/Components/Grid';
import SelectionNet from '@/UILayer/Components/SelectionNet';
import WorkspaceOrigin from '@/UILayer/Components/WorkspaceOrigin';
import Viewport from '@/UILayer/Components/Viewport';
import SvgGroups from '@/UILayer/Components/SvgGroups';
import Pattern from '../../Components/Pattern';
import classNames from 'classnames';
import CustomAnchor from '../../Components/CustomAnchor';
import UIState from '@/UILayer/State/UIState';
import { selectIsDefaultView } from '@/Redux/Slices/UISlice';

type Props = {
  ui: UIState;
};

export default function DefaultUI(props: Props) {
  const { ui } = props;
  const { showGrid, usePositioning, useCutPaths, showCustomAnchor } =
    useSelector(selectOptions);
  const isDefaultView = useSelector(selectIsDefaultView);
  const { hasSelectionNet } = ui;
  const uiCx = classNames('ui-layer', { 'cut-path-display': useCutPaths });

  return (
    <div className={uiCx}>
      <Viewport viewport={ui.viewport}>
        <Pattern />
        {isDefaultView && (
          <>
            {usePositioning && showGrid && <Grid viewport={ui.viewport} />}
            {usePositioning && <WorkspaceOrigin {...props} hideGuides={true} />}
            {usePositioning && showCustomAnchor && (
              <CustomAnchor {...props} ui={ui} />
            )}
          </>
        )}
        <SvgGroups groups={ui.groups} renderMode={ui.store.ui} />
        {hasSelectionNet && <SelectionNet {...ui.selectionNet!} />}
      </Viewport>
    </div>
  );
}
