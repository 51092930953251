//components
import FloatingPanel from './Components/FloatingPanel';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLocale,
  selectShaperSubscriptions,
  setSubscription,
} from '@/Redux/Slices/ShaperHubSlice';
import { setI18nLanguage } from '@/i18n';
import { setDeveloperSettings } from '@/Utility/developer-settings';
import { SecretMenuOption } from './Components/Group';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Subscription, SubscriptionTier } from '@/@types/shaper-types';
import { setFeaturesMode } from '@/Redux/Slices/UISlice';
import Button from '../Button';
import { downloadCanvasSvg } from '@/Redux/Slices/CanvasSlice';

export default function SecretMenuPanel() {
  const dispatch = useDispatch();
  const { language } = useSelector(selectLocale);
  const subscription = useSelector(selectShaperSubscriptions);

  const gridOptions = [
    {
      label: 'Grid Snapping Threshold (px)',
      type: 'number',
      prop: 'gridSnappingThreshold',
    },
    {
      label: 'Smart Snapping Threshold (px)',
      type: 'number',
      prop: 'smartSnappingThreshold',
    },
    {
      label: 'Snapping Stroke',
      type: 'number',
      prop: 'guideLineWidth',
      prop2: 'guideLineColor',
      hasColor2: true,
    },
    {
      type: 'separator',
    },
  ];

  const guideOptions = [
    {
      label: 'Guide Unselected Path',
      type: 'number',
      prop: 'guideUnselectedPathWidth',
      prop2: 'guideUnselectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Unselected Hover Path',
      type: 'number',
      prop: 'guideUnselectedHoverPathWidth',
      prop2: 'guideUnselectedHoverPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Glow Path',
      type: 'number',
      prop: 'guideGlowPathWidth',
      prop2: 'guideGlowPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Selected Path',
      type: 'number',
      prop: 'guideSelectedPathWidth',
      prop2: 'guideSelectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Guide Selected Hover Path',
      type: 'number',
      prop: 'guideSelectedHoverPathWidth',
      prop2: 'guideSelectedHoverPathColor',
      hasColor2: true,
    },
    {
      type: 'separator',
    },
  ];

  const objectOptions = [
    {
      label: 'Object Unselected Path',
      type: 'number',
      prop: 'objectUnselectedPathWidth',
      prop2: 'objectUnselectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Unselected Hover Path',
      type: 'number',
      prop: 'objectUnselectedHoverPathWidth',
      prop2: 'objectUnselectedHoverPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Selected Path',
      type: 'number',
      prop: 'objectSelectedPathWidth',
      prop2: 'objectSelectedPathColor',
      hasColor2: true,
    },
    {
      label: 'Object Selected Hover Path',
      type: 'number',
      prop: 'objectSelectedHoverPathWidth',
      prop2: 'objectSelectedHoverPathColor',
      hasColor2: true,
    },
    {
      type: 'separator',
    },
  ];

  const positionOptions = [
    {
      label: 'Position Display Stroke',
      prop: 'positionStrokeWidth',
      prop2: 'positionColor',
      hasColor2: true,
    },
    {
      label: 'Position Display Dash Width (px)',
      type: 'number',
      prop: 'positionDashWidth',
    },
    {
      label: 'Position Display Gap Width (px)',
      type: 'number',
      prop: 'positionGapWidth',
    },
  ];

  const otherOptions = [
    {
      label: 'Display Workspace Sync Options',
      type: 'toggle',
      prop: 'showWorkspaceSettings',
    },
    {
      label: 'Language',
      type: 'dropdown',
      prop: 'language',
      value: language || 'en',
      onChange: (value: string) => {
        setDeveloperSettings('locale', value);
        setI18nLanguage(value);
      },
      options: [
        { label: 'EN', value: 'en' },
        { label: 'FR', value: 'fr' },
        { label: 'DE', value: 'de' },
      ],
    },

    {
      label: 'Subscription',
      type: 'dropdown',
      prop: 'subscription',
      value: subscription.type,
      onChange: (value: string) => {
        const newSubscription: {
          subscription: Subscription;
          featureMode: SubscriptionTier;
        } = (() => {
          switch (value) {
            case 'annual':
              return {
                subscription: {
                  type: 'annual',
                  isTrial: false,
                  isSubscriber: true,
                  isExpired: false,
                  daysLeft: 100,
                },
                featureMode: 'full',
              };
            case 'annual_expired':
              return {
                subscription: {
                  type: 'annual',
                  isTrial: false,
                  isSubscriber: true,
                  isExpired: true,
                },
                featureMode: 'full',
              };
            case 'perpetual':
              return {
                subscription: {
                  type: 'perpetual',
                  isTrial: false,
                  isSubscriber: true,
                  isExpired: false,
                },
                featureMode: 'full',
              };
            case 'free':
            default:
              return {
                subscription: {
                  type: 'free',
                  isTrial: false,
                  isSubscriber: false,
                  isExpired: false,
                },
                featureMode: 'free',
              };
          }
        })();

        dispatch(setSubscription(newSubscription.subscription));
        dispatch(setFeaturesMode(newSubscription.featureMode));
      },
      options: [
        { label: 'Annual', value: 'annual' },
        { label: 'Annual (Expired)', value: 'annual_expired' },
        { label: 'Perpetual', value: 'perpetual' },
        { label: 'Free', value: 'free' },
      ],
    },
  ];

  type Opt = SecretMenuOption<unknown>;

  const summarySx = {
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'uppercase',
  };

  const detailsSx = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  };

  const accordionSx = {
    elevation: 0,
    TransitionProps: {
      timeout: 0,
    },
    sx: {
      '.MuiButtonBase-root': {
        padding: 0,
      },
    },
  };

  const getGroup = (opt: Opt, index: number) => {
    if (opt.type === 'seperator') {
      return <FloatingPanel.Separator />;
    } else if (opt.type === 'number') {
      return (
        <FloatingPanel.Group<number>
          key={index}
          {...(opt as SecretMenuOption<number>)}
        />
      );
    } else if (opt.type === 'toggle') {
      return (
        <FloatingPanel.Group<boolean>
          key={index}
          {...(opt as SecretMenuOption<boolean>)}
        />
      );
    }
    return (
      <FloatingPanel.Group<string>
        key={index}
        {...(opt as SecretMenuOption<string>)}
      />
    );
  };

  const exportLocally = () => {
    dispatch(downloadCanvasSvg());
  };

  return (
    <div className='secret-menu-panel--content'>
      <Accordion {...accordionSx}>
        <AccordionSummary sx={summarySx} expandIcon={<ExpandMoreIcon />}>
          Color Options
        </AccordionSummary>
        <AccordionDetails sx={detailsSx}>
          {[
            ...gridOptions,
            ...guideOptions,
            ...objectOptions,
            ...positionOptions,
          ].map((opt, index) =>
            getGroup(opt as SecretMenuOption<unknown>, index)
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion {...accordionSx}>
        <AccordionSummary sx={summarySx} expandIcon={<ExpandMoreIcon />}>
          Developer Settings
        </AccordionSummary>
        <AccordionDetails sx={detailsSx}>
          {[...otherOptions].map((opt, index) =>
            getGroup(opt as SecretMenuOption<unknown>, index)
          )}
        </AccordionDetails>
      </Accordion>
      <Button onClick={exportLocally}>Export Through Studio</Button>
    </div>
  );
}
