import { useSelector } from 'react-redux';

// selectors
import { selectOptions } from '@/Redux/Slices/SherpaContainerSlice';

// components
import Grid from '@/UILayer/Components/Grid';
import BoundingBox from '@/UILayer/Components/BoundingBox';
import Viewport from '@/UILayer/Components/Viewport';
import SvgGroups from '@/UILayer/Components/SvgGroups';
import UIState from '@/UILayer/State/UIState';

type Props = {
  ui: UIState;
};

export default function AnchorSelectionUI(props: Props) {
  const { ui } = props;
  const options = useSelector(selectOptions);
  const { showGrid } = options;
  const { hasBounds } = ui;

  return (
    <div className='ui-layer'>
      <Viewport viewport={ui.viewport}>
        {showGrid && <Grid viewport={ui.viewport} />}
        {<SvgGroups groups={ui.groups} renderMode={ui.store.ui} />}
        {hasBounds && (
          <BoundingBox
            {...props}
            {...ui.boundingBox!}
            aabb={ui.boundingBox!.aabb}
            hideLabels
          />
        )}
      </Viewport>
    </div>
  );
}
