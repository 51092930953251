import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// selectors
import {
  selectGetGroupById,
  selectGroupsMap,
} from '@/Redux/Slices/CanvasSlice';
import SvgGroupCls from '../../State/Helpers/SvgGroup';

// components
import Layer from '../Layer';
import {
  selectIsShowingDrawModeShapePreview,
  selectIsShowingOpenSnapPreview,
  selectLineToolActiveShapeId,
} from '@/Redux/Slices/UISlice';
import { Shape, ToolParams } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';

type Props = {
  className?: string;
  group: SvgGroupCls;
  selected: boolean;
};

// renders a preview shape
export function EditableShape({ group, ...props }: Props) {
  const groupsMap = useSelector(selectGroupsMap);
  const getSvgGroupById = useSelector(selectGetGroupById);
  const found = getSvgGroupById(group.id);
  const { closed, points = [] } = found.tool?.params;
  const shapeId = useSelector(selectLineToolActiveShapeId);
  const isShowingDrawModeShapePreview = useSelector(
    selectIsShowingDrawModeShapePreview
  );
  const isShowingOpenSnapPreview = useSelector(selectIsShowingOpenSnapPreview);
  const selectedPathIds = useSelector(selectSelectedPathIds);

  const isDrawModeShapePreview =
    shapeId === group.id && isShowingDrawModeShapePreview;

  const isOpenSnapPreview = shapeId === group.id && isShowingOpenSnapPreview;

  const path = points
    .map((id: string, i: number) => {
      const point = groupsMap.get(id);
      if (!point) {
        return '';
      }

      const x = 'cx' in point ? point.cx : point.position?.x;
      const y = 'cy' in point ? point.cy : point.position?.y;

      return `${i === 0 ? 'M' : 'L'} ${x} ${y}`;
    })
    .join(' ');

  const svg = `
    <g id="sg-${group.id}" >
      <g id="sg-${group.id}" class="pathGroup ${closed ? 'closed' : ''}" >
        <path class="basePath ${
          isDrawModeShapePreview ? 'draw-mode-shape-preview' : ''
        } ${isOpenSnapPreview ? 'open-snap-preview' : ''}" d="${path} ${
    closed ? 'Z' : ''
  }" />
      </g>
    </g>
  `;

  const editing = (() => {
    if (group.tool?.type !== Shape.SHAPE) {
      return false;
    }

    for (const { groupId: id } of selectedPathIds) {
      if ((group.tool.params as ToolParams<Shape.SHAPE>).points.includes(id)) {
        return true;
      }
    }
    return false;
  })();

  const shapeCx = classNames('svg-group design', { editing }, props.className);
  return (
    <Layer
      id={group.id}
      className={shapeCx}
      x={0}
      y={0}
      rotation={group.rotation}
      stretchMatrix={group.stretchMatrix}
      svgGroup={svg}
    />
  );
}
