import { DisplayUnits } from '@/@types/shaper-types';
import BaseAction from './BaseAction';
import {
  Options,
  actions as sherpaContainerActions,
} from '@/Redux/Slices/SherpaContainerSlice';

export default class UpdateWorkspaceOptionsAction extends BaseAction {
  update(options: Partial<Options> & { displayUnits?: DisplayUnits } = {}) {
    const { dispatch } = this;
    const { displayUnits, showCustomAnchor } = options;

    // special updates
    if (displayUnits) {
      dispatch(
        sherpaContainerActions.setDisplayUnits({
          newDisplayUnits: displayUnits,
        })
      );
      delete options.displayUnits;
    }
    if (showCustomAnchor !== undefined) {
      dispatch(
        sherpaContainerActions.updateCustomAnchorOption(showCustomAnchor)
      );
      delete options.showCustomAnchor;
    }

    // check for any other options
    if (!Object.keys(options).length) {
      return;
    }

    // normal config update
    dispatch(sherpaContainerActions.updateOptions(options));
  }
}
