import { ServerSHA } from '@/@types/shaper-types';

const loadKey = <T>(
  stateKey: string,
  storageSrv = localStorage
): T | undefined => {
  try {
    const serializedState = storageSrv.getItem(stateKey);
    if (serializedState === null) {
      return undefined;
    }
    const savedState = JSON.parse(serializedState);
    return savedState;
  } catch (err) {
    return undefined;
  }
};

const saveKey = (stateKey: string, state: any, storageSrv = localStorage) => {
  try {
    const serializedState = JSON.stringify(state);
    storageSrv.setItem(stateKey, serializedState);
  } catch {
    // ignore write errors
  }
};

const loadLocalKey = <T>(stateKey: string) => {
  return loadKey<T>(stateKey, localStorage);
};

const loadSessionKey = <T>(stateKey: string) => {
  return loadKey<T>(stateKey, sessionStorage);
};

const saveLocalKey = (stateKey: string, state: any) => {
  return saveKey(stateKey, state, localStorage);
};

const saveSessionKey = (stateKey: string, state: any) => {
  return saveKey(stateKey, state, sessionStorage);
};

export const saveServerSHA = (serverSha: string) => {
  saveSessionKey('server_sha', { server_sha: serverSha });
  saveLocalKey('server_sha', { server_sha: serverSha });
};

export const loadServerSHA = () => {
  let serverSHA = loadSessionKey<ServerSHA>('server_sha');
  if (serverSHA === undefined) {
    serverSHA = loadLocalKey<ServerSHA>('server_sha');
  }
  return serverSHA?.server_sha;
};
