import { MutableRefObject, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// selectors
import {
  selectOptions,
  selectSecretOptions,
} from '@/Redux/Slices/SherpaContainerSlice';
import {
  selectExportAccess,
  selectLoggedIn,
  selectProject,
} from '@/Redux/Slices/ShaperHubSlice';
import {
  selectIsShowingOpenMenu,
  selectIsShowingMoveMenu,
  selectIsShowingRename,
  toggleRename,
  selectIsShowingExportMenu,
  toggleExportMenu,
  selectIsPreviewView,
  toggleOpenMenu,
  toggleMoveMenu,
  selectIsShowingShare,
} from '@/Redux/Slices/UISlice';

// actions
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';
import CreateWorkspaceAction from '@/Actions/CreateWorkspace';
import FileSystemAction from '@/Actions/FileSystem';
import LogOutAction from '@/Actions/LogOut';

// components
import ClickCapture from '@/Components/ClickCapture/ClickCapture';
import Icon from '@/Styles/Icons/Icon';

//Temp login and workspace controls
import LoginAndWorkspaceControls from '@/Components/LoginMenuContainer/LoginMenuContainer';

// sub menu components
import FileName from './Components/FileName';
import Separator from './Components/Separator';
import DashboardLauncher from './Components/DashboardLauncher';
import FileSystem from './Components/FileSystem';
import classNames from 'classnames';
import ActionMenuList from '../ActionMenuList/ActionMenuList';
import { FLOW_IDS, menuLinks } from '../../defaults';
import SignOut from './Components/SignOut';
import {
  selectIsEditMode,
  selectSyncStatus,
  selectWorkspaceId,
} from '@/Redux/Slices/SyncSlice';
import { duplicate } from '@/Sync/SyncThunks';
import ExportCount from '../Export/ExportCount';
import TranslationText from '../TranslationText/TranslationText';
import { ExportMenu } from './Components/ExportMenu';
import { Tooltip } from '../Tooltip/Tooltip';
import PreviewFileName from './Components/PreviewFileName';
import { selectFeatureFlag } from '@/Redux/Slices/FeatureFlagsSlice';
import { RootState } from '@/Redux/store';
import StudioShare from './Components/Share/ShareMenuItem';
import ShareMobileMenu from './Components/Share/ShareMobileMenu';
import { ShaperButton } from '@shapertools/web-ui';
import ModalActions from '@/Actions/Modal';
import DownloadAction from '@/Actions/Download';
import { useTranslation } from 'react-i18next';
import { addAttributeToUser, openFlow } from '@/Utility/userflow';

export default function ApplicationMenu() {
  const menuRef = useRef<HTMLDivElement>(null);
  const counterRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  // selectors
  const { showApplicationMenu } = useSelector(selectOptions);
  const isLoggedIn = useSelector(selectLoggedIn);

  const showWorkspaceId = useSelector((state: RootState) =>
    selectFeatureFlag(state, 'studio-developer-settings')
  );

  const { showWorkspaceSettings } = useSelector(selectSecretOptions);

  const isPreviewView = useSelector(selectIsPreviewView);
  const isShowingMoveMenu = useSelector(selectIsShowingMoveMenu);
  const isShowingOpenMenu = useSelector(selectIsShowingOpenMenu);
  const isShowingRename = useSelector(selectIsShowingRename);
  const isShowingExportMenu = useSelector(selectIsShowingExportMenu);
  const isShowingShare = useSelector(selectIsShowingShare);

  const workspaceId = useSelector(selectWorkspaceId);
  const project = useSelector(selectProject);
  const isEditMode = useSelector(selectIsEditMode);
  const exportAccess = useSelector(selectExportAccess);
  const isDisconnected = useSelector(selectSyncStatus) === 'disconnected';

  // actions
  const updateWorkspaceOptions = useAction(UpdateWorkspaceOptionsAction);
  const createWorkspaceAction = useAction(CreateWorkspaceAction);
  const fileSystemAction = useAction(FileSystemAction);
  const logOutAction = useAction(LogOutAction);
  const downloadAction = useAction(DownloadAction);
  const modalActions = useAction(ModalActions);
  const { t, i18n } = useTranslation();

  const logInTranslation = i18n.exists('log-in') ? t('log-in') : 'Log In';

  function onRename() {
    dispatch(toggleRename(!isShowingRename));
  }

  function onHideMenu() {
    batch(() => {
      dispatch(toggleRename(false));
      dispatch(toggleOpenMenu(false));
      dispatch(toggleMoveMenu(false));
      dispatch(toggleExportMenu(false));
      updateWorkspaceOptions.update({ showApplicationMenu: false });
    });
  }

  function onCreateWorkspace() {
    createWorkspaceAction.disconnectCurrentWorkspaceAndCreateNewWorkspace();
  }

  function onDuplicateWorkspace() {
    dispatch(toggleRename(false));
    updateWorkspaceOptions.update({ showApplicationMenu: false });
    const duplicateParams =
      isPreviewView && project
        ? {
            name: project?.title,
          }
        : {};
    dispatch(duplicate(duplicateParams));
  }

  function onOpenWorkspace() {
    if (isLoggedIn) {
      fileSystemAction.toggleOpenMenu(isShowingOpenMenu);
    }
  }

  function onMove() {
    if (isLoggedIn) {
      fileSystemAction.toggleMoveMenu(isShowingMoveMenu);
    }
  }

  function onLearnMoreAboutExports() {
    updateWorkspaceOptions.update({ showApplicationMenu: false });
    addAttributeToUser('exports_remaining', exportAccess.remaining);
    openFlow(FLOW_IDS.UPGRADE_STUDIO);
  }

  function onExport() {
    dispatch(toggleExportMenu(true));
  }

  function onPaidExport() {
    downloadAction.exportAsSVG();
  }

  function cancelFileSystem() {
    fileSystemAction.closeFileSystem();
  }

  function logout() {
    onHideMenu();
    logOutAction.logOut();
  }

  function login() {
    modalActions.openSignInModal();
  }

  const menuCx = classNames('application-menu', 'scrollable', {
    show: showApplicationMenu,
    share: isShowingShare,
    project: !isLoggedIn && isPreviewView,
  });

  const menuOverlayCx = classNames('application-menu--overlay', {
    show: showApplicationMenu,
  });

  const menuLeadingCx = classNames('application-menu--leading', {
    'application-menu--full-length':
      isShowingMoveMenu ||
      isShowingOpenMenu ||
      isShowingRename ||
      isShowingExportMenu,
  });

  const duplicateDesignHover = isEditMode ? null : (
    <div className='action-list-menu--section-option--centered'>
      <Icon icon='spinning-arrows' className='animated-spinning-arrows' />
      <span> syncing workspace.</span>
    </div>
  );

  const shaperHubMenu = [
    {
      label: 'Trace',
      icon: 'trace',
      url: menuLinks.TRACE,
      visible: isLoggedIn && !isPreviewView,
      i18nKey: 'Trace',
    },
    {
      label: 'My Files',
      icon: 'projects',
      target: '_blank',
      url: menuLinks.PROJECTS,
      visible: isLoggedIn,
      i18nKey: 'my-projects',
    },
    {
      label: 'ShaperHub',
      icon: 'shaper-hub',
      target: '_blank',
      url: menuLinks.SHAPERHUB,
      visible: true,
      i18nKey: 'shaperhub',
    },
    {
      label: 'Forum',
      icon: 'shaper-origin',
      target: '_blank',
      url: menuLinks.FORUM,
      visible: true,
      i18nKey: 'forum',
    },
    {
      label: 'Shop',
      icon: 'shop',
      target: '_blank',
      url: menuLinks.SHOP,
      visible: true,
      i18nKey: 'shop',
    },
    {
      label: 'Account',
      icon: 'settings',
      target: '_blank',
      url: menuLinks.ACCOUNT,
      visible: isLoggedIn,
      i18nKey: 'account',
    },
    { separator: true, visible: isLoggedIn || isPreviewView },
    {
      component: SignOut,
      icon: 'person',
      onClick: logout,
      visible: isLoggedIn,
      dataCy: 'sign-out',
    },
    {
      component: () => (
        <>
          <ShaperButton
            label={logInTranslation}
            variant='primary'
            onClick={login}
          />
        </>
      ),
      className: 'login-button',
      action: logout,
      visible: !isLoggedIn,
    },
  ];

  const fileSystemMode = isShowingMoveMenu
    ? 'move'
    : isShowingOpenMenu
    ? 'open'
    : null;

  const actionMenu = () => [
    {
      label: 'Rename',
      icon: 'edit',
      action: onRename,
      visible: isLoggedIn && !isPreviewView,
      disabled: isDisconnected,
      i18nKey: 'rename',
    },
    {
      label: 'Move',
      icon: 'move-to-project',
      action: onMove,
      visible: isLoggedIn,
      disabled: isDisconnected,
      i18nKey: 'move-project',
    },
    {
      label: (
        <div className='application-menu--split' ref={counterRef}>
          <TranslationText i18nKey='export'>Export</TranslationText>

          {!exportAccess.unlimited && (
            <Tooltip
              side='right'
              absoluteRef={counterRef as MutableRefObject<HTMLElement>}
              tip={
                <>
                  {exportAccess.remaining !== 1 && (
                    <TranslationText
                      i18nKey='you-have-exports-remaining'
                      count={exportAccess.remaining}
                    >
                      You have {exportAccess.remaining} exports remaining this
                      month.
                    </TranslationText>
                  )}

                  {exportAccess.remaining === 1 && (
                    <TranslationText
                      i18nKey='you-have-exports-remaining_one'
                      count={exportAccess.remaining}
                    >
                      You have {exportAccess.remaining} export remaining this
                      month.
                    </TranslationText>
                  )}

                  <br />
                  <TranslationText i18nKey='subscribe-to-unlock'>
                    Subscribe to unlock unlimited exports.
                  </TranslationText>
                </>
              }
            >
              <ExportCount
                remaining={exportAccess.remaining!}
                limit={exportAccess.limit!}
                onClick={onLearnMoreAboutExports}
              />
            </Tooltip>
          )}
        </div>
      ),
      id: 'export',
      icon: 'download',
      action: onExport,
      visible: isLoggedIn && exportAccess.allowed && !exportAccess.unlimited,
      disabled: isDisconnected,
    },
    {
      label: 'Download SVG',
      id: 'export',
      icon: 'download',
      i18nKey: 'download-svg',
      action: onPaidExport,
      visible: isLoggedIn && exportAccess.allowed && exportAccess.unlimited,
      disabled: isDisconnected,
    },
    {
      component: StudioShare,
      icon: 'shaper-hub',
      visible: !isPreviewView,
      disabled: isDisconnected,
    },
    { separator: true, visible: isLoggedIn },
    {
      label: 'Duplicate Design',
      icon: 'duplicate-workspace',
      action: onDuplicateWorkspace,
      visible: isLoggedIn,
      disabled: !isEditMode,
      i18nKey: 'duplicate-workspace',
      tooltip: isEditMode ? 'opens in new tab' : undefined,
      onHover: duplicateDesignHover,
    },
    {
      label: 'New Design',
      icon: 'new-design',
      action: onCreateWorkspace,
      visible: isLoggedIn,
      i18nKey: 'new-design',
    },
    {
      label: 'Open Recent',
      icon: 'open',
      action: onOpenWorkspace,
      visible: isLoggedIn,
      i18nKey: 'open-recent',
    },
  ];

  return (
    <>
      <ClickCapture onClick={onHideMenu} disabled={!showApplicationMenu} />

      <div className={menuCx} ref={menuRef} data-cy='application-menu-panel'>
        {!isShowingShare && (
          <div className='application-menu--close' onClick={onHideMenu}>
            <Icon>close</Icon>
          </div>
        )}
        <div className={menuLeadingCx} data-cy='application-menu-start'>
          {isShowingShare && <ShareMobileMenu />}
          {!isShowingShare && (
            <>
              <div className='application-menu--logo'>
                <div className='application-menu--logo--image'>
                  <Icon icon='studio-logo' />
                </div>
              </div>

              {!isShowingOpenMenu &&
                !isShowingMoveMenu &&
                !isShowingExportMenu && (
                  <>
                    {isPreviewView && (
                      <Separator classNames='preview-separator' />
                    )}
                    {!isPreviewView && isLoggedIn && (
                      <>
                        <Separator classNames='logo-separator' />
                        <FileName onClick={onRename} />
                        {showWorkspaceId && (
                          <div className='workspace-id'>
                            {workspaceId ? workspaceId : 'disconnected'}
                          </div>
                        )}
                      </>
                    )}
                    {isPreviewView && <PreviewFileName />}
                  </>
                )}

              {!isLoggedIn && !isPreviewView && <Separator />}

              {!isShowingMoveMenu &&
                !isShowingOpenMenu &&
                !isShowingRename &&
                !isShowingExportMenu && (
                  <ActionMenuList>
                    {actionMenu()
                      .filter((action) => action.visible)
                      .map((m, i) => {
                        if (m.separator) {
                          return <Separator key={`${i}_action_menu`} />;
                        }

                        return (
                          <ActionMenuList.SectionOption
                            key={`${i}_action_menu`}
                            onClick={m.action}
                            {...m}
                          >
                            {m.label}
                          </ActionMenuList.SectionOption>
                        );
                      })}
                  </ActionMenuList>
                )}

              {showWorkspaceSettings &&
                !(
                  isShowingMoveMenu ||
                  isShowingOpenMenu ||
                  isShowingRename ||
                  isShowingExportMenu
                ) && (
                  <>
                    <Separator />
                    <LoginAndWorkspaceControls />
                  </>
                )}

              {isShowingExportMenu && (
                <>
                  <Separator classNames='logo-separator' />
                  <ExportMenu />
                </>
              )}

              {(isShowingMoveMenu || isShowingOpenMenu) && (
                <>
                  <Separator />
                  <FileSystem
                    mode={fileSystemMode}
                    onClick={cancelFileSystem}
                  />
                </>
              )}
            </>
          )}
        </div>

        {!isShowingMoveMenu &&
          !isShowingOpenMenu &&
          !isShowingRename &&
          !isShowingExportMenu &&
          !isShowingShare && (
            <div
              className='application-menu--trailing'
              data-cy='application-menu-end'
            >
              <DashboardLauncher />
              <ActionMenuList>
                {shaperHubMenu
                  .filter((action) => action.visible)
                  .map((m, i) => {
                    if (m.separator) {
                      return <Separator key={`${i}_shaper_menu`} />;
                    }
                    return (
                      <ActionMenuList.SectionOption
                        key={`${i}_shaper_menu`}
                        onClick={m.action}
                        url={m.url}
                        icon={m.icon}
                        target={m.target}
                        i18nKey={m.i18nKey}
                        component={m.component}
                        {...m}
                      >
                        {m.label}
                      </ActionMenuList.SectionOption>
                    );
                  })}
              </ActionMenuList>
            </div>
          )}
      </div>
      <div className={menuOverlayCx} onClick={onHideMenu} />
    </>
  );
}
