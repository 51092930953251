import UIState from '@/UILayer/State/UIState';
import {
  selectSVGViewbox,
  selectViewport,
  setPosition,
} from '../Redux/Slices/ViewportSlice';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';

export default class TranslateViewportAction {
  dispatch: AppDispatch;
  useSelector: UseSelector;
  TLCornerX: number;
  TLCornerY: number;
  tempX: number;
  tempY: number;

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;

    // setup initial state
    const viewbox = useSelector(selectSVGViewbox);
    const { x, y } = viewbox;
    this.TLCornerX = x;
    this.TLCornerY = y;
    this.tempX = x;
    this.tempY = y;
  }

  resetToZero() {
    this.dispatch(setPosition({ x: 0, y: 0 }));
  }

  // applies translations and updated existing layers
  panBy(dxPixels: number, dyPixels: number) {
    const { useSelector } = this;
    const viewport = useSelector(selectViewport);

    const scale = viewport.screenToCanvasTransform[0][0];

    const dxCanvas = scale * dxPixels;
    const dyCanvas = scale * dyPixels;
    const newX = dxCanvas + this.TLCornerX;
    const newY = dyCanvas + this.TLCornerY;
    const width =
      viewport.canvasViewbox.maxPoint.x - viewport.canvasViewbox.minPoint.x;
    const height =
      viewport.canvasViewbox.maxPoint.y - viewport.canvasViewbox.minPoint.y;

    // save the updated values
    this.tempX = newX;
    this.tempY = newY;

    // notify the UI layer of the change
    UIState.apply((ui: any) => {
      ui.viewport = {
        x: dxCanvas,
        y: dyCanvas,
        width,
        height,
      };
    });
  }

  resolve() {
    const { useSelector, dispatch } = this;
    const viewport = useSelector(selectViewport);

    UIState.reset();

    //First, need to store new TLCorner of viewbox from temp
    const { tempX, tempY } = this;
    this.TLCornerX = tempX;
    this.TLCornerY = tempY;

    //Second, we pan viewport by changing TL corner, but state stores viewport center, so we need to return new center point
    const width =
      viewport.canvasViewbox.maxPoint.x - viewport.canvasViewbox.minPoint.x;
    const height =
      viewport.canvasViewbox.maxPoint.y - viewport.canvasViewbox.minPoint.y;

    // apply
    const position = {
      x: this.TLCornerX + width / 2,
      y: this.TLCornerY + height / 2,
    };

    dispatch(setPosition(position));
  }
}
