//actions
import { useAction } from '@/Actions/useAction';
import ModalActions from '@/Actions/Modal';

//selectors
import { useSelector } from 'react-redux';
import {
  selectShaperSubscriptions,
  selectShaperMarketingUrl,
  selectLocale,
} from '@/Redux/Slices/ShaperHubSlice';

//components
import Modal from '../Modal';
import Icon from '@/Styles/Icons/Icon';

//helpers
import { getSubscribeLink } from '@/Utility/subscribe-link';
import { localePricing, menuLinks } from '../../../defaults';
import TranslationText from '../../TranslationText/TranslationText';

export default function EducationalModal() {
  const { isSubscriber, isTrial, isExpired } = useSelector(
    selectShaperSubscriptions
  );
  const shaperMarketingUrl = useSelector(selectShaperMarketingUrl);
  const { displayLocale, currency } = useSelector(selectLocale);

  const modalActions = useAction(ModalActions);

  const closeModal = () => {
    modalActions.closeModal();
  };

  const openSubscribeLink = () => {
    const subscribePath = getSubscribeLink();
    window.open(`${shaperMarketingUrl}/${subscribePath}`, '_self');
  };

  const subscriptionPricing = currency
    ? localePricing[currency].toLocaleString(displayLocale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : '';

  const titleVariation = (() => {
    if (isSubscriber && isExpired) {
      return (
        <TranslationText i18nKey='subscription-ended'>
          Your subscription has ended
        </TranslationText>
      );
    } else if (isTrial && isExpired) {
      // 03-07-2025 I am intentionally keeping this case because users may still have a lapsed trial
      // even though we stopped supporting them in October 2024.
      return (
        <TranslationText i18nKey='free-trial-ended'>
          Oh no, your free trial has ended!
        </TranslationText>
      );
    }

    return (
      <TranslationText i18nKey='do-more'>Do More With Studio</TranslationText>
    );
  })();

  return (
    <Modal className='educational-modal'>
      <Modal.Panel position='left'>
        <Modal.Content>
          <Modal.Title>{titleVariation}</Modal.Title>
          <Modal.Button onClick={openSubscribeLink}>
            <TranslationText i18nKey='subscribe'>Subscribe</TranslationText>
            {` (${subscriptionPricing}/YR)`}
          </Modal.Button>
          <div className='educational-modal--left--content--info-text'>
            <div className='educational-modal--left--content--info-terms'>
              <TranslationText i18nKey='access-designs'>
                Access millions of pre-made designs
              </TranslationText>
            </div>
            <div className='educational-modal--left--content--info-terms'>
              <TranslationText i18nKey='shapeshifter-explanation'>
                Fast results with Shapeshifter
              </TranslationText>
            </div>
            <div className='educational-modal--left--content--info-terms'>
              <TranslationText i18nKey='sync-to-origin'>
                Sync to Origin from your phone or computer
              </TranslationText>
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <div className='educational-modal--left--footer--learn-more'>
            <a href={menuLinks.LEARN_MORE}>
              <TranslationText i18nKey='learn-more-modal'>
                Learn More about Studio
              </TranslationText>
            </a>
          </div>
        </Modal.Footer>
      </Modal.Panel>
      <Modal.Panel position='right'>
        <div className='educational-modal--close-icon'>
          <Icon icon='close' onClick={closeModal} />
        </div>
        <div className='educational-modal--learn-more'>
          <a
            href={menuLinks.LEARN_MORE}
            className='educational-modal--learn-more-text'
          >
            <TranslationText i18nKey='learn-more-about-studio'>
              Learn More About Studio
            </TranslationText>
          </a>
        </div>
      </Modal.Panel>
    </Modal>
  );
}
