import React, { useRef, useState } from 'react';
import { radiansToDegreesFormattedNum } from '@/Geometry/UnitOps';
import { useSelector } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import { degreesToRadians } from '@/Utility/rotation';

// util
import { asFloat } from '@/Utility/sanitize';

// rounding
const PRECISION = 10 ** 4;

// selectors
import {
  selectSelectionBounds,
  selectSelectedGroups,
} from '@/Redux/Slices/SelectionSlice';

// actions
import RotateGroupsAction from '@/Actions/RotateGroups';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import TranslationText from '../../../TranslationText/TranslationText';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';

const LINE_ROTATION_RESET = 180;

export default function RotationEditor() {
  const rotationRef = useRef();
  const getGroupById = useSelector(selectGetGroupById);
  const selectedLine = useSelector(selectSelectedLine);
  const selectedGroups = useSelector(selectSelectedGroups);
  const selectionBounds = useSelector(selectSelectionBounds);
  const isMultiSelect = selectedGroups.length > 1;
  const selectionAngleInDegrees = radiansToDegreesFormattedNum(
    selectionBounds.rotation
  );
  let angle = isMultiSelect ? 0 : asFloat(selectionAngleInDegrees);
  const [currentRotation, setCurrentRotation] = useState(angle);
  const [selectedGroup] = selectedGroups;
  const disabled =
    PATH_TYPES[selectedGroup.type]?.propertyEditingDisabled || false;

  // optional value for when selecting a line
  let actualLineAngle;
  if (selectedLine) {
    const [a, b] = selectedLine.map(getGroupById);
    const radians = Math.atan2(
      b.position.y - a.position.y,
      b.position.x - a.position.x
    );
    actualLineAngle = (0 | (radiansToDegreesFormattedNum(radians) * 100)) / 100;
    angle =
      ((actualLineAngle % LINE_ROTATION_RESET) + LINE_ROTATION_RESET * 2) %
      LINE_ROTATION_RESET;
  }

  // actions
  const rotateAction = useAction(RotateGroupsAction, selectedGroups, {
    x: 0,
    y: 0,
  });

  // handle resize updates
  function onRotate(value, event, { didPressEnter } = {}) {
    let degrees = asFloat(value);

    // if there's a selected line, the angle needs to account for
    // the perceived angle being shown when calculating the
    // offset for the new angle
    if (!isNaN(actualLineAngle)) {
      let a = degrees - actualLineAngle;
      let b = degrees - (actualLineAngle + LINE_ROTATION_RESET);

      // if there is a zero change included, flip the sides
      if (didPressEnter && [a, b].includes(0)) {
        degrees = -[a, b].find((v) => v !== 0);
      } else {
        degrees = Math.abs(a) < Math.abs(b) ? a : b;
      }
    }

    // calculate radians
    const radians = (0 | (degreesToRadians(degrees) * PRECISION)) / PRECISION;

    // can this be applied
    if (degrees !== currentRotation && !isNaN(radians)) {
      setCurrentRotation(degrees);
      rotateAction.setToRotation(radians);
      rotateAction.resolve();
    }

    // if needed, reset to zero
    if (isMultiSelect && !selectedLine) {
      if (rotationRef.current) {
        rotationRef.current.textContent = '0';
      }
      setTimeout(() => setCurrentRotation(0));
    }
  }

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='rotation'>
        <TranslationText i18nKey='rotation'>Rotation</TranslationText>
      </FloatingPanel.Label>
      <FloatingPanel.Input
        suffix='°'
        onCommit={onRotate}
        value={(0 | (angle * PRECISION)) / PRECISION}
        number
        calculate
        ignoreUnits
        forwardRef={rotationRef}
        disabled={disabled}
        dataCy='rotation-editor'
      />
    </FloatingPanel.Group>
  );
}
