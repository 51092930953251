import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useAction } from '@/Actions/useAction';
import classNames from 'classnames';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';

// selectors
import {
  selectSelectedGroups,
  refreshPathSelection,
} from '@/Redux/Slices/SelectionSlice';
import { selectIsMobile } from '@/Redux/Slices/UISlice';

// actions
import UpdateTextAction from '@/Actions/UpdateText';
import ApplySvgChangeSetAction from '@/Actions/ApplySvgChangeSet';
import ViewportActions from '@/Actions/Viewport';
import { SvgOps } from '@/Geometry/SvgParser';
import { createText } from '@/Helpers/TextCreator';

// components
import FloatingPanel from '@/Components/FloatingPanel/FloatingPanel';
import { FONTS } from '@/Helpers/TextCreator';
import TranslationText from '../../../TranslationText/TranslationText';
import UIState from '@/UILayer/State/UIState';
import { fontFilterConfig } from '@/defaults';
import { partition } from 'lodash';

export default function TextEditor() {
  const dispatch = useDispatch();
  const selectedGroups = useSelector(selectSelectedGroups);
  const [selectedGroup] = selectedGroups;
  const { fontDisplayName, fontDisplayStyle, text } =
    selectedGroup.tool?.params || {};

  const disabled =
    PATH_TYPES[selectedGroup.type]?.propertyEditingDisabled || false;

  const [enabledFilters, setEnabledFilters] = useState([]);
  const previewTimer = useRef(null);
  const selectedFont =
    FONTS.find((font) => font.fontDisplayName === fontDisplayName) || FONTS[0];
  const selectedStyle =
    selectedFont.fontStyles.find(
      (style) => style.displayStyle === fontDisplayStyle.displayStyle
    ) || selectedFont.fontStyles[0];

  const isMobile = useSelector(selectIsMobile);

  // actions
  const applySvgChangeSetAction = useAction(ApplySvgChangeSetAction);
  const viewportAction = useAction(ViewportActions);

  const textSupportsFont = useMemo(
    () =>
      FONTS.flatMap((font) => font.fontStyles).reduce(
        (acc, { fontFamily }) => ({
          ...acc,
          [fontFamily]: SvgOps.isTextSupportedByFont(text, fontFamily),
        }),
        {}
      ),
    [text]
  );

  const centerOnText = async () => {
    const selection = selectedGroup.basePathSet.map((path) => ({
      group: selectedGroup,
      path,
    }));
    viewportAction.centerTo(selection, {
      ...(isMobile
        ? {
            anchorTopPercentage: 0.25,
          }
        : {
            anchorLeftPercentage: 0.65,
          }),
    });
  };

  // performs an update
  async function updateText(params) {
    // check for some defaults
    params.text = params.text || text;
    params.fontName = params.fontName || fontDisplayName;

    // update the text info
    const update = new UpdateTextAction(selectedGroups);
    update.apply(params);
    const changes = update.resolve();

    // apply the update
    batch(async () => {
      if (params.preview) {
        await applySvgChangeSetAction.apply(changes, { preview: true });
        if (isMobile) {
          await centerOnText();
        }
      } else {
        UIState.reset();
        await applySvgChangeSetAction.apply(changes, {
          preview: params.preview ?? false,
        });
        dispatch(refreshPathSelection());
        if (isMobile) {
          await centerOnText();
        }
      }
    });
  }

  // font changes
  function onSelectFont(font, style) {
    clearTimer();

    const styleToUse =
      style ||
      font.fontStyles.find(
        (item) => item.displayStyle === fontDisplayStyle.displayStyle
      ) ||
      font.fontStyles.find((item) => /regular/i.test(item.displayStyle)) ||
      font.fontStyles[0];
    updateText({
      fontName: font.fontDisplayName,
      fontStyle: styleToUse,
    });
  }

  // style changes
  function onSelectStyle(style) {
    clearTimer();
    updateText({ fontStyle: style });
  }

  const toggleFilter = (category) => {
    if (enabledFilters.includes(category)) {
      setEnabledFilters(enabledFilters.filter((c) => c !== category));
    } else {
      setEnabledFilters([...enabledFilters, category]);
    }
  };

  const fontFilters = fontFilterConfig.map(
    ({ displayName, category, icon, i18nKey }) => (
      <FloatingPanel.Dropdown.Filter
        onSelect={() => toggleFilter(category)}
        selected={enabledFilters.includes(category)}
        icon={icon}
        tooltip={displayName || category}
        i18nKey={i18nKey}
        key={i18nKey}
      />
    )
  );

  const fontDropdownItem = ({
    font,
    style,
    label,
    className,
    hidden,
    subItems,
    selected,
  }) => {
    const isSingleLine = font.fontCategories.includes('Single Line');
    const key = `${font.fontDisplayName}-${style.displayStyle}`;
    const fontSize = font.fontDisplaySizeOverride || 16;
    const supported = textSupportsFont[style.fontFamily] || false;
    const classNameExtended = classNames(className, {
      'single-line-font-svg': isSingleLine,
    });

    if (isSingleLine) {
      const rawSvg = createText(label, style, 'normal', {
        fill: 'none',
        stroke: 'black',
        'font-size': fontSize,
      });
      const svg = SvgOps.getUsvgString(rawSvg, {
        forceOpenPaths: true,
      });
      return (
        <FloatingPanel.Dropdown.Item
          key={key}
          hidden={hidden}
          disabled={!supported}
          className={classNameExtended}
          value={font}
          secondaryValue={style}
          selected={selected}
          subItems={subItems}
        >
          <div
            className='slf-svg'
            style={{
              position: 'relative',
              top: fontSize / 2,
              left: -fontSize,
            }}
            dangerouslySetInnerHTML={{ __html: svg }}
          />
          <div className='label'>{label}</div>
        </FloatingPanel.Dropdown.Item>
      );
    }

    return (
      <FloatingPanel.Dropdown.Item
        key={key}
        hidden={hidden}
        disabled={!supported}
        className={classNameExtended}
        value={font}
        secondaryValue={style}
        selected={selected}
        subItems={subItems}
      >
        <div
          style={{ fontSize: `${fontSize}px`, fontFamily: style.fontFamily }}
          className='label'
        >
          {label}
        </div>
      </FloatingPanel.Dropdown.Item>
    );
  };

  const [supportedFonts, unsupportedFonts] = partition(
    FONTS,
    (font) => textSupportsFont[font.fontStyles[0].fontFamily] || false
  );

  const fonts = [...supportedFonts, ...unsupportedFonts].map((font) => {
    const isFilteredOut =
      enabledFilters.length > 0 &&
      !font.fontCategories.some((c) => enabledFilters.includes(c));
    const expandable = font.fontStyles.length > 1;

    const subItems = (() => {
      if (!expandable) {
        return [];
      }

      return font.fontStyles.map((style) =>
        fontDropdownItem({
          font,
          style,
          label: style.displayStyle,
          selected: font === selectedFont && style === selectedStyle,
          subItems: [],
        })
      );
    })();

    return fontDropdownItem({
      font,
      style: font.fontStyles[0],
      label: font.fontDisplayName,
      hidden: isFilteredOut,
      subItems,
    });
  });

  const styles = selectedFont.fontStyles.map((style, styleIndex) => {
    if (style.isSingleLine) {
      const rawSvg = createText('Regular', style, 'normal', {
        fill: 'none',
        stroke: 'black',
        'font-size': 16,
      });
      const svg = SvgOps.getUsvgString(rawSvg);
      return (
        <FloatingPanel.Dropdown.Item key={styleIndex} value={style}>
          <div
            className='single-line-font-svg'
            dangerouslySetInnerHTML={{ __html: svg }}
          />
        </FloatingPanel.Dropdown.Item>
      );
    }
    return (
      <FloatingPanel.Dropdown.Item
        key={styleIndex}
        value={style}
        className={`font-weight-${style.displayStyle}`}
        style={{ fontFamily: style.fontFamily }}
      >
        {style.displayStyle}
      </FloatingPanel.Dropdown.Item>
    );
  });

  const clearTimer = () => {
    if (previewTimer.current) {
      clearTimeout(previewTimer.current);
      previewTimer.current = null;
    }
  };

  const onFontDropdownExpand = () => {
    centerOnText();
    clearTimer();
  };

  const onFontDropdownDismiss = () => {
    UIState.reset();
    clearTimer();
  };

  const onPreview = (font, style) => {
    if (previewTimer.current) {
      clearTimeout(previewTimer.current);
      previewTimer.current = null;
    }
    updateText({
      fontName: font.fontDisplayName,
      fontStyle: style,
      preview: true,
    });
  };

  const onPreviewEnd = () => {
    previewTimer.current = setTimeout(() => {
      UIState.reset();
      if (isMobile) {
        centerOnText();
      }
    }, 300);
  };

  useEffect(() => {
    return () => {
      UIState.reset();
      clearTimer();
    };
  }, []);

  return (
    <FloatingPanel.Group>
      <FloatingPanel.Label icon='font'>
        <TranslationText i18nKey='font'>Font</TranslationText>
      </FloatingPanel.Label>

      <FloatingPanel.Dropdown
        onChange={onSelectFont}
        onPreview={onPreview}
        onPreviewEnd={onPreviewEnd}
        onExpand={onFontDropdownExpand}
        onDismiss={onFontDropdownDismiss}
        value={selectedFont}
        secondaryValue={selectedStyle}
        className='font-floating-panel'
        dataCy='font-selector'
        disabled={disabled}
        filters={fontFilters}
      >
        {fonts}
      </FloatingPanel.Dropdown>

      {styles.length > 1 && (
        <FloatingPanel.Dropdown
          dataCy='style-selector'
          onChange={onSelectStyle}
          value={selectedStyle}
          disabled={disabled}
        >
          {styles}
        </FloatingPanel.Dropdown>
      )}
    </FloatingPanel.Group>
  );
}
