import React from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';

// components
import DistanceBetweenPoints from './Polyline/DistanceBetweenPoints';
import {
  selectMostRecentlyMovedPoint,
  selectSelectedGroupIds,
} from '@/Redux/Slices/SelectionSlice';
import {
  ACTIVE_POINT_SIZE_CONSTANT,
  ACTIVE_POINT_SIZE_CONSTANT_2,
  POINT_SIZE_CONSTANT,
  SHAPE_EDITOR_POINT_SIZE__SELECTED,
} from '@/Constants/UI';
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';

const NO_OVERRIDES = { x: 0, y: 0 };

export default function SelectedLine({ ui }) {
  const [a, b] = useSelector(selectSelectedLine);
  const getGroupById = useSelector(selectGetGroupById);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);
  const selectedLine = useSelector(selectSelectedLine);
  const mostRecentlyMovedPoint = useSelector(selectMostRecentlyMovedPoint);
  const nspf = useSelector(selectNonScalingPixelFactor);

  // only show if there are two groups and the IDs match the selected line
  if (
    selectedGroupIds.length !== 2 ||
    !selectedGroupIds?.includes(a) ||
    !selectedGroupIds?.includes(b)
  ) {
    return null;
  }

  const pointA = getGroupById(a);
  const pointB = getGroupById(b);
  const groupA = ui.groups.find((group) => group.id === a);
  const groupB = ui.groups.find((group) => group.id === b);
  const overridesA =
    ui.overrides?.groups?.[pointA.id]?.translate || NO_OVERRIDES;
  const overridesB =
    ui.overrides?.groups?.[pointB.id]?.translate || NO_OVERRIDES;

  const selected = selectedGroupIds.includes(pointA.id);
  const isPointAActive =
    selectedLine?.includes(pointA.id) &&
    selected &&
    mostRecentlyMovedPoint === pointA.id;
  const extension = 0.5;

  const point1 = isPointAActive ? pointA : pointB;
  const point2 = isPointAActive ? pointB : pointA;
  const overrides1 = isPointAActive ? overridesA : overridesB;
  const overrides2 = isPointAActive ? overridesB : overridesA;

  // Compute the direction vector
  const dx =
    point2.position.x + overrides2.x - (point1.position.x + overrides1.x);
  const dy =
    point2.position.y + overrides2.y - (point1.position.y + overrides1.y);

  // Compute extended point E, moving backwards from A
  const x1 = point1.position.x + overrides1.x - dx * extension;
  const y1 = point1.position.y + overrides1.y - dy * extension;

  // B remains the end of the line
  const x2 = point2.position.x + overrides2.x;
  const y2 = point2.position.y + overrides2.y;

  const midX1 = (x1 + x2) / 2;
  const midY1 = (y1 + y2) / 2;
  const midDistance = Math.hypot(x2 - x1, y2 - y1) / 2;

  const activePointSize =
    SHAPE_EDITOR_POINT_SIZE__SELECTED *
    ACTIVE_POINT_SIZE_CONSTANT_2 *
    nspf *
    POINT_SIZE_CONSTANT *
    ACTIVE_POINT_SIZE_CONSTANT;

  return (
    <>
      <DistanceBetweenPoints origin={groupA} target={groupB} ui={ui} />
      <defs>
        <radialGradient
          id='gradient'
          cx={midX1}
          cy={midY1}
          r={midDistance}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='60%' stopColor='#6b93ff' stopOpacity='1' />
          <stop offset='100%' stopColor='#6b93ff' stopOpacity='0' />
        </radialGradient>
      </defs>
      {/* dashed continuate of selected line (`EX``XY`) */}
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        className='selected-line-dash'
        stroke='url(#gradient)'
      />
      {/* original selected line `XY` */}
      <line
        x1={pointA.position.x + overridesA.x}
        y1={pointA.position.y + overridesA.y}
        x2={pointB.position.x + overridesB.x}
        y2={pointB.position.y + overridesB.y}
        className='selected-line'
      />

      {/* active circle is here again so that the selected line does not lie 
      on top of the active point */}
      <circle
        className={`active-line-point`}
        r={activePointSize}
        cx={point1.position.x}
        cy={point1.position.y}
      />
    </>
  );
}
