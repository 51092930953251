import SvgGroup from '@/UILayer/Components/SvgGroup';
import { getTypeProperty } from '@shapertools/sherpa-svg-generator/PathTypes';
import SvgGroupCls from '../State/Helpers/SvgGroup';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { useSelector } from 'react-redux';
import { selectSelectedGroupIds } from '@/Redux/Slices/SelectionSlice';
import { Mode } from '@/@types/shaper-types';
import React from 'react';
import { isEqual } from 'lodash';

type Props = {
  className?: string;
  renderMode: Mode;
  groups: SvgGroupCls[];
  extendGroup?: (group: SvgGroupCls) => object;
};

// renders a view of
export default React.memo(
  function SvgGroups(props: Props) {
    const selectedGroupIds = useSelector(selectSelectedGroupIds);

    const groupSort = (a: SvgGroupCls, b: SvgGroupCls) => {
      const pointSort =
        (b.tool?.type === Shape.POINT) === (a.tool?.type === Shape.POINT)
          ? 0
          : a.tool?.type === Shape.POINT
          ? 1
          : -1;
      const typeSort =
        getTypeProperty(b.data?.type, 'selectionOrder') >
        getTypeProperty(a.data?.type, 'selectionOrder')
          ? 1
          : -1;
      return pointSort || typeSort;
    };

    return props.groups
      .filter((g) => getTypeProperty(g.data?.type, props.renderMode))
      .sort((a, b) => groupSort(a, b))
      .map((group) => {
        const extend = props.extendGroup ? props.extendGroup(group) : {};
        return (
          <SvgGroup
            selected={selectedGroupIds.includes(group.id)}
            group={group}
            key={`group_${group.id}`}
            {...extend}
          />
        );
      });
  },
  (prev, next) => isEqual(prev, next) // We use isEqual since UIState.groups is reconstructed quite frequently
);
