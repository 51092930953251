import { Dispatch, PayloadAction, UnknownAction } from '@reduxjs/toolkit';
import { SyncListenerApi } from '../SyncListener';
import SetSelectionAction from '@/Actions/SetSelection';
import { useSelector } from 'react-redux';
import {
  setCanvasState,
  SetCanvasStateOptions,
} from '@/Redux/Slices/CanvasSlice';
import {
  addSVGGeometry,
  addSvgGroups,
  deleteGroupIdsAndAddSimplePolygonGeometry,
  deleteSvgGroup,
  duplicateSelectedGroups,
  redoCanvas,
  setSvgGroupAnchor,
  undoCanvas,
  updateSvgGroup,
} from '@/CanvasContainer/CanvasActions';
import {
  selectHasSelection,
  selectMostRecentlyAddedGroup,
  updateActiveSelection,
} from '@/Redux/Slices/SelectionSlice';
import { toggleEditSelectionProperties } from '@/Redux/Slices/UISlice';
import { RootState } from '@/Redux/store';

const checkPropertiesPanelSelection = (
  state: RootState,
  dispatch: Dispatch
) => {
  const hasSelection = selectHasSelection(state);
  if (hasSelection) {
    dispatch(toggleEditSelectionProperties(true));
  }
};

export const addCanvasSelectionListener = (startListening: Function) => {
  startListening({
    predicate: (action: UnknownAction) => {
      return setCanvasState.match(action) && action.payload.fromAction;
    },
    effect: async (
      action: PayloadAction<SetCanvasStateOptions>,
      { dispatch, getState }: SyncListenerApi
    ) => {
      const originalAction = action.payload.fromAction;
      if (originalAction) {
        if (addSVGGeometry.fulfilled.match(originalAction)) {
          const setSelectionAction = new SetSelectionAction(
            dispatch,
            useSelector
          );
          setSelectionAction.mostRecent();
        }
        if (addSvgGroups.fulfilled.match(originalAction)) {
          const duplicateCount = originalAction.meta.arg.length;
          dispatch(selectMostRecentlyAddedGroup({ duplicateCount }));
        }
        if (duplicateSelectedGroups.fulfilled.match(originalAction)) {
          const duplicateCount = originalAction.meta.arg.groupIds.length;

          // unless maintain selection is explicity requested
          if (originalAction.meta.arg.maintainSelection !== true) {
            dispatch(selectMostRecentlyAddedGroup({ duplicateCount }));
          }
        }
        if (
          undoCanvas.fulfilled.match(originalAction) ||
          redoCanvas.fulfilled.match(originalAction)
        ) {
          dispatch(updateActiveSelection());
          checkPropertiesPanelSelection(getState(), dispatch);
        }
        if (
          deleteGroupIdsAndAddSimplePolygonGeometry.fulfilled.match(
            originalAction
          )
        ) {
          dispatch(selectMostRecentlyAddedGroup({}));
          checkPropertiesPanelSelection(getState(), dispatch);
        }
        if (
          updateSvgGroup.fulfilled.match(originalAction) ||
          deleteSvgGroup.fulfilled.match(originalAction) ||
          setSvgGroupAnchor.fulfilled.match(originalAction)
        ) {
          dispatch(updateActiveSelection());
        }
      }
    },
  });
};
