import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

export const MAX_ZOOM_LEVEL = 5;
export const MIN_SCALE_MULTIPLE = 0.1;
export const MAX_SCALE_MULTIPLE = 10.0;
export const SCALE_INCREMENT = 0.1;
export const MINIMUM_DRAG_THRESHOLD_DISTANCE = 5;
export const MINIMUM_BOUNDING_BOX_HANDLE_PROXIMITY = 15;
export const BOUNDING_BOX_HANDLE_DISTANCE =
  MINIMUM_BOUNDING_BOX_HANDLE_PROXIMITY;
export const TOUCH_AMPLIFY_MODIFIER = 2.5;
export const NUDGE_BONUS = 10;
export const SECONDARY_ACTION_ACTIVATION_TIME_MS = 500;
export const SELECTION_NET_STROKE_WIDTH = 2;
export const GROUP_PATH_STROKE_WIDTH = 2;
export const PATH_STROKE_DASH_LENGTH = 5;
export const SHAPE_SHIFTER_FILL_ACTIVATION_TIME = 100;

export const RESIZE_HANDLE_DISTANCE = 5;
export const ROTATION_HANDLE_DISTANCE = 25;
export const PLAN_MODE_MINIMUM_STROKE_DETECTION_WIDTH = 2;
export const EDGE_HIT_DETECTION_EXPANSION = 24;
export const TWO_POINTS_SELECTION_BOX_HEIGHT = 8;

export const SIZE_TO_HIDE_BOUNDING_BOX_HANDLE = 56;
export const SIZE_TO_INVERT_HIDDEN_BOUNDING_BOX_HANDLE = 40;

export const SELECTION_EDITOR_HOVER_STATE_THRESHOLD = 400;

export const FIT_TO_VIEW_PADDING = 0.15;
export const MERGE_POINT_DISTANCE = 4;

export const MOUSE_DOWN_LONG_PRESS_TIMING = 1000;
export const TOUCH_START_LONG_PRESS_TIMING = 750;

export const DUPLICATE_SELECTION_OFFSET = 5;

// key codes - Browser support for keyCode is deprecated. Use code below instead
// export const LEFT_ARROW = 37;
// export const UP_ARROW = 38;
// export const RIGHT_ARROW = 39;
// export const DOWN_ARROW = 40;
// export const SPACE_BAR = 32;
// export const SHIFT_KEY = 16;
// export const DELETE_KEY = 46;
// export const BACKSPACE_KEY = 8;
// export const ESC_KEY = 27;

export const FORCE_PAN_KEY = { code: 'Space', key: ' ' };
export const DEBOUNCE_SHORTCUT_TIME = 150;
export const LEFT_ARROW = { code: 'ArrowLeft', key: 'ArrowLeft' };
export const UP_ARROW = { code: 'ArrowUp', key: 'ArrowUp' };
export const RIGHT_ARROW = { code: 'ArrowRight', key: 'ArrowRight' };
export const DOWN_ARROW = { code: 'ArrowDown', key: 'ArrowDown' };
export const SPACE_BAR = { code: 'Space', key: ' ' };
export const SHIFT_LEFT_KEY = { code: 'ShiftLeft', key: 'Shift' };
export const SHIFT_RIGHT_KEY = { code: 'ShiftRight', key: 'Shift' };
export const DELETE_KEY = { code: 'Delete', key: 'Delete' };
export const BACKSPACE_KEY = { code: 'Backspace', key: 'Backspace' };
export const ESC_KEY = { code: 'Escape', key: 'Escape' };
export const ENTER_KEY = { code: 'Enter', key: 'Enter' };
export const DUPLICATE_KEY = { code: 'KeyD', key: 'd' };
export const SELECT_ALL_LC = { code: 'a', key: 'a' };
export const SELECT_ALL_UC = { code: 'a', key: 'A' };

export const PLACE_CIRCLE_KEY = { code: 'c', key: 'C' };
export const PLACE_ELLIPSE_KEY = { code: 'e', key: 'E' };
export const PLACE_RECTANGLE_KEY = { code: 'r', key: 'R' };
export const PLACE_ROUNDED_RECTANGLE_KEY = { code: 'r', key: 'R' };
export const PLACE_POLYGON_KEY = { code: 'p', key: 'P' };
export const PLACE_TEXT_KEY = { code: 't', key: 'T' };
export const START_DRAW_KEY = { code: 'd', key: 'D' };

export const CUT_KEY = { code: 'x', key: 'X' };
export const COPY_KEY = { code: 'c', key: 'C' };
export const PASTE_KEY = { code: 'v', key: 'V' };
export const UNDO_KEY = { code: 'z', key: 'Z' };
export const REDO_LEGACY_KEY = { code: 'y', key: 'Y' };
export const REDO_KEY = { code: 'z', key: 'Z' };
export const MIRROR_HORIZONTAL_KEY = { code: 'h', key: 'H' };
export const MIRROR_VERTICAL_KEY = { code: 'v', key: 'V' };
export const FIND_ART_KEY = { code: 'f', key: 'F' };

export const ZOOM_IN_KEY = { code: 'Equal', key: '=' }; // actually, +, but they aren't using shift
export const ZOOM_OUT_KEY = { code: 'Minus', key: '-' };

export const MOVE_TO_ZERO_KEY = { code: 'Digit0', key: '0' };
export const NEW_DESIGN_KEY = { code: 'KeyN', key: 'N' };

export const FIT_TO_VIEW_KEY = { code: 'z', key: 'Z' };

// types of shapes that can be created as tools
export const GENERATED_SHAPE_TYPES = [
  Shape.CIRCLE,
  Shape.ROUNDED_RECT,
  Shape.POLYGON,
  Shape.SHAPE,
];

export const SHAPES_UPDATES_REQUIRED_WITH_BOUNDS_CHANGE = [Shape.ROUNDED_RECT];

export const TOOL_TYPES = [
  Shape.ROUNDED_RECT,
  Shape.CIRCLE,
  Shape.POLYGON,
  Shape.TEXT,
];

export const GROUP_ANCHORS = [
  'tl',
  'tm',
  'tr',
  'lm',
  'center',
  'rm',
  'bl',
  'bm',
  'br',
];

export const BOUNDING_BOX_HANDLES = [
  'tr',
  'bl',
  'tl',
  'br',
  'tm',
  'bm',
  'rm',
  'lm',
  'rotate',
  'rotate_tl',
  'rotate_tr',
  'rotate_bl',
  'rotate_br',
];

export const RESIZE_HANDLES = ['tr', 'bl', 'tl', 'br', 'tm', 'bm', 'rm', 'lm'];

export const ROTATION_HANDLES = [
  'rotate',
  'rotate_tl',
  'rotate_tr',
  'rotate_bl',
  'rotate_br',
];

export const BOUNDING_BOX_HANDLE_RESIZE_DIRECTION = [
  'resize-ne',
  'resize-ne',
  'resize-sw',
  'resize-sw',
  'resize-ns',
  'resize-ns',
  'resize-ew',
  'resize-ew',
  'rotate',
  'rotate_tl',
  'rotate_tr',
  'rotate_bl',
  'rotate_br',
];

export const CUT_DEPTH_PRESETS = [
  { mm: 2, d: 16 },
  { mm: 5, d: 8 },
  { mm: 8, n: 3, d: 16 },
  { mm: 10, d: 4 },
  { mm: 12, n: 3, d: 8 },
  { mm: 15, d: 2 },
];

export const OFFSET_PRESETS = [
  { in: 0.001, mm: 0.02 },
  { in: 0.005, mm: 0.1 },
  { in: 0.01, mm: 0.5 },
  { d: 32, mm: 1 },
  { d: 16, mm: 2 },
  { d: 8, mm: 5 },
];

export const BIT_DIAMETER_PRESETS = [
  { in: 0.508, mm: 0.5, engrave: true },
  { d: 8, mm: 3 },
  { d: 4, mm: 6 },
];

export const SHAPE_EDITOR_POINT_SIZE = 4;
export const SHAPE_EDITOR_POINT_SIZE__SELECTED = 6;
export const DISTANCE_BETWEEN_LABEL_OFFSET = 20;
export const POINT_SIZE_CONSTANT = 0.5;
export const POINT_SIZE_CONSTANT_2 = 0.8;
export const ACTIVE_POINT_SIZE_CONSTANT = 1.25;
export const ACTIVE_POINT_SIZE_CONSTANT_2 = 1.5;
