import { selectTemporaryAnchor } from '@/Redux/Slices/SelectionSlice';

import BaseAction from './BaseAction';
import ResizeGroupsAction from './ResizeGroups';
import RotateGroupsAction from './RotateGroups';
import SetSvgGroupAnchorAction from './SetSvgGroupAnchor';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Anchor } from '@/@types/shaper-types';
import UpdateSvgGroupAction from './UpdateSvgGroup';
import { UpdateSvgGroupPayload } from '@/CanvasContainer/CanvasActions';
import { updateKeyAndValue } from '@/Geometry/SvgGroupOps';

// TODO: move to SelectionBoxHelper once it's typescript
export const isCenterAnchor = (
  anchor: Anchor
): anchor is 'center' | 'centroid' => ['center', 'centroid'].includes(anchor);

type MirrorOpts = { horizontal?: boolean; vertical?: boolean };

export default class MirrorAction extends BaseAction {
  mirror(groups: SvgGroup[], { horizontal, vertical }: MirrorOpts) {
    if (!groups.length) {
      return;
    }

    const anchor = this.getCurrentAnchor(groups);

    // check the anchor combination
    const isVerticalAnchor = ['rm', 'lm'].includes(anchor);
    const isHorizontalAnchor = ['tm', 'bm'].includes(anchor);
    const isMidAnchor =
      (horizontal && isHorizontalAnchor) || (vertical && isVerticalAnchor);

    // check if this should use the anchor when determining
    // the resize change
    let useAnchor;
    if (!isMidAnchor) {
      useAnchor = anchor;
    }

    const batchedMirrorChanges: UpdateSvgGroupPayload[] = [];

    // perform the resize first
    const resize = this.createAction(
      ResizeGroupsAction,
      groups,
      useAnchor || 'center'
    );
    resize.resizeByScale(horizontal ? -1 : 1, vertical ? -1 : 1);
    const resizeChanges = resize.resolveWithoutUpdate();
    batchedMirrorChanges.push(...resizeChanges);

    // fix the anchor
    if (!isMidAnchor) {
      batchedMirrorChanges.push(
        ...this.mirrorAnchorUpdates(groups, { horizontal, vertical })
      );
    }

    // update each rotation
    if (groups.length === 1) {
      const [group] = groups;
      const newGroup = JSON.parse(JSON.stringify(group));

      // apply the updates to get accurate delta pos
      for (const update of batchedMirrorChanges) {
        updateKeyAndValue(newGroup, update.update);
      }

      const rotate = this.createAction(RotateGroupsAction, [newGroup]);
      const delta = -(newGroup.rotation - -newGroup.rotation);
      rotate.rotateByRadiansWithGroup(delta, newGroup);
      const rotationChanges = rotate.resolveWithoutUpdate();
      batchedMirrorChanges.push(...(rotationChanges || []));
    }

    const update = this.createAction(UpdateSvgGroupAction);
    update.apply(batchedMirrorChanges);
  }

  getCurrentAnchor(groups: SvgGroup[]): Anchor {
    const temporaryAnchor = this.useSelector(selectTemporaryAnchor);
    return (
      (groups.length > 1 ? temporaryAnchor : groups[0]?.anchor) || 'centroid'
    );
  }

  mirrorAnchor(groups: SvgGroup[], { horizontal, vertical }: MirrorOpts) {
    const anchor = this.getCurrentAnchor(groups);

    // can't mirror center
    if (isCenterAnchor(anchor) || !(horizontal || vertical)) {
      return;
    }

    // figure out the anchor direction
    const anchorTo = this.createAction(SetSvgGroupAnchorAction);
    const opposite = SetSvgGroupAnchorAction.getOppositeAnchorRelativeToAxis(
      anchor,
      horizontal ? 'h' : 'v'
    );

    // fix appropriately
    if (groups.length > 1) {
      anchorTo.setTemporaryAnchor(opposite);
    } else if (groups.length === 1) {
      anchorTo.setAnchor(
        groups.map((group) => group.id),
        opposite
      );
      anchorTo.setAnchor([groups[0].id], opposite);
    }
  }

  mirrorAnchorUpdates(
    groups: SvgGroup[],
    { horizontal, vertical }: MirrorOpts
  ) {
    const anchor = this.getCurrentAnchor(groups);

    // can't mirror center
    if (isCenterAnchor(anchor) || !(horizontal || vertical)) {
      return [];
    }

    // figure out the anchor direction
    const anchorTo = this.createAction(SetSvgGroupAnchorAction);
    const opposite = SetSvgGroupAnchorAction.getOppositeAnchorRelativeToAxis(
      anchor,
      horizontal ? 'h' : 'v'
    );

    // fix appropriately
    if (groups.length > 1) {
      anchorTo.setTemporaryAnchor(opposite);
    } else if (groups.length === 1) {
      const updates = [];
      updates.push(
        ...anchorTo.getSetAnchorUpdates(
          groups.map((g) => g.id),
          opposite
        )
      );
      updates.push(...anchorTo.getSetAnchorUpdates([groups[0].id], opposite));
      return updates;
    }
    return [];
  }
}
