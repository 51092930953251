import {
  createAction,
  createSlice,
  isAction,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import SelectionBoxOps from '@/Helpers/SelectionBoxHelper';

import { getGroupById, getPathById } from '@/Redux/Slices/CanvasSlice';
import {
  PATH_TYPES,
  getTypeProperty,
} from '@shapertools/sherpa-svg-generator/PathTypes';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import { RootState } from '../store';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import {
  doRefreshPathSelection,
  doSetSelection,
  doUpdateActiveSelection,
  doSelectMostRecentlyAddedGroup,
} from '@/Selection/SelectionHelpers';
import {
  SetMostRecentlyMovedPointPayload,
  SetSelectedLinePayload,
} from '@/LineTool/types';
import { Anchor, Handle } from '@/@types/shaper-types';
import { isCenterAnchor } from '@/Actions/Mirror';
import SelectionBox from '@/Helpers/SelectionBoxHelper';
import { xor, xorBy } from 'lodash';

const getSelectionBounds = function (
  selectedGroupIds: string[],
  svgGroupSet: SvgGroup[],
  temporaryAnchor: Handle | null
) {
  const selectedGroups = selectedGroupIds
    .map((gId) => svgGroupSet.find((sg) => sg.id === gId))
    .filter((sg): sg is SvgGroup => !!sg); //filter for valid items
  return SelectionBoxOps.getSelectionBounds(
    selectedGroups,
    temporaryAnchor || undefined
  );
};

const getSelectionAnchorPosition = function (
  state: RootState,
  selectedGroupIds: string[],
  svgGroupSet: SvgGroup[]
) {
  const selectedGroups = selectedGroupIds
    .map((gId) => svgGroupSet.find((sg) => sg.id === gId))
    .filter((g): g is SvgGroup => g !== undefined);
  const [group] = selectedGroups;
  if (group) {
    const isSingleSelection = selectedGroups.length === 1;
    const groupAnchor = isCenterAnchor(group.anchor) ? undefined : group.anchor;
    const anchor = isSingleSelection
      ? groupAnchor
      : state.selection.temporaryAnchor;
    const rotation = isSingleSelection ? group.rotation : 0;
    const bounds = SelectionBoxOps.getSelectionBounds(
      selectedGroups,
      groupAnchor
    ) as SelectionBox;

    return SelectionBoxOps.getAnchoredPosition(
      bounds,
      bounds.centroidPosition,
      rotation,
      anchor || undefined
    );
  }
};

const isNotNullOrUndefined = <T>(arg: T | null | undefined): arg is T =>
  arg !== null && arg !== undefined;

export type GroupId = string;
export type PathId = { groupId: string; pathId: string };
type GroupIds = GroupId[];
type PathIds = PathId[];

export type SelectionState = {
  groupIds: GroupIds;
  pathIds: PathIds;
  temporaryAnchor: Handle | null;
  mostRecentlyMovedPoint: string | null;
  selectedLine: string[] | null;
};

export const initialState: SelectionState = {
  groupIds: [],
  pathIds: [], //{groupId, pathId}
  temporaryAnchor: null,
  mostRecentlyMovedPoint: null,
  selectedLine: null,
};

export type GroupAndPathId = { groupId: string; pathId: string };

export const setSelection = createAction<(GroupId | PathId)[]>(
  'selection/setSelection'
);
export const updateActiveSelection = createAction(
  'selection/updateActiveSelection'
);
export const refreshPathSelection = createAction(
  'selection/refreshPathSelection'
);
export const selectMostRecentlyAddedGroup = createAction<{
  duplicateCount?: number;
}>('selection/selectMostRecentlyAddedGroup');

export const setMostRecentlyMovedPoint = createAction<string | null>(
  'selection/setMostRecentlyMovedPoint'
);

export const setSelectedLine = createAction<string[] | null>(
  'selection/setSelectedLine'
);

export const slice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    // replaces selections assuming the logic for using
    // correct selections has been performed elsewhere
    replaceSelection: (
      state,
      action: PayloadAction<{ groupIds: GroupIds; pathIds: PathIds }>
    ) => {
      const { groupIds, pathIds } = action.payload;

      // check if anything actually changed
      const groupsChanged = !!xor(state.groupIds, groupIds).length;
      const pathsChanged = !!xorBy(state.pathIds, pathIds, (v) => v.pathId)
        .length;
      const hasChanges = groupsChanged || pathsChanged;

      // only if the selection actually changes, automatically clear
      // any temporary anchors set (check if this is desired)
      if (hasChanges) {
        state.temporaryAnchor = null;
      }

      // update the selection
      state.groupIds = groupIds;
      state.pathIds = pathIds;
    },

    setTemporaryAnchor: (state, action: PayloadAction<Handle>) => {
      state.temporaryAnchor = action.payload;
    },

    clearTemporaryAnchor: (state) => {
      state.temporaryAnchor = null;
    },

    // clear all selections
    clearSelection: (state) => {
      state.groupIds = [];
      state.pathIds = [];
      state.temporaryAnchor = null;
    },

    setMostRecentlyMovedPoint: (
      state,
      action: PayloadAction<SetMostRecentlyMovedPointPayload>
    ) => {
      state.mostRecentlyMovedPoint =
        action.payload ?? state.mostRecentlyMovedPoint;
    },

    setSelectedLine: (state, action: PayloadAction<SetSelectedLinePayload>) => {
      state.selectedLine = action.payload;

      // replace the most recently moved point if it isn't part of the current line
      if (
        !state.selectedLine?.includes(state.mostRecentlyMovedPoint as string)
      ) {
        state.mostRecentlyMovedPoint = state.selectedLine?.[0];
      }
    },
  },
});

export const {
  replaceSelection,
  clearSelection,
  setTemporaryAnchor,
  clearTemporaryAnchor,
} = slice.actions;

export const middleware: Middleware = (store) => (next) => (action) => {
  if (isAction(action) && action.type && action.type.includes('selection/')) {
    const { canvas, ui, selection } = store.getState();
    const dispatch = store.dispatch;

    const mode = ui.mode;
    if (setSelection.match(action)) {
      const { groupIds, pathIds } = doSetSelection(action.payload, canvas);
      dispatch(replaceSelection({ groupIds, pathIds }));
    }
    if (updateActiveSelection.match(action)) {
      const { groupIds, pathIds } = doUpdateActiveSelection(
        selection,
        canvas,
        mode
      );
      dispatch(replaceSelection({ groupIds, pathIds }));
    }
    if (refreshPathSelection.match(action)) {
      const { groupIds, pathIds } = doRefreshPathSelection(
        selection,
        canvas,
        mode
      );
      dispatch(replaceSelection({ groupIds, pathIds }));
    }
    if (selectMostRecentlyAddedGroup.match(action)) {
      const { groupIds, pathIds } = doSelectMostRecentlyAddedGroup(
        action.payload,
        canvas
      );
      dispatch(replaceSelection({ groupIds, pathIds }));
    }
  }

  // perform the update
  next(action);
};

export const selectHasSelection = (state: RootState) => {
  const mode = `${state.ui.mode}`;
  return (
    state.selection.groupIds
      .map((gId) => getGroupById(state, gId))
      .filter((g) => getTypeProperty(g?.type || PATH_TYPE.DESIGN, mode))
      .length > 0
  );
};
export const selectSelectedGroupIds = (state: RootState) =>
  state.selection.groupIds;
export const selectSelectedPathIds = (state: RootState) =>
  state.selection.pathIds;
export const selectSelectionType = (state: RootState) => {
  if (state.selection.groupIds && state.selection.groupIds.length > 0) {
    const firstSelection: SvgGroup | null | undefined = state.selection.groupIds
      .map((gId) => getGroupById(state, gId))
      .at(0);
    if (firstSelection) {
      return PATH_TYPES[(firstSelection.type as PATH_TYPE) || PATH_TYPE.DESIGN];
    }
  }
  return null;
};

export const selectMostRecentlyMovedPoint = (state: RootState) =>
  state.selection.mostRecentlyMovedPoint;
export const selectSelectedLine = (state: RootState) =>
  state.selection.selectedLine;

export const selectSelectedGroups = (state: RootState) =>
  state.selection.groupIds
    .map((gId) => getGroupById(state, gId))
    .filter((g): g is SvgGroup => g !== null)
    .filter((g) => getTypeProperty(g.type, state.ui.mode));

export const selectSelectedPaths = (state: RootState) =>
  state.selection.pathIds
    .map((idPair) => getPathById(state, idPair.pathId, idPair.groupId))
    .filter(isNotNullOrUndefined);

export const selectSelectionBounds = (state: RootState) =>
  getSelectionBounds(
    state.selection.groupIds,
    state.canvas.canvas.svgGroupSet,
    state.selection.temporaryAnchor
  ) as SelectionBox;

export const selectSelectionBoundsWithGroupOverrides =
  (state: RootState) => (overrides: object) => {
    const entriesMap = Object.fromEntries(
      state.canvas.canvas.svgGroupSet.map((group) => [group.id, group])
    );
    for (const [key, value] of Object.entries(overrides)) {
      entriesMap[key] = value;
    }

    return getSelectionBounds(
      state.selection.groupIds,
      Object.values(entriesMap),
      state.selection.temporaryAnchor
    );
  };
export const selectSelectionAnchorPosition = (state: RootState) =>
  getSelectionAnchorPosition(
    state,
    state.selection.groupIds,
    state.canvas.canvas.svgGroupSet
  );
export const selectTemporaryAnchor = (state: RootState) =>
  state.selection.temporaryAnchor;
export const selectActiveAnchor = (state: RootState): Anchor => {
  const isSingleSelection = state.selection?.groupIds?.length === 1;
  const defaultAnchor = 'center';

  // if this is a single selected object and has an anchor
  if (isSingleSelection) {
    const group = getGroupById(state, state.selection.groupIds[0]);
    return group?.anchor || defaultAnchor;
  }

  return state.selection.temporaryAnchor || defaultAnchor;
};

export const actions = slice.actions;
export default slice.reducer;
