import React from 'react';
import { useSelector } from 'react-redux';
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import {
  selectLineToolActiveShapeId,
  selectIsShowingDrawModeShapePreview,
} from '@/Redux/Slices/UISlice';
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';

export default function DrawToolPreview() {
  const nspf = useSelector(selectNonScalingPixelFactor);
  const shapeId = useSelector(selectLineToolActiveShapeId);
  const getGroupById = useSelector(selectGetGroupById);
  const isShowingDrawModeShapePreview = useSelector(
    selectIsShowingDrawModeShapePreview
  );

  if (!shapeId) {
    return null;
  }

  const shape = getGroupById(shapeId);

  const points = shape.tool.params.points.map((pointId: string) =>
    getGroupById(pointId)
  );

  if (!isShowingDrawModeShapePreview) {
    return null;
  }

  return (
    <>
      <g className='draw-tool-preview'>
        <path
          className='draw-tool-preview__path'
          d={
            points.reduce((acc: string, point: any, i: number) => {
              const command = i === 0 ? 'M' : 'L';
              return `${acc} ${command} ${point.position.x} ${point.position.y}`;
            }, '') +
            (points.length > 0
              ? ` L ${points[0].position.x} ${points[0].position.y}`
              : '')
          }
          fill='none'
          stroke='#ff0000'
        />
        {points.map((point: any, index: number) => (
          <circle
            className={`draw-tool-preview__point ${
              index === 0 ? 'draw-tool-preview__closing-point' : ''
            }`}
            key={point.id}
            cx={point.position.x}
            cy={point.position.y}
            r={2 * nspf}
            fill='#ff0000'
          />
        ))}
      </g>
    </>
  );
}
