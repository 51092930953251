import ScaleViewportAction from '@/Actions/ScaleViewport';
import BaseInteraction from './BaseInteraction';

const ZOOM_DELAY = 17;
const SCALE_MODIFIER = 0.1;

export default class ZoomViewportInteraction extends BaseInteraction {
  interactionId = 'Zoom Viewport';

  onMouseWheel(event) {
    // throttle
    const now = Date.now();
    if (now < this.nextAllowedZoom || 0) {
      return;
    }

    // set the next allowed time
    this.nextAllowedZoom = now + ZOOM_DELAY;

    // perform the zoom
    const origin = { x: event.clientX, y: event.clientY };
    const scale = Math.sign(event.deltaY) * SCALE_MODIFIER;
    this.scaleViewport(scale, origin);
  }

  scaleViewport(scale, point) {
    const action = this.createAction(ScaleViewportAction);
    action.scaleBy(scale, point);
  }
}
