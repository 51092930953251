import { REDO_KEY, REDO_LEGACY_KEY, UNDO_KEY } from '@/Constants/UI';
import { cancelEvent } from '@/Utility/events';

export function isEnterKey(event) {
  return event.keyCode === 13;
}

export function isEscKey(event) {
  return event.keyCode === 27;
}

export function isDeleteKey(event) {
  return event.keyCode === 46;
}

export function isBackspaceKey(event) {
  return event.keyCode === 8;
}

export function isArrowKey(event) {
  return (
    event.keyCode === 37 ||
    event.keyCode === 38 ||
    event.keyCode === 39 ||
    event.keyCode === 40
  );
}

export function isUpArrow(event) {
  return event.keyCode === 37;
}

export function isRightArrow(event) {
  return event.keyCode === 38;
}

export function isDownArrow(event) {
  return event.keyCode === 39;
}

export function isLeftArrow(event) {
  return event.keyCode === 40;
}

export function hasShift(event) {
  return event.shiftKey;
}

export function hasModifier(event) {
  return event.ctrlKey || event.metaKey;
}

export function isUndoInput(event) {
  const modifier = hasModifier(event);
  const shift = hasShift(event);
  return modifier && !shift && isKey(event, UNDO_KEY);
}

export function isRedoInput(event) {
  const modifier = hasModifier(event);
  const shift = hasShift(event);
  return (
    (modifier && isKey(event, REDO_LEGACY_KEY)) ||
    (modifier && shift && isKey(event, REDO_KEY))
  );
}

export function isKey(event, ...compare) {
  for (const item of compare) {
    if (
      event.key === item.key ||
      event.key === item.code ||
      event.code === item.key ||
      event.code === item.code ||
      event.key === item
    ) {
      return true;
    }
  }
}

export function handleKey(event, props) {
  for (const [check, handler] of [
    [isEnterKey, 'enter'],
    [isEscKey, 'esc'],
    [isDeleteKey, 'delete'],
    [isBackspaceKey, 'backspace'],
    [isLeftArrow, 'left'],
    [isRightArrow, 'right'],
    [isUpArrow, 'up'],
    [isDownArrow, 'down'],
  ]) {
    // if matches
    if (handler in props && check(event)) {
      props[handler](event);
      break;
    }
  }

  // check for event handling
  if (props.capture) {
    cancelEvent(event);
  }
}
