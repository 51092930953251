import {
  setSvgGroupAnchor,
  UpdateSvgGroupPayload,
} from '@/CanvasContainer/CanvasActions';
import {
  setTemporaryAnchor as setTemporaryAnchorAction,
  clearTemporaryAnchor as clearTemporaryAnchorAction,
  GroupId,
} from '@/Redux/Slices/SelectionSlice';
import BaseAction from './BaseAction';
import { Handle } from '@/@types/shaper-types';
import { SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';
import { Anchor } from '@shapertools/sherpa-svg-generator/SvgGroup';

export default class SetSvgGroupAnchorAction extends BaseAction {
  // verify the selected anchor
  static normalizeAnchor(anchor: Handle) {
    return ['center', 'centroid'].includes(anchor) ? null : anchor;
  }

  setTemporaryAnchor(anchor: Handle) {
    const { dispatch } = this;

    const updateTo = SetSvgGroupAnchorAction.normalizeAnchor(anchor);
    if (updateTo) {
      dispatch(setTemporaryAnchorAction(updateTo));
    }
  }

  clearTemporaryAnchor() {
    const { dispatch } = this;
    dispatch(clearTemporaryAnchorAction());
  }

  static getOppositeAnchorRelativeToAxis(
    anchor: Handle,
    axis: 'h' | 'horizontal' | 'v' | 'vertical'
  ): Handle {
    return ['h', 'horizontal'].includes(axis)
      ? (anchor.replace(/(l|r)/gi, (side) =>
          side === 'l' ? 'r' : 'l'
        ) as Handle)
      : (anchor.replace(/(t|b)/gi, (side) =>
          side === 't' ? 'b' : 't'
        ) as Handle);
  }

  async setAnchor(groupIds: GroupId[], anchor: Handle) {
    const { dispatch } = this;

    // replaces the selected anchor
    const updateTo = SetSvgGroupAnchorAction.normalizeAnchor(anchor);
    await dispatch(
      setSvgGroupAnchor({
        ids: groupIds,
        anchor: updateTo,
      })
    );
  }

  getSetAnchorUpdates(
    groupIds: GroupId[],
    anchor: Handle
  ): UpdateSvgGroupPayload[] {
    const updateTo = SetSvgGroupAnchorAction.normalizeAnchor(anchor) as Anchor;

    return groupIds.map((id) => ({
      id,
      update: {
        key: SvgGroupUpdateKey.Anchor,
        value: updateTo,
      },
    }));
  }
}
