import { upperFirst } from 'lodash';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import BaseInteraction from '@/InteractionsManager/Interactions/BaseInteraction';

// selectors
import {
  selectIsDesignMode,
  selectIsInsertPointMode,
  selectIsMobile,
  selectIsPlanMode,
  selectIsPreviewView,
  selectIsReviewMode,
  selectIsShowingImportMode,
  selectIsShowingShapeShifter,
} from '@/Redux/Slices/UISlice';
import { selectFeatureFlags } from '@/Redux/Slices/FeatureFlagsSlice';

// actions
import TranslateGroupsAction from '@/Actions/TranslateGroups';
import UndoRedoAction from '@/Actions/UndoRedoAction';
import DeleteGroupsAction from '@/Actions/DeleteGroups';
import DuplicateGroupsAction from '@/Actions/DuplicateGroups';
import SetSelectionAction from '@/Actions/SetSelection';
import SetCursorAction from '@/Actions/SetCursor';
import ViewportActions from '@/Actions/Viewport';
import BuildBasicShapeAction from '@/Actions/BuildBasicShape';
import ToggleTextEditorModeAction from '@/Actions/ToggleTextEditorMode';
import MirrorAction from '@/Actions/Mirror';
import UIFeatureAction from '@/Actions/UIFeature';

import {
  LEFT_ARROW,
  RIGHT_ARROW,
  UP_ARROW,
  DOWN_ARROW,
  DELETE_KEY,
  ESC_KEY,
  BACKSPACE_KEY,
  DUPLICATE_KEY,
  SHIFT_LEFT_KEY,
  SHIFT_RIGHT_KEY,
  SELECT_ALL_LC,
  SELECT_ALL_UC,
  FIT_TO_VIEW_KEY,
  DUPLICATE_SELECTION_OFFSET,
  COPY_KEY,
  PASTE_KEY,
  PLACE_ELLIPSE_KEY,
  PLACE_CIRCLE_KEY,
  PLACE_RECTANGLE_KEY,
  PLACE_ROUNDED_RECTANGLE_KEY,
  PLACE_POLYGON_KEY,
  PLACE_TEXT_KEY,
  UNDO_KEY,
  REDO_LEGACY_KEY,
  REDO_KEY,
  MIRROR_HORIZONTAL_KEY,
  MIRROR_VERTICAL_KEY,
  START_DRAW_KEY,
  MOVE_TO_ZERO_KEY,
  NEW_DESIGN_KEY,
  ZOOM_IN_KEY,
  ZOOM_OUT_KEY,
  FIND_ART_KEY,
  CUT_KEY,
  DEBOUNCE_SHORTCUT_TIME,
} from '@/Constants/UI';

import { addAttributeToUser } from '../../../../Utility/userflow';
import ScaleViewportAction from '@/Actions/ScaleViewport';
import TranslateViewportAction from '@/Actions/TranslateViewport';
import CreateWorkspaceAction from '@/Actions/CreateWorkspace';
import CopyPasteAction, { MISMATCH } from '@/Actions/CopyPaste';
import isMetaKeyActivated from '@/Utility/isMetaKey';
import UIModeAction from '@/Actions/UIMode';
import AlertAction from '@/Actions/Alert';
import { ALERT_TYPES } from '@/defaults';

export default class KeyboardShortcutsInteraction extends BaseInteraction {
  interactionId = 'Keyboard Shortcuts';

  shortcuts = [
    { name: 'duplicate', down: true, modes: { design: true } },
    {
      name: 'redo',
      down: true,
      modes: { design: true, plan: true, review: true },
    },
    {
      name: 'undo',
      down: true,
      modes: { design: true, plan: true, review: true },
    },
    {
      name: 'multiSelect',
      debounce: false,
      down: true,
      up: true,
      allowInPreviewView: true,
      modes: { design: true },
    },
    { name: 'selectAll', down: true, modes: { design: true, plan: true } },
    {
      name: 'fitToView',
      down: true,
      allowInPreviewView: true,
      modes: {
        design: true,
        plan: true,
        review: true,
        shapeShifter: true,
        import: true,
      },
    },
    { name: 'deleteSelection', down: true, modes: { design: true } },
    {
      name: 'clearSelection',
      down: true,
      allowInPreviewView: true,
      modes: { design: true, plan: true, shapeShifter: true, import: true },
    },
    { name: 'nudgeLeft', down: true, modes: { design: true } },
    { name: 'nudgeRight', down: true, modes: { design: true } },
    { name: 'nudgeUp', down: true, modes: { design: true } },
    { name: 'nudgeDown', down: true, modes: { design: true } },
    { name: 'cut', down: true, modes: { design: true } },
    {
      name: 'copy',
      down: true,
      modes: { design: true, plan: true },
    },
    { name: 'paste', down: true, modes: { design: true, plan: true } },
    { name: 'pasteInPlace', down: true, modes: { design: true } },
    { name: 'placeCircle', down: true, modes: { design: true } },
    { name: 'placeEllipse', down: true, modes: { design: true } },
    { name: 'placeRectangle', down: true, modes: { design: true } },
    { name: 'placeRoundedRectangle', down: true, modes: { design: true } },
    { name: 'placePolygon', down: true, modes: { design: true } },
    { name: 'placeText', down: true, modes: { design: true } },
    { name: 'mirrorHorizontal', down: true, modes: { design: true } },
    { name: 'mirrorVertical', down: true, modes: { design: true } },
    { name: 'startDraw', down: true, modes: { design: true } },
    {
      name: 'moveToZero',
      down: true,
      allowInPreviewView: true,
      modes: {
        design: true,
        plan: true,
        review: true,
        shapeShifter: true,
        import: true,
      },
    },
    {
      name: 'newDesign',
      down: true,
      modes: { design: true, plan: true, review: true },
    },
    { name: 'findArt', down: true, modes: { design: true } },
    {
      name: 'zoomIn',
      down: true,
      allowInPreviewView: true,
      modes: {
        design: true,
        plan: true,
        review: true,
        shapeShifter: true,
        import: true,
      },
    },
    {
      name: 'zoomOut',
      down: true,
      allowInPreviewView: true,
      modes: {
        design: true,
        plan: true,
        review: true,
        shapeShifter: true,
        import: true,
      },
    },
  ];

  // undo
  isUndo = ({ modifier, event }) => modifier && this.isKey(event, UNDO_KEY);
  applyUndo = () => {
    const action = this.createAction(UndoRedoAction);
    action.undo();
  };

  // Redo
  isRedo = ({ modifier, shift, event }) =>
    (modifier && this.isKey(event, REDO_LEGACY_KEY)) ||
    (modifier && shift && this.isKey(event, REDO_KEY));

  applyRedo = () => {
    const action = this.createAction(UndoRedoAction);
    action.redo();
  };

  // Duplicate selection
  isDuplicate = ({ modifier, event }) =>
    modifier && this.isKey(event, DUPLICATE_KEY);
  applyDuplicate = ({ shift: inPlace }) => {
    const action = this.createAction(DuplicateGroupsAction);
    const ids = this.getDesignSelectedGroupIds();
    if (ids.length > 0) {
      const offset = inPlace ? 0 : DUPLICATE_SELECTION_OFFSET;
      action.duplicate(ids, { x: offset, y: offset });
    }
  };

  // Multi-select Tool
  isMultiSelect = ({ event }) =>
    this.isKey(event, SHIFT_LEFT_KEY, SHIFT_RIGHT_KEY);
  applyMultiSelect = ({ up }) => {
    const cursorAction = this.createAction(SetCursorAction);
    cursorAction[up ? 'toDefault' : 'toMultiSelect']();
  };

  // Select All
  isSelectAll = ({ modifier, event }) =>
    modifier && this.isKey(event, SELECT_ALL_LC, SELECT_ALL_UC);
  applySelectAll = () => {
    const all = this.getSvgGroups();
    const limit = all.filter(
      (group) =>
        group.tool?.type !== Shape.SHAPE && group.type !== PATH_TYPE.REFERENCE
    );

    // create the selection
    const select = [];
    for (const group of limit) {
      for (const path of group.basePathSet) {
        select.push({ groupId: group.id, pathId: path.id });
      }
    }

    const action = this.createAction(SetSelectionAction);
    action.set(select);
  };

  // Fit to View
  isFitToView = ({ event }) => this.isKey(event, FIT_TO_VIEW_KEY);
  applyFitToView = ({ shift, isDesignMode, isPlanMode }) => {
    const selectedGroups = this.getSelectedGroups();
    const source = [];

    // when pressing shift, the entire view is fit
    // regardless of selection
    if ((isDesignMode || isPlanMode) && !shift) {
      for (const group of selectedGroups) {
        source.push(
          ...group.basePathSet.map((path) => ({
            group: group,
            path: path,
          }))
        );
      }
    }

    const viewportAction = this.createAction(ViewportActions);
    viewportAction.centerTo(source);
  };

  // Delete selected layers
  isDeleteSelection = ({ event }) =>
    this.isKey(event, DELETE_KEY, BACKSPACE_KEY);
  applyDeleteSelection = async () => {
    const ids = this.getDesignSelectedGroupIds();
    const action = this.createAction(DeleteGroupsAction);
    await action.delete(ids);
  };

  // Clear selected layers
  // when inserting points, disregard any ESC behaviors
  isClearSelection = ({ event }) => {
    const isInsertPointMode = this.useSelector(selectIsInsertPointMode);
    return !isInsertPointMode && this.isKey(event, ESC_KEY);
  };

  applyClearSelection = ({ isShapeShifterMode, isImportMode }) => {
    // clear any actions
    const feature = this.createAction(UIFeatureAction);
    feature.clear();

    // when in shape shifter, go to design mode
    if (isShapeShifterMode || isImportMode) {
      const mode = this.createAction(UIModeAction);
      mode.toDefault();
    }
    // normal behavior
    else {
      const action = this.createAction(SetSelectionAction);
      action.clear();
    }
  };

  // Keyboard nudges
  isNudgeLeft = ({ event }) => this.isKey(event, LEFT_ARROW.code);
  applyNudgeLeft = ({ shift }) => this.nudge(-1, 0, shift);
  isNudgeRight = ({ event }) => this.isKey(event, RIGHT_ARROW.code);
  applyNudgeRight = ({ shift }) => this.nudge(1, 0, shift);
  isNudgeUp = ({ event }) => this.isKey(event, UP_ARROW.code);
  applyNudgeUp = ({ shift }) => this.nudge(0, -1, shift);
  isNudgeDown = ({ event }) => this.isKey(event, DOWN_ARROW.code);
  applyNudgeDown = ({ shift }) => this.nudge(0, 1, shift);

  // shared nudge code
  nudge(x, y, extendDistance) {
    const selected = this.getDesignSelectedGroupIds();
    if (!selected.length) {
      return;
    }

    // perform the nudge
    const action = this.createAction(TranslateGroupsAction, selected);
    const bonus = extendDistance ? 10 : 1;
    action.translateBy(x * bonus, y * bonus, { updateUI: false });
    action.resolve({ reason: 'nudge' });
  }

  isCopy = ({ event, modifier }) =>
    modifier && this.isKey(event, COPY_KEY.code);
  applyCopy = ({ isPlanMode, isDesignMode }) => {
    const copy = this.createAction(CopyPasteAction);

    if (isDesignMode) {
      const ids = this.getSelectedGroupIds();
      copy.copyGroups(ids);
    } else if (isPlanMode) {
      const ids = this.getSelectedPathIds();
      const result = copy.copyCutParams(ids);

      // unable to copy the params
      if (result === MISMATCH) {
        const alertAction = this.createAction(AlertAction);
        alertAction.set({
          msg: 'Unable to copy mixed encodings',
          i18nKey: 'mixed-encodings',
          type: ALERT_TYPES.ERROR_DISMISSIBLE,
          icon: 'alert-warning',
          modalIcon: 'circle-warning',
          duration: 3000,
        });
      }
    }
  };

  isCut = ({ event, modifier }) => modifier && this.isKey(event, CUT_KEY.code);
  applyCut = async ({ isPlanMode, isDesignMode }) => {
    // copy then delete
    this.applyCopy({ isPlanMode, isDesignMode });
    await this.applyDeleteSelection();
  };

  isPaste = ({ event, shift, modifier }) =>
    modifier && !shift && this.isKey(event, PASTE_KEY.code);
  applyPaste = () => {
    const paste = this.createAction(CopyPasteAction);
    paste.paste();
  };

  isPasteInPlace = ({ event, shift, modifier }) =>
    modifier && shift && this.isKey(event, PASTE_KEY.code);
  applyPasteInPlace = () => {
    const paste = this.createAction(CopyPasteAction);
    paste.paste({ pasteInCenter: false });
  };

  // shape placement
  isPlaceCircle = ({ event, modifier }) =>
    !modifier && this.isKey(event, PLACE_CIRCLE_KEY);
  applyPlaceCircle = () => this.insertShape(Shape.CIRCLE);

  isPlaceEllipse = ({ event }) => this.isKey(event, PLACE_ELLIPSE_KEY);
  applyPlaceEllipse = () => this.insertShape(Shape.ELLIPSE);

  isPlaceRectangle = ({ event, shift, modifier }) => {
    return !shift && !modifier && this.isKey(event, PLACE_RECTANGLE_KEY);
  };
  applyPlaceRectangle = () => this.insertShape(Shape.RECTANGLE);

  isPlaceRoundedRectangle = ({ event, shift, modifier }) =>
    !modifier && shift && this.isKey(event, PLACE_ROUNDED_RECTANGLE_KEY);
  applyPlaceRoundedRectangle = () => this.insertShape(Shape.ROUNDED_RECT);

  isPlacePolygon = ({ event, shift }) =>
    shift && this.isKey(event, PLACE_POLYGON_KEY);
  applyPlacePolygon = () => this.insertShape(Shape.POLYGON);

  // add the shape to the view
  insertShape(shape) {
    if (this.hasSelection) {
      return;
    }

    // add to the center
    const center = this.getCenterViewport();
    const action = this.createAction(BuildBasicShapeAction);
    action.build(shape, center);
  }

  isPlaceText = ({ event }) => this.isKey(event, PLACE_TEXT_KEY);
  applyPlaceText = () => {
    const textEditorModeAction = this.createAction(ToggleTextEditorModeAction);
    textEditorModeAction.insert();
  };

  isMirrorHorizontal = ({ event, shift }) =>
    shift && this.isKey(event, MIRROR_HORIZONTAL_KEY);
  applyMirrorHorizontal = () => {
    const groups = this.getSelectedGroups();
    const mirror = this.createAction(MirrorAction);
    mirror.mirror(groups, { horizontal: true });
  };

  isMirrorVertical = ({ event, shift }) =>
    shift && this.isKey(event, MIRROR_VERTICAL_KEY);
  applyMirrorVertical = () => {
    const groups = this.getSelectedGroups();
    const mirror = this.createAction(MirrorAction);
    mirror.mirror(groups, { vertical: true });
  };

  isStartDraw = ({ event, shift, modifier }) =>
    !shift && !modifier && this.isKey(event, START_DRAW_KEY);
  applyStartDraw = () => {
    // not enabled
    const features = this.useSelector(selectFeatureFlags);
    if (!features['studio-polyline-feature']) {
      return;
    }

    // don't draw when something is selected
    if (this.hasSelection) {
      return;
    }

    // if already drawing, don't enter the mode
    const isInsertPointMode = this.useSelector(selectIsInsertPointMode);
    if (isInsertPointMode) {
      return;
    }

    const viewport = this.getCenterViewport();
    const uiFeature = this.createAction(UIFeatureAction);
    const isMobile = this.useSelector(selectIsMobile);
    uiFeature.toggleInsertPoint(true, {
      origin: viewport,
      type: isMobile ? 'mobile' : 'default',
    });
  };

  // special condition since ALT changes behavior
  isNewDesign = ({ modifier, alt, event }) =>
    modifier && alt && event.code === NEW_DESIGN_KEY.code;
  applyNewDesign = () => {
    const action = this.createAction(CreateWorkspaceAction);
    action.disconnectCurrentWorkspaceAndCreateNewWorkspace();
  };

  isMoveToZero = ({ modifier, event }) =>
    modifier && this.isKey(event, MOVE_TO_ZERO_KEY);
  applyMoveToZero = () => {
    const action = this.createAction(TranslateViewportAction);
    action.resetToZero();
  };

  isZoomIn = ({ event, modifier }) =>
    modifier && this.isKey(event, ZOOM_IN_KEY);
  applyZoomIn = () => this.applyZoom(-2.5);

  isZoomOut = ({ event, modifier }) =>
    modifier && this.isKey(event, ZOOM_OUT_KEY);
  applyZoomOut = () => this.applyZoom(2.5);

  // perform a keyboard zoom
  applyZoom(dir) {
    const viewport = this.getViewport();
    const x = (viewport.size.x >> 1) + viewport.position.x;
    const y = (viewport.size.y >> 1) + viewport.position.y;
    const action = this.createAction(ScaleViewportAction);
    action.scaleBy(0.1 * dir, { x, y });
  }

  isFindArt = ({ event }) => this.isKey(event, FIND_ART_KEY);
  applyFindArt = () => {
    const feature = this.createAction(UIFeatureAction);
    feature.toggleFindArt(true);
  };

  get hasSelection() {
    return !!this.getSelectedGroups()?.length;
  }

  getCenterViewport() {
    const { canvasViewbox } = this.getViewport();
    return {
      x: (canvasViewbox.minPoint.x + canvasViewbox.maxPoint.x) >> 1,
      y: (canvasViewbox.minPoint.y + canvasViewbox.maxPoint.y) >> 1,
    };
  }

  // applies a series of shortcuts
  applyShortcuts({ event, applyDown, applyUp }) {
    // ignore meta and modifiers, shift is kept because
    // it's used by Multi-select
    if (['meta', 'control', 'alt'].includes(event.key?.toLowerCase())) {
      console.log('[shortcut] ignore meta keys');
      return;
    }

    // verify this can be done - when a shortcut is applied,
    // there's a short period of time that we ignore other shortcuts
    const now = Date.now();
    if (now < this.allowNextShortcut ?? 0) {
      console.log('[shortcut] debounced');
      return;
    }

    // selectors
    const isPreviewView = this.useSelector(selectIsPreviewView);
    const isDesignMode = this.useSelector(selectIsDesignMode);
    const isPlanMode = this.useSelector(selectIsPlanMode);
    const isReviewMode = this.useSelector(selectIsReviewMode);
    const isShapeShifterMode = this.useSelector(selectIsShowingShapeShifter);
    const isImportMode = this.useSelector(selectIsShowingImportMode);
    const modifier = isMetaKeyActivated(event);
    const shift = event.shiftKey;
    const alt = event.altKey;
    const ctrl = event.ctrlKey;
    const args = {
      modifier,
      shift,
      alt,
      ctrl,
      event,
      up: applyUp,
      down: applyDown,

      // tracking modes for behavior changes
      isDesignMode,
      isPlanMode,
      isReviewMode,
      isShapeShifterMode,
      isImportMode,
    };

    // test each shortcut
    for (const shortcut of this.shortcuts) {
      const { name, down, up, modes, debug, allowInPreviewView } = shortcut;

      // some shortcuts are disabled in preview view
      if (isPreviewView && !allowInPreviewView) {
        if (debug) {
          console.log('[shortcut] reject', name, 'when previewing', event);
        }
        continue;
      }

      // check the mode
      if (
        (isDesignMode && !modes.design) ||
        (isPlanMode && !modes.plan) ||
        (isReviewMode && !modes.review) ||
        (isShapeShifterMode && !modes.shapeShifter) ||
        (isImportMode && !modes.import)
      ) {
        if (debug) {
          // eslint-disable-next-line
          console.warn('[shortcut]', name, 'wrong mode', event);
        }
        continue;
      }

      // check if we need to skip
      if (applyDown !== down && applyUp !== up) {
        if (debug) {
          // eslint-disable-next-line
          console.warn('[shortcut]', name, 'wrong up/down', event);
        }

        continue;
      }

      // get the methods to call - by default, actions will cancel
      // unless specifically set to false
      const test = shortcut.test ?? `is${upperFirst(name)}`;
      const action = shortcut.action ?? `apply${upperFirst(name)}`;
      const cancel = shortcut.cancel !== false;
      const debounce = shortcut.debounce !== false;

      // test the condition
      if (this[test]?.(args)) {
        console.log(
          `[shortcut:${applyDown ? 'down' : 'up'}] execute ${name}`,
          shortcut
        );

        // execute the shortcut
        this[action]?.(args);

        // don't do the normal action
        event.preventDefault?.();
        event.stopPropagation?.();

        // set the debounce window
        if (debounce) {
          this.allowNextShortcut = now + DEBOUNCE_SHORTCUT_TIME;
        }

        // this should cancel the event
        if (cancel) {
          return event.cancel();
        }
      } else {
        if (debug) {
          // eslint-disable-next-line
          console.warn('[shortcut]', name, 'failed shortcut match', event);
        }
      }
    }
  }

  // handle keydown
  onEveryKeyDown(event) {
    addAttributeToUser('key_pressed', event.key);
    this.applyShortcuts({ event, applyDown: true });
  }

  // handle key up
  onEveryKeyUp(event) {
    addAttributeToUser('key_pressed', null);
    this.applyShortcuts({ event, applyUp: true });
  }
}
