import InteractionMode from '@/InteractionsManager/Modes/InteractionMode';

// interactions
import ZoomViewportInteraction from '@/InteractionsManager/Interactions/ZoomViewport';
import TranslateViewportInteraction from '@/InteractionsManager/Interactions/TranslateViewport';
import SelectPathInteraction from './Interactions/SelectPath';
import EmptyInteraction from '../Default/Interactions/Empty';
import SelectionNetInteraction from '../../Interactions/SelectionNet';
import PanAndZoomViewportInteraction from '@/InteractionsManager/Interactions/PanAndZoomViewport';
import KeyboardShortcutsInteraction from '../Default/Interactions/KeyboardShortcuts';

export default class PlanMode extends InteractionMode {
  theme = 'dark';

  getInteractionHandlers() {
    return [
      KeyboardShortcutsInteraction,
      SelectionNetInteraction,
      SelectPathInteraction,
      TranslateViewportInteraction,
      PanAndZoomViewportInteraction,
      ZoomViewportInteraction,
      EmptyInteraction,
    ];
  }
}
