function isLocalHost() {
  return /localhost/.test(window.location.host);
}

function hasFlag(flag) {
  return !!localStorage.getItem(flag);
}

export function ignoreLogin() {
  return isLocalHost() && hasFlag('ignore-login');
}

export function debugTranslations() {
  return isLocalHost() && hasFlag('debug-translations');
}

export function useAllFeatures() {
  return isLocalHost() && hasFlag('all-features');
}

export function isDevelopment() {
  return isLocalHost();
}
