import bezier from 'adaptive-bezier-curve';
import { transform } from './PointOps';
import { performanceLogSync } from './Helpers';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import {
  createMatrix33,
  getMtx33FromSVGMatrix,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import arcToBezier from 'svg-arc-to-cubic-bezier';
import raphael, { RaphaelPathSegment } from 'raphael';
import { adaptiveBezierSampling } from './bezier';

type PathCmd = {
  cmd: 'M' | 'L' | 'Z' | 'C' | 'A';
  pt: number[];
};

export type RaphaelPathSegmentExtended = RaphaelPathSegment & {
  pt: RaphaelPathSegment;
};

export const pairArrayElements = (flatArr: number[]) => {
  if (flatArr.length % 2 !== 0) {
    console.log(
      'Warning - pairing array with non-even length. Dropping last element'
    );
  }
  return flatArr.reduce((acc, val, idx, arr) => {
    if (idx % 2 === 0) {
      return acc;
    }
    //If idx is odd, pair with trailing element.
    //Use trailing instead of leading to avoid access outside of array
    acc.push([arr[idx - 1], arr[idx]]);
    return acc;
  }, [] as number[][]);
};

export const getPathSegments = (pathString: string) => {
  const segments = raphael.parsePathString(pathString);
  return segments;
};

export const getPathSegments_deprecated = (pathString: string) => {
  const pathSegRe = /[a-zA-z](?:\s+-?\d+(?:\.\d+)?)*/gm;

  try {
    const pathSegs = pathString.match(pathSegRe);

    return pathSegs?.map((seg) => {
      return {
        cmd: seg[0],
        pt: seg
          .slice(1)
          .split(' ')
          .filter((v) => v !== '')
          .map((v) => parseFloat(v)),
      } as PathCmd;
    });
  } catch (err) {
    throw new Error(`Error parsing path string ${err} \n${pathString}`);
  }
};

export const tessellateCubic = (controlPts: number[], pathScale = 1) => {
  const [start, c1, c2, end] = pairArrayElements(controlPts);
  const pts = bezier(start, c1, c2, end, pathScale).map(
    ([x, y]: [number, number]) => new Point(x, y)
  );
  return pts;
};

export const bezierToPoints = (
  pathCmd: number[],
  lastPt: Point,
  scale?: number
) => {
  if (scale) {
    const start = new Point(lastPt.x, lastPt.y);
    const c1 = new Point(pathCmd[1], pathCmd[2]);
    const c2 = new Point(pathCmd[3], pathCmd[4]);
    const end = new Point(pathCmd[5], pathCmd[6]);

    const newPoints = adaptiveBezierSampling(start, c1, c2, end, scale);
    return newPoints.slice(1);
  }

  const start = [lastPt.x, lastPt.y];
  const c1 = [pathCmd[1], pathCmd[2]];
  const c2 = [pathCmd[3], pathCmd[4]];
  const end = [pathCmd[5], pathCmd[6]];
  const newPoints2 = bezier(start, c1, c2, end, 25);

  const newPathPts = newPoints2
    .slice(1)
    .map((p: [number, number]) => new Point(p[0], p[1]));
  return newPathPts;
};

export const getPointsFromSegments = (
  pathCmds: RaphaelPathSegmentExtended[],
  forceOpenPaths: boolean,
  scale?: number
): Point[] => {
  let firstPt = new Point();
  let lastPt = new Point();

  const pathPts = [];
  for (let pathCmd of pathCmds) {
    switch (pathCmd[0]) {
      case 'M':
        firstPt = new Point(pathCmd[1], pathCmd[2]);
        lastPt = firstPt;
        pathPts.push(firstPt);
        break;

      case 'L':
        const thisPt = new Point(pathCmd[1], pathCmd[2]);
        pathPts.push(thisPt);
        lastPt = thisPt;
        break;

      case 'Z':
        if (!forceOpenPaths) {
          pathPts.push(firstPt);
          lastPt = firstPt;
        }
        break;

      case 'C': {
        const newPathPts = bezierToPoints(pathCmd as number[], lastPt, scale);
        pathPts.push(...newPathPts);
        lastPt = pathPts[pathPts.length - 1];
        break;
      }

      //Tessellate arcs by approximating with bezier curve
      case 'A': {
        const [rx, ry, xAxisRotation, largeArcFlag, sweepFlag, cx, cy] =
          pathCmd.slice(1);
        const arcApproxCurves = arcToBezier({
          px: lastPt.x,
          py: lastPt.y,
          rx,
          ry,
          xAxisRotation,
          largeArcFlag,
          sweepFlag,
          cx,
          cy,
        });
        let lastArcPt = lastPt;
        const arcPts = arcApproxCurves
          .map((controlPts) => {
            const { x1, y1, x2, y2, x, y } = controlPts;
            const newPathPts = bezierToPoints(
              [0, x1, y1, x2, y2, x, y],
              lastArcPt,
              scale
            );
            lastArcPt = new Point(x, y);
            return newPathPts;
          })
          .flat();
        pathPts.push(...arcPts);
        lastPt = arcPts.slice(-1)[0];
        break;
      }

      default:
        throw new Error(`Unknown PathCmd ${pathCmd[0]}`);
    }
  }
  return pathPts;
};

export const getPointsFromSegments_deprecated = (
  pathCmds: PathCmd[],
  pathScale: number,
  forceOpenPaths: boolean
): Point[] => {
  let firstPt = new Point();
  let lastPt = new Point();

  const pathPts = [];
  for (let pathCmd of pathCmds) {
    let thisPt;
    switch (pathCmd.cmd) {
      case 'M':
        firstPt = new Point(pathCmd.pt[0], pathCmd.pt[1]);
        lastPt = firstPt;
        pathPts.push(firstPt);
        break;

      case 'L':
        thisPt = new Point(pathCmd.pt[0], pathCmd.pt[1]);
        pathPts.push(thisPt);
        lastPt = thisPt;
        break;

      case 'Z':
        if (!forceOpenPaths) {
          pathPts.push(firstPt);
          lastPt = firstPt;
        }
        break;

      case 'C': {
        const controlPts = [lastPt.x, lastPt.y, ...pathCmd.pt];
        const curvePts = tessellateCubic(controlPts, pathScale).slice(1); //Remove duplicate first point, as it's already in the pathPts array.
        pathPts.push(...curvePts);
        lastPt = curvePts.slice(-1)[0];
        break;
      }

      //Tessellate arcs by approximating with bezier curve
      case 'A': {
        const [rx, ry, xAxisRotation, largeArcFlag, sweepFlag, cx, cy] =
          pathCmd.pt;
        const arcApproxCurves = arcToBezier({
          px: lastPt.x,
          py: lastPt.y,
          rx,
          ry,
          xAxisRotation,
          largeArcFlag,
          sweepFlag,
          cx,
          cy,
        }); //returns array of {x1,y1, x2,y2, x,y}
        let lastArcPt = lastPt;
        const arcPts = arcApproxCurves
          .map((controlPts) => {
            const { x1, y1, x2, y2, x, y } = controlPts;
            const curvePts = tessellateCubic(
              [lastArcPt.x, lastArcPt.y, x1, y1, x2, y2, x, y],
              pathScale
            ).slice(1); //Remove duplicate first point, as it's already in the pathPts array from last pathCmd or a previous curve associated with this arc.
            lastArcPt = new Point(x, y);
            return curvePts;
          })
          .flat();
        pathPts.push(...arcPts);
        lastPt = arcPts.slice(-1)[0];
        break;
      }

      default:
        throw new Error(`Unknown PathCmd ${pathCmd.cmd}`);
    }
  }
  return pathPts;
};

export const getPathPointsFromUSVGPathCmds = (
  d: string,
  pathScale: number | undefined,
  forceOpenPaths: boolean
) => {
  try {
    const pathCmds = getPathSegments(d);
    if (!pathCmds || pathCmds.length === 0) {
      return [];
    }
    const points = getPointsFromSegments(
      pathCmds as RaphaelPathSegmentExtended[],
      forceOpenPaths,
      pathScale
    );
    return points;
  } catch (e) {
    return [];
  }
};

const getNodeSvgTransform = (el: SVGPathElement) => {
  const nodeSvgTransform = el?.transform?.baseVal.consolidate();
  return nodeSvgTransform === null || nodeSvgTransform === undefined
    ? createMatrix33()
    : getMtx33FromSVGMatrix(nodeSvgTransform.matrix);
};

export const getTessellatedPathPointsFromUSVGElement = (
  el: SVGPathElement,
  clipBox: AABB,
  forceOpenPaths: boolean
) => {
  try {
    const scale = (() => {
      const dx = clipBox.maxPoint.x - clipBox.minPoint.x;
      const dy = clipBox.maxPoint.y - clipBox.minPoint.y;

      return Math.sqrt(dx * dx + dy * dy);
    })();
    const path = el.getAttribute('d');
    if (path) {
      const pathPts = performanceLogSync(
        () => getPathPointsFromUSVGPathCmds(path, undefined, forceOpenPaths),
        'PERFORMANCE: getPathPointsFromUSVGPathCmds -> '
      );

      if (scale === 0 || !pathPts || pathPts.length === 0) {
        return { pts: [] };
      }

      const nodeTransform = getNodeSvgTransform(el);
      return {
        pts: pathPts.map((pt) => transform(pt, nodeTransform)),
        nodeTransform,
      };
    }
    return { pts: [] };
  } catch (err) {
    return { pts: [] };
  }
};
