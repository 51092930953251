import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

// selectors
import {
  selectGroupsMap,
  selectSvgGroupString,
} from '@/Redux/Slices/CanvasSlice';
import {
  selectSelectedGroupIds,
  selectSelectedPathIds,
} from '@/Redux/Slices/SelectionSlice';
import { selectIsDefaultMode, selectUIMode } from '@/Redux/Slices/UISlice';

// components
import Layer from './Layer';
import EditablePoint from './Polyline/EditablePoint';
import { EditableShape } from './Polyline/EditableShape';
import SvgGroupCls from '../State/Helpers/SvgGroup';
import {
  Shape,
  SvgGroup as ISvgGroup,
  ToolParams,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { isEqual } from 'lodash';

type Props = {
  className?: string;
  group: SvgGroupCls;
  selected: boolean;
};

export default React.memo(
  function SvgGroup(props: Props) {
    const { group } = props;
    const groupsMap = useSelector(selectGroupsMap);
    const selectedPathIds = useSelector(selectSelectedPathIds);
    const selectedGroupIds = useSelector(selectSelectedGroupIds);
    const mode = useSelector(selectUIMode);
    const selected = selectedGroupIds.includes(group.id);
    const svg = useSelector(selectSvgGroupString(group.data), shallowEqual);
    const isDefaultMode = useSelector(selectIsDefaultMode);
    const isPoint = group.tool.type === Shape.POINT;
    const isShape = group.tool.type === Shape.SHAPE;

    const pathType = group.data.type.toLowerCase();

    // TODO: this is not ideal, but find the selected paths
    // inside this layer and mark them as selected - manipulate
    // the DOM to avoid rendering a new string when selection changes
    // consider moving the ID generation to somewhere else
    function updatePathSelections() {
      // Shapes created by draw tool have a different structure.
      // we need to find the parent ID of any selected draw tool shapes
      // and add that group to the selection list.
      const selectedDrawToolShapePaths = selectedPathIds
        .map((item) => {
          const shapegroup = groupsMap.get(item.groupId);
          if (!shapegroup || shapegroup.tool.type !== Shape.POINT) {
            return undefined;
          }

          const parent = (shapegroup as ISvgGroup<Shape.POINT>).tool.params
            .belongsTo;
          if (!parent) {
            return undefined;
          }
          const parentGroup = groupsMap.get(parent);
          if (!parentGroup?.basePathSet?.[0]?.id) {
            return undefined;
          }
          return {
            groupId: parentGroup.id,
            pathId: parentGroup.basePathSet[0].id,
          };
        })
        .filter((item) => item !== undefined);

      const selection = [...selectedPathIds, ...selectedDrawToolShapePaths];
      const selectionIds = selection.map(
        (item) => `pathGroup-sg-${item.groupId}-pg-${item.pathId}`
      );

      document.querySelectorAll(`#sg-${group.id} .pathGroup`).forEach((el) => {
        const isSelected =
          mode === 'default'
            ? selection.some(({ groupId }) => el.id.includes(groupId))
            : selectionIds.includes(el.id);
        el.classList.toggle('selected', isSelected);
      });
    }

    useEffect(updatePathSelections, [groupsMap, selectedPathIds]);

    if (isPoint && isDefaultMode) {
      return <EditablePoint group={group} />;
    }

    if (
      isShape &&
      isDefaultMode &&
      (group.data.tool.params as ToolParams<Shape.SHAPE>).points.length
    ) {
      return <EditableShape group={group} selected={selected} />;
    }

    return (
      <Layer
        id={group.id}
        className={`svg-group ${selected ? 'selected' : ''} ${pathType} ${
          props.className || ''
        }`}
        x={group.cx}
        y={group.cy}
        rotation={group.rotation}
        stretchMatrix={group.stretchMatrix}
        svgGroup={svg}
      />
    );
  },
  (prev, next) => isEqual(prev, next) // We use isEqual since UIState.groups is reconstructed quite frequently
);
