import { FEATURE_FLAGS, FeatureFlagsMap } from '@/Helpers/FeatureFlagClient';
import { enableDebugging } from '@/Sync/SyncLog';
import {
  createAction,
  createSlice,
  isAction,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getDeveloperSettings } from '@/Utility/developer-settings';
import { setI18nLanguage } from '@/i18n';
import { RootState, AppThunkDispatch } from '../store';
import { getShaperSubscriptions, setLocale } from './ShaperHubSlice';

export const DEFAULT_FEATURE_FLAGS: FeatureFlagsMap = FEATURE_FLAGS.reduce(
  (defaultFlagMap, flag) => {
    // Set all the flags to false to start
    defaultFlagMap[flag] = false;
    return defaultFlagMap;
  },
  {} as FeatureFlagsMap
);

export const updateFeatureFlags = createAction<FeatureFlagsMap>(
  'featureFlags/featureFlagsUpdated'
);

// Initial state for feature flags
export const initialFeatureFlagState = {
  featureFlags: DEFAULT_FEATURE_FLAGS,
};

// Create a feature flags slice
const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: initialFeatureFlagState,
  reducers: {
    setState: (state, action: PayloadAction<FeatureFlagsMap>) => {
      state.featureFlags = action.payload;
    },
  },
});

export const { setState } = featureFlagsSlice.actions;

export const featureFlagsMiddleware: Middleware<
  {},
  RootState,
  AppThunkDispatch
> =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (isAction(action) && updateFeatureFlags.match(action)) {
      const { shaperHub } = getState();
      dispatch(setState(action.payload));

      const featureFlags = action.payload;
      if (featureFlags['studio-debug-logs']) {
        enableDebugging();
      }

      if (featureFlags['studio-developer-settings']) {
        const devLanguage =
          getDeveloperSettings('locale') || shaperHub.locale.language;
        dispatch(setLocale({ language: devLanguage }));
        setI18nLanguage(devLanguage);
      }

      dispatch(getShaperSubscriptions());
    }

    return next(action);
  };

export const selectFeatureFlags = (state: RootState) =>
  state.featureFlags.featureFlags;

export const selectFeatureFlag = (
  state: RootState,
  flagName: keyof FeatureFlagsMap
) =>
  state.featureFlags.featureFlags
    ? state.featureFlags.featureFlags[flagName]
    : false;
export default featureFlagsSlice.reducer;
