import {
  generateSvgPathData,
  setPathsWindingOrder,
  toPathArray,
  transform,
} from '../BasePathOps';
import { getReviewPathOffset, getSvgPathCssClass } from '../CutParamsOps';
import { performanceLogAsync } from '../Helpers';
import {
  BasePath,
  generatePathId,
} from '@shapertools/sherpa-svg-generator/BasePath';
import {
  CutParams,
  CutType,
} from '@shapertools/sherpa-svg-generator/CutParams';
import { multiplyMatrix33 } from '@shapertools/sherpa-svg-generator/Matrix33';
import { Path } from '@shapertools/sherpa-svg-generator/Path';
import { createSvgPathFromStrsWithCSSAndAttributes } from '@shapertools/sherpa-svg-generator/SvgGenerator';
import {
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Cache } from './interfaces/GenericCache';
import clipper from '../offset/Clipper';
import { ToolPathsCache } from './SvgCache';

const generateToolpathOnlineOpenPathAsync = async (
  basePath: BasePath,
  cutParams: CutParams
) => {
  const toolDiaOffset = getReviewPathOffset(cutParams);

  const offsets = await clipper.offsetAsync(basePath, toolDiaOffset, true);

  if (offsets.length > 1) {
    const copiedOffsets = JSON.parse(JSON.stringify(offsets));
    const outerPath = copiedOffsets[0];

    return [
      new BasePath({
        outerPath: new Path({
          ...outerPath,
        }),
        holePaths: copiedOffsets.slice(1).map(
          (o: BasePath) =>
            new Path({
              ...o,
            })
        ),
        cutParams: cutParams,
      }),
    ];
  }

  return [
    new BasePath({
      outerPath: new Path({
        ...basePath,
      }),
      holePaths: offsets.map(
        (o: BasePath) =>
          new Path({
            ...o,
          })
      ),
      cutParams: cutParams,
    }),
  ];
};

const generateToolpathOutlineSPAsync = async (
  basePath: BasePath,
  cutParams: CutParams
) => {
  const toolDiaOffset = getReviewPathOffset(cutParams);

  //For simplePolygons, offset outerPath and holePaths separately
  //Need to round corners for this offset operation only, as we are determining the cut area around the toolpath
  const simplePolyPaths = await Promise.all(
    toPathArray(basePath).map(async (op: BasePath) => {
      const [outerPath] = await clipper.offsetAsync(
        op,
        1 * toolDiaOffset,
        true
      );

      const holePaths = await clipper.offsetAsync(
        op,
        -1 * toolDiaOffset,
        true,
        true
      );

      const holePathsArray = Array.isArray(holePaths) ? holePaths : [holePaths];

      return {
        outerPath,
        holePaths: holePathsArray.map((hp) => hp.outerPath || hp),
      };
    })
  );

  return simplePolyPaths.map((spPaths) =>
    setPathsWindingOrder(
      new BasePath({
        ...spPaths,
        cutParams: cutParams || basePath.cutParams,
      })
    )
  );
};

const generatePocketSPAsync = async (
  toolPath: BasePath,
  cutParams: CutParams
) => {
  const toolDiaOffset = getReviewPathOffset(cutParams);
  const offsets = await clipper.offsetAsync(toolPath, toolDiaOffset, true);

  return offsets.map((op) => {
    if (op.outerPath) {
      op.cutParams = cutParams;
      return op;
    }
    return new BasePath({
      outerPath: new Path({
        points: op.points,
        closed: op.closed,
      }),
      cutParams,
      holePaths: [],
    });
  });
};

const getReviewPathDataAsync = async (
  toolPaths: BasePath[],
  cutParams: CutParams,
  tool: Tool
): Promise<BasePath[]> => {
  if (cutParams.cutType === CutType.GUIDE) {
    return [];
  }
  if (tool.type === Shape.TEXT && tool.params.forceOpenPaths) {
    const outlines = await Promise.all(
      toolPaths.map((tp: BasePath) =>
        generateToolpathOnlineOpenPathAsync(tp, cutParams)
      )
    );
    return outlines.flat();
  }

  const previewPaths = await Promise.all(
    toolPaths.map((tp: BasePath) => {
      switch (cutParams.cutType) {
        case CutType.ONLINE:
        case CutType.INSIDE:
        case CutType.OUTSIDE:
          // Offset version is well tested, but 30% slower than Minkowski sum
          return generateToolpathOutlineSPAsync(tp, cutParams);

        case CutType.POCKET:
          return generatePocketSPAsync(tp, cutParams);

        default:
          throw new Error(`cutType unknown, ${cutParams.cutType}`);
      }
    })
  );

  // render all remaining
  return previewPaths.flat();
};

export const getReviewPathSvg = (
  svgGroupId: string,
  basePathId: string,
  cutParams: CutParams,
  paths: BasePath[]
) => {
  const pathData = paths.map((p) => generateSvgPathData(p)).flat();

  if (pathData.length === 0) {
    return '';
  }

  const reviewPathId = generatePathId(svgGroupId, basePathId, 'reviewToolPath');

  const cssKey =
    cutParams.cutType === CutType.POCKET
      ? 'toolWidthReviewPocket'
      : 'toolWidthReviewOutline';

  const reviewPathCSS = getSvgPathCssClass(
    cssKey,
    cutParams.toolDia,
    cutParams.cutDepth
  );

  return createSvgPathFromStrsWithCSSAndAttributes(
    pathData,
    reviewPathId,
    reviewPathCSS,
    []
  );
};

export const getReviewPathCache = async (
  basePath: BasePath,
  svgGroup: SvgGroup,
  extraArgs: {
    toolPathCache: ToolPathsCache;
  }
): Promise<Cache<string>> => {
  const { toolPathCache } = extraArgs;
  const { pathData: toolPathData } = toolPathCache.getCache(
    basePath,
    svgGroup,
    {}
  );

  const { cutParams } = basePath;
  const { translateMtx, rotateMtx, tool } = svgGroup;

  const TRMtx = multiplyMatrix33(translateMtx, rotateMtx);

  const TRPathData = transform(toolPathData, TRMtx) as BasePath[];

  const reviewPathData2 = await performanceLogAsync<BasePath[]>(
    async () => getReviewPathDataAsync(TRPathData, cutParams, tool),
    'PERFORMANCE: getReviewPathDataAsync VERSION 2 -> '
  );

  return reviewPathData2.length === 0
    ? { pathData: [], pathSvg: '' }
    : { pathData: reviewPathData2, pathSvg: '' };
};
