import { batch } from 'react-redux';

import BaseAction from './BaseAction';
import UpdateWorkspaceOptionsAction from '@/Actions/UpdateWorkspaceOptions';

import { setModal } from '@/Redux/Slices/SherpaContainerSlice';
import { hideAllFeatures } from '@/Redux/Slices/UISlice';
import { MODAL_TYPES, MODAL_TRIGGER_TYPES } from '../defaults';
import { ModalTriggerTypes } from '@/@types/shaper-types';

// modal arguments
import { ExportTargets } from '@/Components/Export/ExportModal';

export default class ModalActions extends BaseAction {
  openEducationalModal(trigger?: ModalTriggerTypes) {
    const updateWorkspaceOptions = this.createAction(
      UpdateWorkspaceOptionsAction
    );
    batch(() => {
      updateWorkspaceOptions.update({ showApplicationMenu: false });
      this.dispatch(hideAllFeatures());
      this.dispatch(setModal(MODAL_TYPES.EDU_MODAL, trigger));
    });
  }

  openSignInModal(trigger?: ModalTriggerTypes) {
    const updateWorkspaceOptions = this.createAction(
      UpdateWorkspaceOptionsAction
    );
    batch(() => {
      updateWorkspaceOptions.update({ showApplicationMenu: false });
      this.dispatch(hideAllFeatures());
      this.dispatch(setModal(MODAL_TYPES.SIGNIN_MODAL, trigger));
    });
  }

  openExportModal(target: ExportTargets, trigger?: ModalTriggerTypes) {
    this.dispatch(setModal(MODAL_TYPES.EXPORT_MODAL, trigger, { target }));
  }

  closeModal(clearURL?: boolean, trigger?: ModalTriggerTypes) {
    if (clearURL) {
      //clear success query
      window.history.pushState(null, document.title, '/');
    }

    this.dispatch(
      setModal(MODAL_TYPES.NO_MODAL, trigger || MODAL_TRIGGER_TYPES.NO_TRIGGER)
    );
  }
}
