import React from 'react';
import { useAction } from '@/Actions/useAction';
import { useSelector } from 'react-redux';
import {
  ACTIVE_POINT_SIZE_CONSTANT,
  ACTIVE_POINT_SIZE_CONSTANT_2,
  POINT_SIZE_CONSTANT,
  POINT_SIZE_CONSTANT_2,
  SHAPE_EDITOR_POINT_SIZE,
  SHAPE_EDITOR_POINT_SIZE__SELECTED,
} from '@/Constants/UI';
import SvgGroupCls from '../../State/Helpers/SvgGroup';

// actions
import LineToolAction from '@/Actions/LineTool';

// selectors
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import {
  selectMostRecentlyMovedPoint,
  selectSelectedGroupIds,
} from '@/Redux/Slices/SelectionSlice';
import { selectSelectedLine } from '@/Redux/Slices/LineToolSlice';
import { RAD } from '@/Helpers/utils';
import {
  selectIsInsertPointMode,
  selectIsShowingDrawModeShapePreview,
  selectIsShowingOpenSnapPreview,
  selectLineToolActiveShapeId,
} from '@/Redux/Slices/UISlice';
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
// components
// import Arc from './Arc';

type Props = {
  group: SvgGroupCls;
};

export default function EditablePoint({ group }: Props) {
  const lineTool = useAction(LineToolAction);
  const nspf = useSelector(selectNonScalingPixelFactor);
  const mostRecentlyMovedPoint = useSelector(selectMostRecentlyMovedPoint);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);
  const selectedLine = useSelector(selectSelectedLine);
  const isInsertPointMode = useSelector(selectIsInsertPointMode);
  const isShowingDrawModeShapePreview = useSelector(
    selectIsShowingDrawModeShapePreview
  );
  const isShowingOpenSnapPreview = useSelector(selectIsShowingOpenSnapPreview);
  const shapeId = useSelector(selectLineToolActiveShapeId);
  const getSvgGroupById = useSelector(selectGetGroupById);

  const selected = selectedGroupIds.includes(group.id);
  const position = { cx: group.cx, cy: group.cy };

  let isOpenSnapPreview = false;
  if (shapeId) {
    const shape = getSvgGroupById(shapeId);
    isOpenSnapPreview =
      shape?.tool?.params?.points?.includes(group.id) &&
      isShowingOpenSnapPreview;
  }

  // Prior UI components to display angle and distance
  // between adjacent points - uncomment to restore
  // TODO: update this ts-ignore to properly access
  // lineTool.points when it's moved to typescript
  // @ts-ignore
  const point = lineTool.points[group.id];
  const isActivePoint =
    selectedLine?.includes(group.id) &&
    selected &&
    mostRecentlyMovedPoint === group.id;

  if (point) {
    const size =
      (selected ? SHAPE_EDITOR_POINT_SIZE__SELECTED : SHAPE_EDITOR_POINT_SIZE) *
      (isActivePoint ? ACTIVE_POINT_SIZE_CONSTANT_2 : 1);
    const pointSize =
      size *
      nspf *
      POINT_SIZE_CONSTANT *
      (isActivePoint ? POINT_SIZE_CONSTANT_2 : 1);
    const activePointSize =
      size * nspf * POINT_SIZE_CONSTANT * ACTIVE_POINT_SIZE_CONSTANT;

    const rectangleSize = 12 * nspf;
    const radius = 2 * nspf;
    const rectangleProperties = {
      x: group.cx - rectangleSize / 2,
      y: group.cy - rectangleSize / 2,
      width: rectangleSize,
      height: rectangleSize,
      rx: radius,
      ry: radius,
    };

    const angle = (() => {
      if (selectedLine && selectedLine.length === 2) {
        const [pointA, pointB] = selectedLine.map((line) =>
          lineTool.getById(line)
        );

        const a = Math.atan2(pointB.cy - pointA.cy, pointB.cx - pointA.cx);
        return (a * RAD) % 180;
      }
      return 0;
    })();

    return (
      <>
        <>
          <circle
            id={`point-${group.id}`}
            data-cy='point'
            className={`path-connection ${
              selected && !selectedLine && 'selected'
            } ${isInsertPointMode && 'disable-hover'} ${
              isOpenSnapPreview && 'open-snap-point-preview'
            }`}
            r={pointSize}
            {...position}
          />
        </>
        {isActivePoint && (
          <circle
            className={`active-line-point ${
              isShowingDrawModeShapePreview && 'draw-mode-shape-preview'
            }`}
            r={activePointSize}
            {...position}
          />
        )}
        {selectedLine && (
          <>
            {selected && !isActivePoint && (
              // have to apply transforms to group so the css transitions
              // dont create a fun css transition for the rotation
              <g
                className='path-connection-transforms'
                transform={`rotate(${angle})`}
              >
                <rect
                  id={`point-${group.id}`}
                  className={`path-connection-fixed`}
                  {...rectangleProperties}
                />
              </g>
            )}
          </>
        )}
      </>
    );
  }
}
