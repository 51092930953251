export default function isMetaKeyActivated(event) {
  // TODO: how can we handle this?
  // since playwright passes an unusual useragent string, we need to just
  // make sure to pass back an expected value
  if (window.__playwright__) {
    return !!(event.ctrlKey || event.metaKey);
  }

  return /(os x)/i.test(window.navigator.userAgent)
    ? !!event.metaKey
    : !!event.ctrlKey;
}
