import { uniq } from 'lodash';

// consts
import { MERGE_POINT_DISTANCE } from '@/Constants/UI';

// slices
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';

import {
  clearSelection,
  selectSelectedGroupIds,
} from '@/Redux/Slices/SelectionSlice';

import {
  selectSvgGroupSet,
  selectGetGroupById,
} from '@/Redux/Slices/CanvasSlice';

import {
  createPolylineShape,
  addPointToPolylineShape,
  mergePolylineShapes,
  closePolylineShape,
  removePolylinePoints,
  endPolylineShape,
} from '@/LineTool/LineToolActions';

import {
  selectInsertPointModeOptions,
  setInsertPointOptions,
  setLineToolActiveShapeId,
} from '@/Redux/Slices/UISlice';

import {
  selectIsTwoAdjacentPoints,
  selectMostRecentlyAddedShapeID,
} from '@/Redux/Slices/LineToolSlice';

// actions
import { RAD } from '@/Helpers/utils';
import { Shape } from '@shapertools/sherpa-svg-generator/SvgGroup';

export default class LineToolAction {
  constructor(dispatch, useSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;

    // add the groups
    const svgGroups = useSelector(selectSvgGroupSet);
    this.groups = svgGroups;

    // convert each object to a group
    for (const group of svgGroups) {
      if (group.tool?.type === Shape.POINT) {
        this.points[group.id] = new LineToolPoint(this, group);
      } else if (group.tool?.type === Shape.SHAPE) {
        this.shapes[group.id] = new LineToolShape(this, group);
      }
    }
  }

  points = {};
  shapes = {};

  // determine the angle between two different points
  static angleBetweenPoints(origin, pointA, pointB, capAt180) {
    const angleA = Math.atan2(origin.cy - pointA.y, origin.cx - pointA.x);
    const angleB = Math.atan2(origin.cy - pointB.y, origin.cx - pointB.x);
    let nearAngle = Math.min(angleA, angleB);
    let farAngle = Math.max(angleA, angleB);
    let angle = farAngle - nearAngle;
    let displayAngle = (angle * RAD) % 180;
    let midAngle = (nearAngle + farAngle) * 0.5;
    let capped = false;

    if (capAt180 && angle > Math.PI) {
      angle = Math.PI - (angle % Math.PI);
      nearAngle = farAngle;
      displayAngle = 180 - displayAngle;
      capped = true;
    }

    return { angle, midAngle, nearAngle, farAngle, displayAngle, capped };
  }

  static resolveShapesInSelection(selectedIds, svgGroups, retainPoints) {
    const updated = [...selectedIds];

    // remove unusable points
    for (let i = updated.length; i-- > 0; ) {
      const id = updated[i];
      const group = svgGroups.find((obj) => obj.id === id);

      if (group.tool.type === Shape.POINT) {
        if (retainPoints) {
          updated.push(group.tool.params.belongsTo);
        } else {
          updated[i] = group.tool.params.belongsTo;
        }
      }
    }

    return uniq(updated);
  }

  isTwoAdjacentPoints(pointIds) {
    const isTwoAdjacentPoints = this.useSelector(selectIsTwoAdjacentPoints);
    return isTwoAdjacentPoints(pointIds);
  }

  setActiveShape(shapeId) {
    this.dispatch(setLineToolActiveShapeId(shapeId));
  }

  clearActiveShape() {
    this.dispatch(setLineToolActiveShapeId(null));
  }

  // isActiveShape() {
  //   const activeShapeId = this.useSelector(selectLineToolActiveShapeId);
  // }

  allPointsSelected(shape) {
    const selectedGroupIds = this.useSelector(selectSelectedGroupIds);
    const points = shape.tool?.params?.points;

    // there's no points for some reason
    if (!points.length) {
      return false;
    }

    // make sure they're all accounted for
    for (const id of points) {
      if (!selectedGroupIds.includes(id)) {
        return false;
      }
    }

    return true;
  }

  anyPointSelected(shape) {
    const selectedGroupIds = this.useSelector(selectSelectedGroupIds);
    const points = shape.tool?.params?.points;

    // there's no points for some reason
    if (!points.length) {
      return false;
    }

    // make sure they're all accounted for
    for (const id of points) {
      if (selectedGroupIds.includes(id)) {
        return true;
      }
    }

    return false;
  }

  getById(id) {
    return this.points[id] || this.shapes[id];
  }

  getGroupById(id) {
    const getGroupById = this.useSelector(selectGetGroupById);
    return getGroupById(id);
  }

  // finds the nearest point to a coordinate
  getPointDistanceFrom({ x, y }) {
    return Object.values(this.points)
      .map((point) => ({ point, distance: point.distanceFrom({ x, y }) }))
      .sort((a, b) => a.distance - b.distance);
  }

  mergePoints(fromId, toId) {
    const { dispatch } = this;
    const fromPoint = this.points[fromId];
    const toPoint = this.points[toId];

    // same group, just close the shape
    if (fromPoint.parent.id === toPoint.parent.id) {
      // is closing the shape
      if (fromPoint.isCap && toPoint.isCap) {
        dispatch(clearSelection());
        dispatch(
          closePolylineShape({
            shapeID: fromPoint.parent.id,
            removePointID: fromPoint.id,
          })
        );
        return;
      }
      // if adjacent, remove it
      else if (fromPoint.isAdjacent(toPoint)) {
        dispatch(clearSelection());
        dispatch(removePolylinePoints({ pointId: fromId }));
        return;
      }
    }

    // this appears to be two different shapes, both points must be
    // a cap in order to be merged
    if (!(fromPoint.isCap && toPoint.isCap)) {
      return;
    }

    // it's also not possible to merge if either shape is closed
    if (fromPoint.parent.closed || toPoint.parent.closed) {
      return;
    }

    // since they're both caps, try and determine the best order
    let points;
    const toPoints = toPoint.parent.pointIds;
    const fromPoints = fromPoint.parent.pointIds;
    if (toPoint.isHead) {
      if (fromPoint.isHead) {
        fromPoints.reverse();
      }

      points = [...fromPoints, ...toPoints].filter((id) => id !== fromId);
    } else if (toPoint.isTail) {
      if (fromPoint.isTail) {
        fromPoints.reverse();
      }

      points = [...toPoints, ...fromPoints].filter((id) => id !== fromId);
    }

    // if for some reason there's no points, there's nothing to do
    if (!points?.length) {
      return;
    }

    // with the new point order, do the update
    dispatch(clearSelection());
    dispatch(
      mergePolylineShapes({
        mergeTo: toPoint.parent,
        dispose: fromPoint.parent,
        points,
        mergedPointId: fromId,
      })
    );
  }

  // adds a point (and possibly merge)
  async addPoint(shapeId, { x, y }) {
    const { dispatch, useSelector } = this;
    const nspf = useSelector(selectNonScalingPixelFactor);
    const insertOptions = useSelector(selectInsertPointModeOptions);
    const { atFront } = insertOptions;
    const exists = this.getGroupById(shapeId);
    const shape = this.shapes[shapeId];

    // if there's no parent yet, then we need to create it now
    if (!exists) {
      await dispatch(createPolylineShape({ position: { x, y } }));
      const shapeID = useSelector(selectMostRecentlyAddedShapeID);

      // apply the new options
      await dispatch(
        setInsertPointOptions({ ...insertOptions, groupId: shapeID })
      );

      return { closed: false, groupId: shapeID };
    }

    // where is this connecting line coming from
    let lineFrom;
    if (shape) {
      const { points } = shape;
      lineFrom = atFront ? points[0] : points[points.length - 1];
    }

    // since this belongs to a shape, we want to check and see if this
    // point is close enough to causing a merge with another point or path
    const others = this.getPointDistanceFrom({ x, y });
    const threshold = MERGE_POINT_DISTANCE * nspf; // should we do this outside of the helper?

    // check all points to see if any are close enough to merge with
    let mergeTo;
    for (const { distance, point } of others) {
      // if this is beyond the distance, there's nothing left to check
      if (distance > threshold) {
        break;
      }

      // since this is close enough, make sure it's mergable
      if (point.isHead || point.isTail) {
        mergeTo = point;
        break;
      }
    }

    // since there's something to merge to, adjust as needed
    if (mergeTo && !mergeTo.parent.closed && mergeTo.isCap) {
      // when clicking on a self-merging point, just exit the mode
      if (mergeTo.id === lineFrom.id) {
        return { closed: false, groupId: shapeId, exitInsertionMode: true };
      }

      // if this belongs to the current shape, then we just need to close it
      if (shape.id === mergeTo.parent.id) {
        await dispatch(closePolylineShape({ shapeID: shape.id }));
        return { closed: true, groupId: shape.id, exitInsertionMode: true };
      }

      // if it belongs to another shape, delete the other shape and merge the points
      // into the current shape - we also need to leave this mode
      let points = [...shape.pointIds];
      const mergePoints = mergeTo.parent.pointIds;
      if (mergeTo.isHead) {
        points = [...points, ...mergePoints];
      } else if (mergeTo.isTail) {
        points.reverse();
        points = [...mergePoints, ...points];
      }

      await dispatch(
        mergePolylineShapes({
          mergeTo: shape,
          dispose: mergeTo.parent,
          points,
        })
      );

      return { closed: false, groupId: shape.id, exitInsertionMode: true };
    }

    await dispatch(
      addPointToPolylineShape({
        position: { x, y },
        parentId: shape.id,
        atFront,
      })
    );

    return { closed: false, groupId: shape.id };
  }

  // spits all paths as required
  async removePoints(pointIds, extraGroupIds) {
    const { dispatch } = this;
    const toRemove = [...pointIds];
    dispatch(clearSelection());
    dispatch(
      removePolylinePoints({ points: toRemove, extraGroupIds: extraGroupIds })
    );
  }

  // if a user exits the draw tool after placing only one point
  // we should remove that point and its parent shape
  async removeOrphanedPoints() {
    const { dispatch } = this;
    const svgGroups = this.useSelector(selectSvgGroupSet);

    // Find shapes that have only one point
    const shapesWithSinglePoint = svgGroups.filter(
      (group) =>
        group.tool.type === Shape.SHAPE &&
        group.tool.params.points?.length === 1
    );

    // Get all point IDs that need to be removed
    const pointsToRemove = shapesWithSinglePoint.flatMap(
      (shape) => shape.tool.params.points
    );

    // Remove both the points and their parent shapes
    if (pointsToRemove.length > 0) {
      dispatch(
        removePolylinePoints({
          points: pointsToRemove,
          addUndoPatches: false,
        })
      );
    }
  }

  async endPolylineShape(shapeID, closeShape = false) {
    const { dispatch } = this;
    dispatch(endPolylineShape({ shapeID, closeShape }));
  }
}

class LineToolShape {
  constructor(helper, data) {
    this.helper = helper;
    this.data = data;
  }

  get bounds() {
    const xs = [];
    const ys = [];
    for (const point of this.points) {
      xs.push(point.cx);
      ys.push(point.cy);
    }

    const left = Math.min.apply(Math, xs);
    const right = Math.max.apply(Math, xs);
    const top = Math.min.apply(Math, ys);
    const bottom = Math.max.apply(Math, ys);
    const width = right - left;
    const height = bottom - top;
    const x = (left + right) * 0.5;
    const y = (top + bottom) * 0.5;
    return { x, y, width, height, top, left, right, bottom };
  }

  get id() {
    return this.data.id;
  }

  get points() {
    const { data, helper } = this;
    return (
      data.tool?.params?.points?.map((id) => helper.points[id]) || []
    ).filter((item) => !!item);
  }

  get pointIds() {
    return this.points.map((point) => point.id);
  }

  get closed() {
    return !!this.data?.tool?.params?.closed;
  }

  get head() {
    return this.points[0];
  }

  get tail() {
    return this.points[this.points.length - 1];
  }
}

class LineToolPoint {
  constructor(helper, data) {
    this.helper = helper;
    this.data = data;
  }

  distanceFrom({ x, y }) {
    return Math.hypot(this.data.position.x - x, this.data.position.y - y);
  }

  isAdjacent(other) {
    const { pointIds } = this.parent;
    const index = pointIds.indexOf(this.id);
    const adjacent = pointIds.indexOf(other.id);
    return [-1, 1, pointIds.length - 1].includes(index - adjacent);
  }

  get id() {
    return this.data.id;
  }

  get x() {
    return this.data.position.x;
  }

  get y() {
    return this.data.position.y;
  }

  get cx() {
    return this.data.position.x;
  }

  get cy() {
    return this.data.position.y;
  }

  get belongsTo() {
    return this.data.tool?.params?.belongsTo;
  }

  get parent() {
    return this.helper.shapes[this.belongsTo];
  }

  get isHead() {
    return this.parent.head?.id === this.id;
  }

  get isTail() {
    return this.parent.tail?.id === this.id;
  }

  get isCap() {
    return this.isHead || this.isTail;
  }

  get isBody() {
    return !this.isCap;
  }

  get distanceFromPointA() {
    const { pointA } = this;
    return Math.hypot(this.x - pointA?.x, this.y - pointA?.y) || 0;
  }

  get distanceFromPointB() {
    const { pointB } = this;
    return Math.hypot(this.x - pointB?.x, this.y - pointB?.y) || 0;
  }

  get angleToPointA() {
    const { pointA } = this;
    return Math.atan2(pointA?.y - this.y, pointA?.x - this.x) || 0;
  }

  get angleToPointB() {
    const { pointB } = this;
    return Math.atan2(pointB?.y - this.y, pointB?.x - this.x) || 0;
  }

  get pointA() {
    const { helper, parent = {} } = this;

    const { pointIds = [], closed = false } = parent;

    // find the a-side point
    let index = pointIds.indexOf(this.id) - 1;
    if (closed) {
      index = (index + pointIds.length) % pointIds.length;
    }

    return helper.points[pointIds[index]];
  }

  get pointB() {
    const { helper, parent = {} } = this;

    const { pointIds = [], closed = false } = parent;

    // find the a-side point
    let index = pointIds.indexOf(this.id) + 1;
    if (closed) {
      index = (index + pointIds.length) % pointIds.length;
    }

    return helper.points[pointIds[index]];
  }
}
