import InteractionMode from '@/InteractionsManager/Modes/InteractionMode';

// interactions
import ZoomViewportInteraction from '@/InteractionsManager/Interactions/ZoomViewport';
import TranslateViewportInteraction from '@/InteractionsManager/Interactions/TranslateViewport';
import EmptyInteraction from '../Default/Interactions/Empty';
import PanAndZoomViewportInteraction from '@/InteractionsManager/Interactions/PanAndZoomViewport';
import KeyboardShortcutsInteraction from '../Default/Interactions/KeyboardShortcuts';

export default class ReviewMode extends InteractionMode {
  theme = 'review';

  getInteractionHandlers() {
    return [
      KeyboardShortcutsInteraction,
      TranslateViewportInteraction,
      ZoomViewportInteraction,
      PanAndZoomViewportInteraction,
      EmptyInteraction,
    ];
  }
}
