import React from 'react';
import { useSelector } from 'react-redux';
import { DISTANCE_BETWEEN_LABEL_OFFSET } from '@/Constants/UI';

// selectors
import { selectNonScalingPixelFactor } from '@/Redux/Slices/ViewportSlice';
import {
  selectDisplayUnits,
  selectToFormattedDisplayUnitValue,
} from '@/Redux/Slices/SherpaContainerSlice';

// components
import Label from '../Label';

export default function DistanceBetweenPoints({ origin, target, ui }) {
  const displayUnits = useSelector(selectDisplayUnits);
  const toFormattedDisplayUnitValue = useSelector(
    selectToFormattedDisplayUnitValue
  );

  const nspf = useSelector(selectNonScalingPixelFactor);
  let ax = ui.overrides?.[origin.id]?.translate?.x ?? origin.cx;
  let ay = ui.overrides?.[origin.id]?.translate?.y ?? origin.cy;
  let bx = ui.overrides?.[target.id]?.translate?.x ?? target.cx;
  let by = ui.overrides?.[target.id]?.translate?.y ?? target.cy;

  const distance = Math.hypot(ax - bx, ay - by).toFixed(3);
  let x = (ax + bx) * 0.5;
  let y = (ay + by) * 0.5;
  const displayDistance = toFormattedDisplayUnitValue(distance);

  let angle = Math.atan2(ay - by, ax - bx);

  if (angle > Math.PI * 0.5) {
    angle -= Math.PI;
  }

  if (angle < Math.PI * -0.5) {
    angle += Math.PI;
  }

  // offset
  const alt = angle + Math.PI * 0.5;
  x -= Math.cos(alt) * DISTANCE_BETWEEN_LABEL_OFFSET * nspf;
  y -= Math.sin(alt) * DISTANCE_BETWEEN_LABEL_OFFSET * nspf;

  return (
    <>
      <g className='distance-points'>
        <Label
          id={`distance_${origin.id}_${target.id}`}
          radius={4}
          padding={5}
          x={x}
          y={y}
          className={'distance-points--label'}
          rotation={angle}
          text={`${displayDistance} ${displayUnits}`}
        />
      </g>
    </>
  );
}
