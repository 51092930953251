import { useSelector, useDispatch } from 'react-redux';
import { useAction } from '@/Actions/useAction';

// consts
import { FONTS } from '@/Helpers/TextCreator';

// helpers
import pluralize from '@/Utility/pluralize';

// selectors
import {
  selectSelectedGroupIds,
  selectSelectedGroups,
} from '@/Redux/Slices/SelectionSlice';

// actions
import ToggleTextEditorModeAction from '@/Actions/ToggleTextEditorMode';
import DeleteGroupsAction from '@/Actions/DeleteGroups';
import DuplicateGroupsAction from '@/Actions/DuplicateGroups';
import BuildActiveShapeAction from '@/Actions/BuildActiveShape';
import MirrorAction from '@/Actions/Mirror';

// selectors
import { selectFeatures } from '@/Redux/Slices/UISlice';

// components
import CanvasActionMenu from '../CanvasActionMenu';
import TranslationText from '../../TranslationText/TranslationText';
import StackedButton from '../../StackedButton/StackedButton';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';
import { entitlements } from '@/Helpers/Entitlements';
import {
  Shape,
  SvgGroup,
  isToolForShape,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { DUPLICATE_SELECTION_OFFSET } from '@/Constants/UI';
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';

type Props = {
  edge: 'left' | 'right' | 'bottom';
  disableAll?: boolean;
};

// primary canvas menu
export default function DesignActionMenu(props: Props) {
  const dispatch = useDispatch();

  // selectors
  const getGroupById = useSelector(selectGetGroupById);
  const selectedGroups = useSelector(selectSelectedGroups);
  const selectedGroupIds = useSelector(selectSelectedGroupIds);
  const { length: selectionCount } = selectedGroupIds;

  // computed
  const selectedCount = selectedGroupIds.length;
  const hasSelection = !!selectedCount;
  const selectedPoints = selectedGroups.filter(
    (point) => point.tool?.type === Shape.POINT
  );
  const pointParentIds = selectedPoints.map(
    (point) => (point as SvgGroup<Shape.POINT>).tool?.params?.belongsTo
  );
  const uniquePointParentIds = [...new Set(pointParentIds)];
  const parentShapes = uniquePointParentIds.map((parentId) =>
    getGroupById(parentId)
  );

  let partialSelection;

  partialSelection = parentShapes.some((shape) => {
    if (!shape?.tool?.params?.points) {
      return false;
    }
    return shape.tool.params.points.some(
      (pointId: string) => !selectedGroupIds.includes(pointId)
    );
  });

  const hasShapeShifter = !selectedGroups.every(
    (s) =>
      s.tool.type === Shape.TEXT &&
      s.basePathSet.some((b) => (b.outerPath ? !b.outerPath.closed : !b.closed))
  );
  const disableContextualActions = selectedGroups.some((x) =>
    x.type ? PATH_TYPES[x.type].contextual === false : false
  );
  const isText =
    selectedGroups.length === 1 && selectedGroups[0].tool?.type === Shape.TEXT;

  // actions
  const mirrorActions = useAction(MirrorAction);
  const duplicateActions = useAction(DuplicateGroupsAction);
  const deleteActions = useAction(DeleteGroupsAction);
  const buildShapeAction = useAction(BuildActiveShapeAction);
  const toggleTextAction = useAction(ToggleTextEditorModeAction);

  function onEditText() {
    const { tool } = selectedGroups[0];
    if (!isToolForShape(tool, Shape.TEXT)) {
      return;
    }
    const { params } = tool;
    const { text, fontDisplayName, fontDisplayStyle } = params;
    const font = FONTS.find(
      (item) => item.fontDisplayName === fontDisplayName
    )!;
    const style = font.fontStyles.find(
      (item) => item.displayStyle === fontDisplayStyle.displayStyle
    )!;
    toggleTextAction.edit({
      text,
      fontDisplayName,
      fontSelection: style,
      fontDisplayStyle: style,
    });
  }

  function onActivateShapeShifter() {
    buildShapeAction.build();
  }

  function onDuplicateSelection() {
    return duplicateActions.duplicate(
      selectedGroupIds,
      new Point(DUPLICATE_SELECTION_OFFSET, DUPLICATE_SELECTION_OFFSET)
    );
  }

  function onDuplicateSelectionInPlace() {
    return duplicateActions.duplicate(selectedGroupIds, new Point(0, 0));
  }

  async function onDeleteSelection() {
    return await deleteActions.delete(selectedGroupIds);
  }

  // activates an action
  function onSelectAction(target: any) {
    // the action will manage the dispatch
    if (target.dispatch === false) {
      target.toggle();
    }
    // normal toggle
    else {
      dispatch(target.toggle(true));
    }
  }

  const deleteTooltip = pluralize`Delete ${selectionCount} group`;

  const showShapeshifterButton = () =>
    selectFeatures(entitlements.SHAPESHIFTER) &&
    hasSelection &&
    hasShapeShifter;

  // create each action
  const actions = [
    {
      id: 'edit-text',
      icon: 'edit-text',
      label: 'Edit Text',
      enabled: hasSelection && isText,
      toggle: onEditText,
      i18nKey: 'edit-text',
    },
    {
      id: 'shape-shifter',
      icon: 'shape-shifter',
      label: 'ShapeShifter',
      enabled: showShapeshifterButton() && !partialSelection,
      toggle: onActivateShapeShifter,
      i18nKey: 'shape-shifter',
      dispatch: false,
    },
    {
      id: 'mirror',
      enabled: hasSelection && !partialSelection,
      component: (
        <StackedButton
          id='mirror'
          key='mirror-selection'
          dataCy='mirror-group'
          buttons={[
            {
              id: 'mirror-shape--vertical',
              icon: 'mirror-vertical',
              label: 'Mirror Vertical',
              enabled: hasSelection,
              toggle: () => {
                mirrorActions.mirror(selectedGroups, { vertical: true });
              },
              i18nKey: 'mirror-shape--vertical',
            },
            {
              id: 'mirror-shape--horizontal',
              icon: 'mirror-horizontal',
              label: 'Mirror Horizontal',
              enabled: hasSelection,
              toggle: () => {
                mirrorActions.mirror(selectedGroups, { horizontal: true });
              },
              i18nKey: 'mirror-shape--horizontal',
            },
          ]}
        />
      ),
    },

    {
      id: 'duplicate',
      enabled: hasSelection && !partialSelection,
      component: (
        <StackedButton
          id='duplicate'
          key='duplicate-selection'
          dataCy='duplicate-group'
          buttons={[
            {
              id: 'duplicate-shape--in-place',
              icon: 'duplicate-in-place',
              label: 'Duplicate in Place',
              enabled: hasSelection,
              toggle: onDuplicateSelectionInPlace,
              i18nKey: 'duplicate-in-place',
            },
            {
              id: 'duplicate-shape--default',
              icon: 'duplicate',
              label: 'Duplicate',
              enabled: hasSelection,
              toggle: onDuplicateSelection,
              i18nKey: 'duplicate-default',
            },
          ]}
        />
      ),
    },
    {
      id: 'delete',
      icon: 'delete',
      label: (
        <TranslationText i18nKey='delete-group' count={selectionCount}>
          {deleteTooltip}
        </TranslationText>
      ),
      enabled: hasSelection,
      dispatch: false,
      dataCy: 'delete',
      toggle: onDeleteSelection,
    },
  ];

  return (
    !disableContextualActions && (
      <CanvasActionMenu
        onSelectAction={onSelectAction}
        className='canvas-context-action-menu'
        edge={props.edge}
        invert
        actions={actions}
        disableAll={props.disableAll}
        dataCy='canvas-context'
      />
    )
  );
}
