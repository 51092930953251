import {
  Tool,
  SvgGroup,
  Shape,
  isSvgGroupForShape,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import { CutParams } from '@shapertools/sherpa-svg-generator/CutParams';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { createCanvasSvg } from '@shapertools/sherpa-svg-generator/SvgGenerator';
import { mergeAABBs, clone as AABBclone, getAABBSize } from './AABBOps';
import { getAABB } from './BasePathOps';
import { Canvas } from '@shapertools/sherpa-svg-generator/Canvas';
import {
  createSvgGroupFromSvg,
  updateKeyAndValue,
  updateCutParams,
  updateSvgGroupTransform,
  updateTs,
  clone as svgGroupClone,
  applyPostProcess,
  SvgGroupUpdateKey,
  SvgGroupUpdate,
} from './SvgGroupOps';
import { uniq } from 'lodash';

export interface UpdatePaths {
  groupId: string;
  pathId: string;
  cutParams: CutParams;
}

function clone(canvas: Canvas): Canvas {
  const newCanvas = new Canvas();
  newCanvas.svgGroupSet = [...canvas.svgGroupSet];
  newCanvas.AABB = AABBclone(canvas.AABB);
  newCanvas.showCustomAnchor = canvas.showCustomAnchor;
  return newCanvas;
}

function updateCanvasAABB(canvas: Canvas): AABB {
  return canvas.svgGroupSet.reduce(
    (cumBB, sg: SvgGroup) => mergeAABBs(sg.transformedAABB, cumBB),
    new AABB()
  );
}

function createSvgGroupOnCanvasFromSvg(
  canvas: Canvas,
  useGroupSize: boolean,
  position: Point,
  svgStr: string,
  tool?: Tool,
  origin?: Point
): Canvas {
  const svgGroup = createSvgGroupFromSvg({
    useGroupSize,
    position,
    origin,
    svgStr,
    tool,
  });

  canvas.svgGroupSet.push(svgGroup);
  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function createSvgGroupOnCanvasFromSimplePolygon(
  canvas: Canvas,
  position: Point,
  simplePolygon: BasePath[],
  tool: Tool
): Canvas {
  let svgGroup = new SvgGroup({
    basePathSet: simplePolygon,
    position,
    tool,
    displaySize: getAABBSize(getAABB(simplePolygon)),
  });
  svgGroup = applyPostProcess(svgGroup);

  canvas.svgGroupSet.push(svgGroup);

  canvas.AABB = updateCanvasAABB(canvas);

  return canvas;
}

function deleteSvgGroup(
  canvas: Canvas,
  svgGroupId: string | { id: string }
): Canvas {
  const id = typeof svgGroupId === 'object' ? svgGroupId.id : svgGroupId;
  const idIndex = canvas.svgGroupSet.map((s) => s.id).indexOf(id);
  if (idIndex > -1) {
    canvas.svgGroupSet.splice(idIndex, 1);
    canvas.AABB = updateCanvasAABB(canvas);
  }
  return canvas;
}

function deleteSvgGroups(canvas: Canvas, svgGroupIds: string[]): Canvas {
  const idSet = new Set(svgGroupIds);
  canvas.svgGroupSet = canvas.svgGroupSet.filter(({ id }) => !idSet.has(id));
  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function updateSvgGroup(
  canvas: Canvas,
  svgGroupId: string,
  update: SvgGroupUpdate
): Canvas {
  const thisSvgGroup = canvas.svgGroupSet.find((sg) => sg.id === svgGroupId);

  if (thisSvgGroup !== undefined) {
    updateKeyAndValue(thisSvgGroup, update);
  }

  //Refresh pathgroup and canvas BB after size, position, SVG content update
  canvas.AABB = updateCanvasAABB(canvas);

  return canvas;
}

function createAnchorSvgGroupOnCanvas(canvas: Canvas): Canvas {
  let svgGroup = new SvgGroup({
    repairPaths: false,
    useGroupSize: true,
    position: new Point(1.8520833333333333, -3.175),
    svgStr: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -24 14 24">
        <path d="m 0 -24 v 24 h 14 l -14 -24 z" fill="#FF0000"/>
      </svg>`,
    tool: new Tool(),
  });
  svgGroup = applyPostProcess(svgGroup);
  canvas.svgGroupSet.push(svgGroup);

  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function addSvgGroups(canvas: Canvas, svgGroups: SvgGroup[]): Canvas {
  canvas.svgGroupSet.push(...svgGroups);
  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function updatePathsCutParams(
  canvas: Canvas,
  pathsUpdate: UpdatePaths[]
): Canvas {
  //Updating cutParams triggers refresh of canvas AABB.
  //If there are a large number of paths being updated, it is better to update all the paths first, and then refresh AABB only at the end.

  //Track groups that are updated
  const dirtyGroupIds: {
    [key: string]: boolean;
  } = {};
  pathsUpdate.forEach((pu) => {
    const thisSvgGroup = canvas.svgGroupSet.find((g) => g.id === pu.groupId);
    if (thisSvgGroup === undefined) {
      throw new Error('SvgGroup Id not found');
    }

    dirtyGroupIds[pu.groupId] = true;
    // thisSvgGroup.updateCutParams()
    updateCutParams(thisSvgGroup, pu.pathId, pu.cutParams);
  });

  Object.keys(dirtyGroupIds).forEach((gId) => {
    const thisSvgGroup = canvas.svgGroupSet.find((g) => g.id === gId);
    if (thisSvgGroup) {
      updateSvgGroupTransform(thisSvgGroup);
      updateTs(thisSvgGroup);
    }
  });

  //Finally, update canvas AABB once
  canvas.AABB = updateCanvasAABB(canvas);
  return canvas;
}

function getCanvasSVG(canvas: Canvas): string {
  return createCanvasSvg(canvas);
}

function duplicateGroupsById(
  canvas: Canvas,
  requestedGroupIds: string[],
  offset: Point = new Point(5, 5),
  virtualGroups?: SvgGroup[]
): {
  canvas: Canvas;
  groupIds: string[];
} {
  const duplicatedGroupIds: string[] = [];
  let groupIds = [...requestedGroupIds];

  // helper find function
  function findGroup(id: string) {
    return (
      virtualGroups?.find((g) => g.id === id) ??
      canvas.svgGroupSet.find((item) => item.id === id)
    );
  }

  // replace points with their parent group
  for (let i = groupIds.length; i-- > 0; ) {
    const id = groupIds[i];
    const group = findGroup(id);
    if (group && isSvgGroupForShape(group, Shape.POINT)) {
      groupIds[i] = group.tool.params.belongsTo;
    }
  }

  // replace with unique values only
  groupIds = uniq(groupIds);

  const resultingCanvas = groupIds.reduce((targetCanvas, gId) => {
    let canvasAcc = targetCanvas;

    const srcGroup = findGroup(gId);
    if (srcGroup) {
      const targetGroup = svgGroupClone(srcGroup);
      duplicatedGroupIds.push(targetGroup.id);

      canvasAcc.svgGroupSet.push(targetGroup);

      // for a shape, we need to duplicate all points as well
      if (isSvgGroupForShape(targetGroup, Shape.SHAPE)) {
        const targetParams = targetGroup.tool.params;

        // check each point
        for (let i = 0; i < targetParams.points.length; i++) {
          const pointId = targetParams.points[i];
          const srcPoint = canvasAcc.svgGroupSet.find(
            (g) => g.id === pointId
          ) as SvgGroup<Shape.POINT>;

          // create the cloned point
          const targetPoint = svgGroupClone(srcPoint) as SvgGroup<Shape.POINT>;
          targetPoint.tool.params.belongsTo = targetGroup.id;
          canvasAcc.svgGroupSet.push(targetPoint);

          // offset the point
          canvasAcc = updateSvgGroup(canvasAcc, targetPoint.id, {
            key: SvgGroupUpdateKey.RelativePosition,
            value: offset,
          });

          // replace the ID in this new group
          targetParams.points[i] = targetPoint.id;
        }
      }

      return updateSvgGroup(canvasAcc, targetGroup.id, {
        key: SvgGroupUpdateKey.RelativePosition,
        value: offset,
      }) as Canvas;
    }
    return canvasAcc;
  }, canvas);

  return { canvas: resultingCanvas, groupIds: duplicatedGroupIds };
}

export {
  clone,
  updateCanvasAABB,
  createSvgGroupOnCanvasFromSvg,
  createSvgGroupOnCanvasFromSimplePolygon,
  deleteSvgGroup,
  deleteSvgGroups,
  updateSvgGroup,
  createAnchorSvgGroupOnCanvas,
  addSvgGroups,
  updatePathsCutParams,
  getCanvasSVG,
  duplicateGroupsById,
};
