import React, { useState, useLayoutEffect } from 'react';
import { useAction } from '@/Actions/useAction';

// selectors
import {
  selectSelectedPaths,
  selectSelectedGroups,
} from '@/Redux/Slices/SelectionSlice';

import {
  selectActiveUIState,
  selectIsPlanMode,
  selectIsReviewMode,
  selectFeatures,
  selectIsDefaultView,
} from '@/Redux/Slices/UISlice';
import { selectSelectedGroupsExcludingPoints } from '@/Redux/Slices/LineToolSlice';

// actions
import UIFeatureAction from '@/Actions/UIFeature';

// components
import CanvasActionMenu from '../CanvasActionMenu';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { PATH_TYPE } from '@shapertools/sherpa-svg-generator/Path';
import UpdateSvgGroupAction from '../../../Actions/UpdateSvgGroup';
import { entitlements } from '@/Helpers/Entitlements';
import { SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';
import { UpdateSvgGroupPayload } from '@/CanvasContainer/CanvasActions';

type Props = {
  edge: 'left' | 'right';
};

export default function GroupSelectionActionMenu(props: Props) {
  // selectors
  const { isShowingEditSelectionProperties, isShowingSelectionEditor } =
    useSelector(selectActiveUIState);
  const isPlanMode = useSelector(selectIsPlanMode);
  const isReviewMode = useSelector(selectIsReviewMode);
  const isDefaultView = useSelector(selectIsDefaultView);
  const selectedGroups = useSelector(selectSelectedGroups);
  const selectedGroupsExcludingPoints = useSelector(
    selectSelectedGroupsExcludingPoints
  );

  const selectedPaths = useSelector(selectSelectedPaths);
  const hasFeature = selectFeatures(entitlements.SELECTION_MANAGER) || false;

  const [showSelectionManager, setShowSelectionManager] = useState(false);
  const hasReference =
    selectedGroups.some((s) => s?.type === PATH_TYPE.REFERENCE) || false;

  // determine the correct count
  const selectionCount = (
    isPlanMode
      ? selectedPaths.filter((p) => p.path.type !== PATH_TYPE.REFERENCE)
      : selectedGroupsExcludingPoints
  ).length;

  const hasMultiSelection = selectedGroups.length > 1;
  const showEditGroupPanel = isShowingEditSelectionProperties;

  // actions
  const uiAction = useAction(UIFeatureAction);
  const updateSvgGroup = useAction(UpdateSvgGroupAction);

  useLayoutEffect(() => {
    if (
      hasFeature &&
      hasMultiSelection &&
      isDefaultView &&
      !isShowingSelectionEditor &&
      !isReviewMode
    ) {
      setShowSelectionManager(true);
    } else {
      setShowSelectionManager(false);
    }
  }, [
    hasFeature,
    hasMultiSelection,
    isShowingSelectionEditor,
    isReviewMode,
    selectionCount,
  ]);

  // action types
  const actions = [
    {
      id: 'selection-edior',
      label: 'Manage Selection',
      content: selectionCount,
      className: 'secondary',
      visible: showSelectionManager,
      i18nKey: 'manage-selection',
      action: () => {
        uiAction.toggleSelectionEditor(true);
      },
    },
    {
      id: 'edit',
      label: isShowingSelectionEditor
        ? 'Edit Shape Properties'
        : 'Edit Selection',
      icon: 'edit',
      visible:
        !hasReference &&
        (isShowingSelectionEditor ||
          (!!selectionCount &&
            !showEditGroupPanel &&
            typeof showEditGroupPanel !== 'undefined')),
      i18nKey: isShowingSelectionEditor
        ? 'exit-selection-editor'
        : 'edit-selection',
      action: () => {
        uiAction.toggleEditSelectionProperties(true);
      },
    },
    {
      id: 'make-editable',
      label: 'Make Editable',
      icon: 'path-type-lock',
      visible: hasReference,
      i18nKey: 'make-editable',
      action: () => {
        const updates = selectedGroups.map(
          (s): UpdateSvgGroupPayload => ({
            id: s.id,
            update: {
              key: SvgGroupUpdateKey.PathType,
              value: PATH_TYPE.DESIGN,
            },
          })
        );
        updateSvgGroup.apply(updates);
      },
    },
  ];

  // handle the actions from above
  function onSelectAction({ action }: (typeof actions)[number]) {
    action?.();
  }

  const menuCx = classNames('group-selection-menu', {
    'track-properties-panel':
      isShowingSelectionEditor || (!hasReference && showEditGroupPanel),
  });

  return (
    <CanvasActionMenu
      onSelectAction={onSelectAction}
      edge={props.edge}
      className={menuCx}
      actions={actions}
    />
  );
}
