import InteractionMode from '@/InteractionsManager/Modes/InteractionMode';

// interactions
import ZoomViewportInteraction from '@/InteractionsManager/Interactions/ZoomViewport';
import TranslateViewportInteraction from '@/InteractionsManager/Interactions/TranslateViewport';
import PanAndZoomViewportInteraction from '@/InteractionsManager/Interactions/PanAndZoomViewport';
import KeyboardShortcutsInteraction from '../Default/Interactions/KeyboardShortcuts';

export default class DefaultMode extends InteractionMode {
  theme = 'shape-shifter';

  getInteractionHandlers() {
    return [
      KeyboardShortcutsInteraction,
      TranslateViewportInteraction,
      ZoomViewportInteraction,
      PanAndZoomViewportInteraction,
    ];
  }
}
