import BaseAction from './BaseAction';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';

// selectors
import {
  selectGetGroupById,
  selectSvgGroupSet,
} from '@/Redux/Slices/CanvasSlice';
import { selectViewport } from '@/Redux/Slices/ViewportSlice';
import { duplicateSelectedGroups } from '@/CanvasContainer/CanvasActions';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { selectIsDesignMode, selectIsPlanMode } from '@/Redux/Slices/UISlice';
import { selectSelectedPathIds } from '@/Redux/Slices/SelectionSlice';
import { CutParams } from '@shapertools/sherpa-svg-generator/CutParams';
import { isEqual } from 'lodash';
import UpdateSvgPathAction from './UpdateSvgPath';

export const MISMATCH = 1;
export const EMPTY = 2;

interface PathSelection {
  pathId: string;
  groupId: string;
}

export default class CopyPasteAction extends BaseAction {
  // the current clipboard content
  static copiedCutPathData: CutParams | undefined = undefined;
  static copiedGroups: SvgGroup[] = [];
  static offsetX: number = 0;
  static offsetY: number = 0;

  // gather all path data
  copyCutParams(pathIds: PathSelection[]) {
    const getGroup = this.useSelector(selectGetGroupById);

    // nothing selected
    if (!pathIds?.length) {
      return EMPTY;
    }

    // get the first set of cut params and ensure
    // that all remaining match
    let params: CutParams | undefined;
    for (const { groupId, pathId } of pathIds) {
      const group = getGroup(groupId) as SvgGroup;
      const path = group.basePathSet.find(({ id }) => id === pathId);

      // if it's missing for some reason
      if (!path) {
        continue;
      }

      // hasn't been set yet
      if (!params) {
        params = path.cutParams;
        continue;
      }

      // compare the cut paths
      if (!isEqual(path.cutParams, params)) {
        return MISMATCH;
      }
    }

    // save the cut params
    CopyPasteAction.copiedCutPathData = params;
  }

  // saves a set of groups to a clipboard
  copyGroups(groupIds: string[]) {
    // nothing to copy
    if (!groupIds.length) {
      return EMPTY;
    }

    // keep track of x/y coordinates which
    // will be used to calculate offsets to
    // properly position elements when pasting
    const xs: number[] = [];
    const ys: number[] = [];

    // since it's possible for groups to be deleted, we need to just
    // clone all of the group information
    let groups = this.useSelector(selectSvgGroupSet).filter((group) => {
      const keep = groupIds.includes(group.id);

      // since we're here, get the bounds
      if (keep) {
        xs.push(
          group.transformedAABB.minPoint.x,
          group.transformedAABB.maxPoint.x
        );
        ys.push(
          group.transformedAABB.minPoint.y,
          group.transformedAABB.maxPoint.y
        );
      }

      return keep;
    });

    // figure out the center offset for this selection - this will be used
    // when pasting to try and place the object at the center of the view
    CopyPasteAction.offsetX = (Math.min(...xs) + Math.max(...xs)) >> 1;
    CopyPasteAction.offsetY = (Math.min(...ys) + Math.max(...ys)) >> 1;

    // save to the clipboard
    // clone the group data - just in case something
    // is deleted before pasting
    CopyPasteAction.copiedGroups = JSON.parse(JSON.stringify(groups));
  }

  // paste the clipboard contents
  paste(args: any) {
    const isDesignMode = this.useSelector(selectIsDesignMode);
    const isPlanMode = this.useSelector(selectIsPlanMode);

    if (isDesignMode) {
      this._pasteAsDesignMode(args);
    } else if (isPlanMode) {
      this._pasteAsPlanMode();
    }
  }

  // tries to paste cut params
  _pasteAsPlanMode() {
    if (!CopyPasteAction.copiedCutPathData) {
      return;
    }

    const update = this.createAction(UpdateSvgPathAction);
    const selectedPathIds = this.useSelector(selectSelectedPathIds);
    const getGroup = this.useSelector(selectGetGroupById);

    // map out targets
    const targets = selectedPathIds
      .map(({ groupId, pathId }) => {
        const group = getGroup(groupId) as SvgGroup;
        const path = group?.basePathSet.find(({ id }) => id === pathId);
        return group && path ? { group, path } : null;
      })
      .filter((item) => !!item);

    // update all paths
    update.setAll(targets, CopyPasteAction.copiedCutPathData);
  }

  // try and paste the selected groups
  _pasteAsDesignMode({ pasteInCenter }: { pasteInCenter?: boolean } = {}) {
    if (!CopyPasteAction.copiedGroups?.length) {
      return;
    }

    // get the groups to paste
    const { copiedGroups: groups } = CopyPasteAction;

    // calculate the offset for pasting
    let x = 0;
    let y = 0;

    // apply the offset to put this in the center of the screen
    if (pasteInCenter !== false) {
      x = CopyPasteAction.offsetX || 0;
      y = CopyPasteAction.offsetY || 0;

      // make negative to return to the origin
      x *= -1;
      y *= -1;

      // determine the center origin to paste
      const { canvasViewbox } = this.useSelector(selectViewport);
      const center = {
        x: (canvasViewbox.minPoint.x + canvasViewbox.maxPoint.x) >> 1,
        y: (canvasViewbox.minPoint.y + canvasViewbox.maxPoint.y) >> 1,
      };

      // then apply panning
      x += center.x;
      y += center.y;
    }

    // perform a duplicate with virtual groups
    const { dispatch } = this;
    const ids = groups.map((g) => g.id);
    dispatch(
      duplicateSelectedGroups({
        groupIds: ids,
        offset: { x, y } as Point,
        virtualGroups: groups,
      })
    );
  }
}
