import { batch } from 'react-redux';
import UIModeAction from '@/Actions/UIMode';
import SetSelectionAction from '@/Actions/SetSelection';
import { uniq } from 'lodash';

import {
  addSVGGeometry,
  addSvgGroups,
  updateSvgGroup,
  deleteGroupIdsAndAddSimplePolygonGeometry,
  SimplePolygonsParams,
  AddSVGGeometryPayload,
  UpdateSvgGroupPayload,
} from '@/CanvasContainer/CanvasActions';
import { clearShapeShifter } from '@/Redux/Slices/ShapeShifterSlice';
import { selectViewportCenterCanvas } from '@/Redux/Slices/ViewportSlice';
import { clearSelection, GroupId } from '@/Redux/Slices/SelectionSlice';
import { UseSelector, useActionWithDispatch } from './useAction';
import { IPoint, Point } from '@shapertools/sherpa-svg-generator/Point';
import {
  Shape,
  SvgGroup,
  Tool,
  ToolParams,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import { AppDispatch } from '@/Redux/store';
import { SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';
import { selectAllSvgGroupSet } from '@/Redux/Slices/CanvasSlice';

export type AddSvgParams = {
  rawSVG: string;
  uriSVG?: string;
  tool: Tool;
};

export default class AddGeometryAction {
  dispatch: AppDispatch;
  useSelector: UseSelector;
  selection: SetSelectionAction;

  constructor(dispatch: AppDispatch, useSelector: UseSelector) {
    this.dispatch = dispatch;
    this.useSelector = useSelector;
    this.selection = new SetSelectionAction(dispatch, useSelector);
  }

  addSvg(
    type: 'unsized' | 'icon' | 'new-text',
    svgParams: AddSvgParams,
    placePosition: IPoint
  ) {
    const { dispatch, useSelector } = this;
    const position = placePosition ?? useSelector(selectViewportCenterCanvas);

    const dispatchParams: AddSVGGeometryPayload = (() => {
      switch (type) {
        //Add SVG geometry to canvas using size specified by the SVG file
        case 'unsized':
          return {
            ...svgParams,
            useGroupSize: true,
            position: new Point(position.x, position.y),
          };

        //Adds Icon geometry at default size to canvas
        case 'icon':
          return {
            ...svgParams,
            useGroupSize: false,
            position: new Point(position.x, position.y),
          };

        //Adds new text
        case 'new-text':
          return {
            ...svgParams,
            useGroupSize: true,
            position: new Point(position.x, position.y),
          };

        default:
          throw new Error('Unknown svg type');
      }
    })();

    dispatch(addSVGGeometry(dispatchParams));
  }

  placeImportedGroups(groups: SvgGroup[]) {
    const { dispatch } = this;
    dispatch(addSvgGroups(groups));
  }

  updateText(svgParams: any, selectedGroupIds: GroupId[]) {
    const { dispatch } = this;

    const updateBatch = selectedGroupIds.map((gId) => ({
      id: gId,
      update: {
        key: SvgGroupUpdateKey.ToolSvg,
        value: svgParams,
      },
    })) as UpdateSvgGroupPayload[];

    dispatch(updateSvgGroup(updateBatch));
  }

  addShapeShifterCommits({
    simplePolygonsParams = [],
    deleteGroupIds = [],
  }: {
    simplePolygonsParams: SimplePolygonsParams[];
    deleteGroupIds: GroupId[];
  }) {
    const { dispatch } = this;
    const uiModeAction = useActionWithDispatch(UIModeAction, dispatch);
    const svgGroups = this.useSelector(selectAllSvgGroupSet) as SvgGroup[];

    batch(async () => {
      // check for shapes that may also need to be included
      const shapes: { [id: string]: boolean } = {};
      for (const id of deleteGroupIds) {
        const group = svgGroups.find((item) => item.id === id);
        if (group?.tool?.type === Shape.POINT) {
          shapes[(group.tool.params as ToolParams<Shape.POINT>).belongsTo] =
            true;
        }
      }

      // create a clean up pass
      let cleanUp = [...deleteGroupIds];
      for (const id of Object.keys(shapes)) {
        const shape = svgGroups.find((item) => item.id === id)!;
        cleanUp.push(
          ...[
            shape.id,
            ...(shape.tool.params as ToolParams<Shape.SHAPE>).points,
          ]
        );
      }

      dispatch(clearSelection());
      dispatch(
        deleteGroupIdsAndAddSimplePolygonGeometry({
          deleteGroupIds: uniq([...deleteGroupIds, ...cleanUp]),
          simplePolygonsParams,
        })
      );
      //Shapeshifter used to require batching redux actions for undos. However, this is tricky to implement with workspace sync, so I've created a single action and reducer to delete shapeshifter inputs and add shapeshifter outputs. This eliminates the need for batching undos.
      //The PatchBatcher helper in ImmerPatchHelpers will batch undos locally, if we need this function elsewhere
      dispatch(clearShapeShifter());
      uiModeAction.toDefault();
    });
  }
}
