import { getAABBSize, transform as AABBTransform } from './AABBOps';
import {
  createScaleMtx,
  createTranslateMtx,
  createRotationMtx,
  clone as matrixClone,
  getSVGTransformParams,
  createStretchMtx,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import { defaultSvgGroupSize } from '@/defaults';
import {
  getAABB,
  transform as basePathTransform,
  clonePath,
  getId,
  sanitize,
} from './BasePathOps';
import { add, clone as ptClone } from './PointOps';
import RoundPath from './RoundPath';
import { SvgOps } from './SvgParser';
import { closePaths, joinPaths, removeDuplicatePaths } from './PathRepair';
import { difference } from './Helpers';
import {
  CutParams,
  CutType,
} from '@shapertools/sherpa-svg-generator/CutParams';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import {
  Matrix33,
  multiplyMatrix33,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import {
  isSvgGroupForShape,
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';
import {
  isSourceSvgObject,
  PATH_TYPE,
} from '@shapertools/sherpa-svg-generator/Path';
import { Anchor } from '@/@types/shaper-types';
import { createScaledBasePathSvg } from './SvgCache/BasePathCache';

const CLOSE_PATHS = true;
const JOIN_PATHS = true;
const REMOVE_DUPLICATE_PATHS = true; //Expensive, may need to disable until better profiling and performance

type CreateSVGGroupFromSvgParams = {
  useGroupSize: boolean;
  position?: Point;
  svgStr: string;
  tool?: Tool;
  origin?: Point;
  repair?: boolean;
  originWorkpiece?: boolean;
};

// Wrapper for createSVGGroupAndImportPositionFromSvg function to keep old API and let new function provide importPosition where needed.

function createSvgGroupFromSvg(params: CreateSVGGroupFromSvgParams) {
  //
  const { svgGroup } = createSVGGroupAndImportPositionFromSvg(params);

  return svgGroup;
}

function createSVGGroupAndImportPositionFromSvg(
  params: CreateSVGGroupFromSvgParams
) {
  const {
    useGroupSize,
    svgStr,
    repair,
    tool,
    originWorkpiece = false,
  } = params;
  const { cutPaths, displaySize, importPosition } =
    createBasePathsAndDisplaySizeFromSvg(
      useGroupSize,
      svgStr,
      tool,
      undefined,
      originWorkpiece,
      repair
    );
  const svgGroup = applyPostProcess(
    new SvgGroup({
      basePathSet: cutPaths,
      ...params,
      ...(displaySize && { displaySize }),
      ...(tool?.type === Shape.POINT && { type: PATH_TYPE.POINT }),
    })
  );
  return { svgGroup, importPosition };
}

function getSvgGroupScale(baseAABB: AABB, displaySize: Point) {
  const baseSize = getAABBSize(baseAABB);
  return new Point(displaySize.x / baseSize.x, displaySize.y / baseSize.y);
}

export enum SvgGroupUpdateKey {
  /* eslint-disable no-unused-vars */
  DisplaySize = 'displaySize',
  DisplaySizeRelativePosition = 'displaySize:relative_position',
  RelativeStretchRelativePosition = 'relative_stretch:relative_position',
  RotationRelativePosition = 'rotation:relative_position',
  RelativePosition = 'relative_position',
  AbsolutePosition = 'absolute_position',
  ForceOpenPaths = 'forceOpenPaths',
  ToolSvg = 'toolSvg',
  /* eslint-disable-next-line @typescript-eslint/no-shadow */
  Anchor = 'anchor',
  PathType = 'pathtype',
  /* eslint-enable no-unused-vars */
}
type SvgGroupKeyValues = {
  [key in keyof SvgGroup]-?: { key: key; value: SvgGroup[key] };
};
export type SvgGroupUpdate =
  | {
      key: SvgGroupUpdateKey.DisplaySize;
      value: Point;
    }
  | {
      key: SvgGroupUpdateKey.DisplaySizeRelativePosition;
      value: any;
    }
  | {
      key: SvgGroupUpdateKey.RelativeStretchRelativePosition;
      value: {
        deltaPos: Point;
        stretchMtx: Matrix33;
      };
    }
  | {
      key: SvgGroupUpdateKey.RotationRelativePosition;
      value: {
        deltaPosition: Point;
      } & (
        | {
            deltaRotation: number;
            setRotation: undefined;
          }
        | {
            deltaRotation: undefined;
            setRotation: number;
          }
      );
    }
  | {
      key: SvgGroupUpdateKey.RelativePosition;
      value: Point;
    }
  | {
      key: SvgGroupUpdateKey.AbsolutePosition;
      value: Point;
    }
  | {
      key: SvgGroupUpdateKey.ToolSvg;
      value: {
        tool: Tool;
        rawSVG: string;
        deltaPos?: Point;
        setPos?: Point;
        setPosition?: Point;
        constantDim?: 'width';
        stretchMtx?: Matrix33;
      };
    }
  | {
      key: SvgGroupUpdateKey.Anchor;
      value: Anchor;
    }
  | {
      key: SvgGroupUpdateKey.PathType;
      value: PATH_TYPE;
    }
  | SvgGroupKeyValues[Exclude<keyof SvgGroup, 'displaySize' | 'anchor'>];

function updateKeyAndValue(svgGroup: SvgGroup, update: SvgGroupUpdate) {
  const { key, value } = update;

  let oldDims = new Point();

  switch (key) {
    case SvgGroupUpdateKey.DisplaySize:
      svgGroup.displaySize = value;
      rescaleStretchMtx(svgGroup);
      break;
    case SvgGroupUpdateKey.DisplaySizeRelativePosition:
      throw new Error(`Deprecated key ${key}`);
    case SvgGroupUpdateKey.RelativeStretchRelativePosition:
      stretchAndPosition(svgGroup, value.deltaPos, value.stretchMtx);
      break;
    case SvgGroupUpdateKey.RotationRelativePosition:
      rotateAndTranslate(
        svgGroup,
        value.deltaPosition,
        value.deltaRotation,
        value.setRotation
      );
      break;
    case SvgGroupUpdateKey.RelativePosition:
      relativeTranslate(svgGroup, value);
      break;
    case SvgGroupUpdateKey.AbsolutePosition:
      translate(svgGroup, value);
      break;
    case SvgGroupUpdateKey.ToolSvg:
      svgGroup.tool = value.tool;

      if ('deltaPos' in svgGroup.tool.params) {
        delete svgGroup.tool.params.deltaPos;
      }

      if (value.constantDim !== undefined) {
        oldDims = getAABBSize(svgGroup.transformedAABB);
      }
      const { cutPaths, displaySize } = createBasePathsAndDisplaySizeFromSvg(
        true,
        value.rawSVG,
        svgGroup.tool
      );

      //Update cutParams when toolSvg changes
      //This needs to be done when changing polygon sides, resizing rounded rectangles, and updating text
      //NOTE - this assumes that all cutParams are the same, because we have no way of associating old pathIds with new pathIds, especially where the number of paths change after the update, which is common with text updates
      const oldCutParams =
        svgGroup.basePathSet[0]?.cutParams ||
        new CutParams({ cutType: CutType.OUTSIDE });

      svgGroup.basePathSet = cutPaths;
      svgGroup.displaySize = displaySize ?? new Point();

      //Copy last cutParams into new basePaths
      svgGroup.basePathSet.forEach((bp) => {
        if (bp.outerPath) {
          bp.cutParams = new CutParams({
            ...oldCutParams,
            ...(!bp.outerPath.closed ? { cutType: CutType.ONLINE } : {}),
          });
        } else {
          bp.cutParams = new CutParams({
            ...oldCutParams,
            ...(!bp.closed ? { cutType: CutType.ONLINE } : {}),
          });
        }
      });

      if (value.deltaPos !== undefined) {
        svgGroup.position = add(svgGroup.position, value.deltaPos);
      }
      if (value.stretchMtx !== undefined) {
        svgGroup.stretchMtx = multiplyMatrix33(
          value.stretchMtx,
          svgGroup.stretchMtx
        );
      }
      if (value.constantDim !== undefined) {
        //This rescales svgGroup to match prior width or height
        //Used for scaling text groups when font changes.
        updateSvgGroupTransform(svgGroup);
        const newDims = getAABBSize(svgGroup.transformedAABB);
        const ratio =
          value.constantDim === 'width'
            ? oldDims.x / newDims.x
            : oldDims.y / newDims.y;
        const newScaleMtx = createScaleMtx(ratio);
        svgGroup.stretchMtx = multiplyMatrix33(
          newScaleMtx,
          svgGroup.stretchMtx
        );
      }
      break;
    case SvgGroupUpdateKey.Anchor:
      svgGroup.anchor = value;
      break;
    case SvgGroupUpdateKey.PathType:
      svgGroup.type = value;
      svgGroup.basePathSet.forEach((b) => {
        b.type = value;
      });
      break;
    default:
      // @ts-ignore Typescript can't properly typecheck this line, but the types above ensure it works
      svgGroup[key] = value;
  }

  updateTs(svgGroup);
  updateSvgGroupTransform(svgGroup);

  if (key === SvgGroupUpdateKey.ToolSvg) {
    applyPostProcess(svgGroup);
  }
}

function updateCutParams(
  svgGroup: SvgGroup,
  pathId: string,
  newCutParams: CutParams
) {
  const selectedPath = svgGroup.basePathSet.find((bp) => bp.id === pathId);

  if (selectedPath === undefined) {
    throw new Error(`pathId ${pathId} not found`);
  }

  selectedPath.cutParams = newCutParams;

  updateTs(svgGroup);
}

export function createBasePathsAndDisplaySizeFromSvg(
  useGroupSize: boolean = false,
  svgStr: string = '',
  tool?: Tool,
  preferSize?: Point,
  originWorkpiece?: boolean,
  repair: boolean = false
): {
  cutPaths: BasePath[];
  displaySize: Point | null;
  importPosition: Point | null;
} {
  const forceOpenPaths = tool?.params?.forceOpenPaths;
  const { cutPaths, svgSize, importPosition } = SvgOps.convertSvgStrToPaths(
    svgStr,
    forceOpenPaths,
    originWorkpiece,
    repair
  ); //paths is array of Path Objects

  const displaySize = (() => {
    //Icons from noun project are imported and scaled to 25mm / 1 in by default
    //SVG and DXF files are imported and scaled to actual dimensions listed in file, if possible
    if (useGroupSize) {
      return svgSize;
    } else if (preferSize) {
      return preferSize;
    }
    //useGroupSize === false, scale svg to default dimensions
    const defaultDim = defaultSvgGroupSize.imperial;
    //Default svgGroupSize is now set in defaults for metric and imperial units.
    //When user unit settings are enabled, we can toggle between these.

    const baseAABB = getAABB(cutPaths as BasePath[]);
    const groupSize = getAABBSize(baseAABB);
    const newDisplaySize = new Point();
    if (groupSize.x > groupSize.y) {
      newDisplaySize.x = defaultDim;
      newDisplaySize.y = (defaultDim * groupSize.y) / groupSize.x;
    } else {
      newDisplaySize.y = defaultDim;
      newDisplaySize.x = (defaultDim * groupSize.x) / groupSize.y;
    }
    return newDisplaySize;
  })();

  return { cutPaths: cutPaths as BasePath[], displaySize, importPosition };
}

function updateSvgGroupTransform(svgGroup: SvgGroup) {
  //Stretch matrix is updated separately, because it depends on whether update is a relative or absolute scaling operation
  const rotateMtx = createRotationMtx(svgGroup.rotation);
  svgGroup.rotateMtx = rotateMtx;
  svgGroup.translateMtx = createTranslateMtx(svgGroup.position);
  svgGroup.TRSMtx = multiplyMatrix33(
    svgGroup.translateMtx,
    rotateMtx,
    svgGroup.stretchMtx
  );

  const TRSPathSet = basePathTransform(svgGroup.basePathSet, svgGroup.TRSMtx);

  svgGroup.transformedAABB = getAABB(TRSPathSet);
  svgGroup.unrotatedAABB = AABBTransform(
    getAABB(basePathTransform(svgGroup.basePathSet, svgGroup.stretchMtx)),
    svgGroup.translateMtx
  );
  return svgGroup;
}

function repairPaths(paths: BasePath[]): BasePath[] {
  const passedPaths = paths.filter((p) => p.outerPath); // paths that have already been shapeshifted
  let processedPaths = paths.filter(
    (p) => !p.outerPath && p.points && p.points.length > 0
  ); // all other paths
  if (CLOSE_PATHS) {
    processedPaths = closePaths(processedPaths) as BasePath[];
  }
  if (JOIN_PATHS) {
    processedPaths = joinPaths(processedPaths) as BasePath[];
  }

  if (REMOVE_DUPLICATE_PATHS) {
    processedPaths = removeDuplicatePaths(processedPaths);
  }

  return [...processedPaths, ...passedPaths];
}

function clone(svgGroup: SvgGroup, newPaths?: BasePath[]): SvgGroup {
  const basePathSet = (() => {
    if (newPaths) {
      return newPaths;
    }
    const basePaths = clonePath(svgGroup.basePathSet);
    if (!Array.isArray(basePaths)) {
      return [basePaths];
    }

    return basePaths;
  })();

  return new SvgGroup({
    useId: svgGroup.useId,
    tool: JSON.parse(JSON.stringify(svgGroup.tool)),
    position: ptClone(svgGroup.position),
    rotation: svgGroup.rotation,
    displaySize: ptClone(svgGroup.displaySize),
    stretchMtx: matrixClone(svgGroup.stretchMtx),
    basePathSet: basePathSet,
    anchor: svgGroup.anchor,
  });
}

function updateTs(svgGroup: SvgGroup) {
  svgGroup.generatedTs = Date.now();
}

function rescaleStretchMtx(svgGroup: SvgGroup) {
  const { stretchMtx, baseAABB, displaySize } = svgGroup;

  // for a shape, revert the scale to the original size and then
  // scale it up based on the new display size
  if (isSvgGroupForShape(svgGroup, Shape.SHAPE)) {
    const tw =
      (svgGroup.transformedAABB.maxPoint.x -
        svgGroup.transformedAABB.minPoint.x) /
      (svgGroup.stretchMtx[0][0] || 1);
    const th =
      (svgGroup.transformedAABB.maxPoint.y -
        svgGroup.transformedAABB.minPoint.y) /
      (svgGroup.stretchMtx[1][1] || 1);

    const sx = displaySize.x / (tw || 1);
    const sy = displaySize.y / (th || 1);

    svgGroup.displaySize = { ...displaySize };
    svgGroup.stretchMtx = createStretchMtx(sx, sy);

    return svgGroup;
  }

  const newScale = getSvgGroupScale(baseAABB, displaySize);

  //newScale resizes group from baseBB to display size. To get new stretch mtx, need to normalize shears to baseBB before scaling matrix to new size

  const oldScaleX = stretchMtx[0][0];
  const oldScaleY = stretchMtx[1][1];

  svgGroup.stretchMtx = [
    [newScale.x, (newScale.x / oldScaleX) * stretchMtx[0][1], 0],
    [(newScale.y / oldScaleY) * stretchMtx[1][0], newScale.y, 0],
    [0, 0, 1],
  ];
  updateTs(svgGroup);
  return svgGroup;
}

function stretchAndPosition(
  svgGroup: SvgGroup,
  deltaPos: Point,
  stretchMtx: Matrix33
) {
  svgGroup.position = add(svgGroup.position, deltaPos);

  svgGroup.stretchMtx = multiplyMatrix33(stretchMtx, svgGroup.stretchMtx);

  return svgGroup;
}

function rotateAndTranslate(
  svgGroup: SvgGroup,
  deltaPosition: Point = new Point(),
  deltaRotation: number = 0,
  setRotation?: number
) {
  const originalSvgGroup = JSON.parse(JSON.stringify(svgGroup));
  svgGroup.position = add(svgGroup.position, deltaPosition);

  // shapes and points are not rotated
  if ([Shape.SHAPE, Shape.POINT].includes(svgGroup.tool.type)) {
    // no-op - do not change
    svgGroup.rotation = 0;
    return svgGroup;
  }

  const rotateTo = setRotation as number;
  if (!isNaN(rotateTo)) {
    svgGroup.rotation = rotateTo;
  } else {
    svgGroup.rotation += deltaRotation;
  }

  difference(originalSvgGroup, svgGroup);
  return svgGroup;
}

function relativeTranslate(svgGroup: SvgGroup, deltaPos: Point) {
  svgGroup.position = add(svgGroup.position, deltaPos);

  return svgGroup;
}

function translate(svgGroup: SvgGroup, position: Point) {
  svgGroup.position = new Point(position.x, position.y);

  return svgGroup;
}

//Creates standalone svg of group for import mode
function createStandaloneGroupSvg(svgGroup: SvgGroup) {
  const basePathsSvg = svgGroup.basePathSet.map((basePath) => {
    const transformAttr = (() => {
      if (isSourceSvgObject(basePath.sourceSvg)) {
        const transformMtx = basePath.sourceSvg.sourceTransform;

        return [
          {
            name: 'transform',
            value: `${getSVGTransformParams(transformMtx)}`,
          },
        ];
      }
      return [];
    })();
    return createScaledBasePathSvg(
      svgGroup.id,
      getId(basePath) as string,
      basePath,
      ['import'],
      transformAttr
    );
  });

  return basePathsSvg;
}

function getSanitizedSvgGroup(svgGroup: SvgGroup): Object {
  return {
    ...svgGroup,
    // reduce how many basepaths are showing to help with performance of devtools
    basePathSet: sanitize(svgGroup.basePathSet.slice(0, 25)),
  };
}

/**
 * Function to apply the post process functions on a BasePathSet
 * @param {SvgGroup} svgGroup - the svg group that contains the `basePathSet` that needs the post process functions applied to
 * @returns {SvgGroup} returns the svg group that was passed in
 */
function applyPostProcess(svgGroup: SvgGroup) {
  const { tool } = svgGroup;

  switch (tool?.params?.postProcess) {
    case 'round':
      // Automatic path closing has been added back to SvgParser to avoid paths not being closed correctly.
      const roundedPaths = RoundPath.roundPath({
        paths: svgGroup.basePathSet,
        tool,
      });
      svgGroup.basePathSet = roundedPaths;
      break;
    case 'removeSourceSvg':
      svgGroup.basePathSet = svgGroup.basePathSet.map((basePath) => {
        return {
          ...basePath,
          sourceSvg: undefined,
        };
      });
      break;
    default:
      break;
  }

  return svgGroup;
}

export {
  clone,
  createSVGGroupAndImportPositionFromSvg,
  createScaledBasePathSvg,
  createSvgGroupFromSvg,
  updateKeyAndValue,
  updateCutParams,
  updateSvgGroupTransform,
  updateTs,
  repairPaths,
  createStandaloneGroupSvg,
  getSanitizedSvgGroup,
  applyPostProcess,
};
