import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Snapshot, SyncStatus } from '../../Sync/SyncConstants';
import { PatchSet } from '../../Sync/PatchGenerator';
import { CanvasState } from '@/Redux/Slices/CanvasSlice';
import { Workspace } from '@/@types/shaper-types';
import { RootState } from '@/Redux/store';
import { log } from '@/Sync/SyncLog';
import { updateSnapshot } from '@/Sync/SyncHelpers';
import { addAttributeToUser } from '@/Utility/userflow';
import { SyncError } from '@/Sync/SyncError';

interface SyncState {
  syncUrl?: string;
  enabled: boolean;
  status: SyncStatus;
  workspace: Workspace | null;
  reconnectId?: string | null;
  passedWorkspaceId?: string | null;
  passedProjectId?: string | null;
  queue: PatchSet[];
  pendingQueue: PatchSet[];
  duplicateId?: string;
}

export const initialSyncState: SyncState = {
  enabled: false,
  status: 'disconnected',
  workspace: null,
  queue: [],
  pendingQueue: [],
};

export interface SnapshotUpdate {
  slice?: string;
  value: CanvasState | null;
}

export type SyncStatusPayload = {
  status: SyncStatus;
  error?: SyncError;
};

const context = {
  syncLevel: 'slice',
};

export const syncSlice = createSlice({
  name: 'sync',
  initialState: initialSyncState,
  reducers: {
    setEnabled: (state, action: PayloadAction<boolean>) => {
      log(`Setting sync to enabled as ${action.payload}`, { ...context });
      state.enabled = action.payload;
    },
    setWorkspaceIdFromUrlParam: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.passedWorkspaceId = action.payload;
    },
    setProjectIdFromUrlParam: (state, action: PayloadAction<string | null>) => {
      state.passedProjectId = action.payload;
    },
    setSyncUrl: (state, action: PayloadAction<string>) => {
      state.syncUrl = action.payload;
    },
    setWorkspace: (state, action: PayloadAction<Workspace | null>) => {
      state.workspace = action.payload;

      // Remove the chance of a bad setWorkspace call causing the workspace ID that
      // was previously set from being removed
      if (state.workspace) {
        addAttributeToUser('workspaceId', state.workspace.id);
      }
    },
    updateQueue: (state, action: PayloadAction<PatchSet[]>) => {
      state.queue = action.payload;
    },
    updatePendingQueue: (state, action: PayloadAction<PatchSet[]>) => {
      state.pendingQueue = action.payload;
    },
    setSnapshot: (_, action: PayloadAction<SnapshotUpdate | Snapshot>) => {
      if ('slice' in action.payload || 'value' in action.payload) {
        const { slice, value } = action.payload;
        const snapshot = {
          [(slice as keyof Snapshot) ?? 'canvas']: value,
        };
        updateSnapshot(snapshot);
      } else {
        updateSnapshot(action.payload);
      }
    },
    setSequence: (state, action: PayloadAction<number>) => {
      if (state.workspace) {
        state.workspace.latestUpdateSequence = action.payload;
      }
    },
    setStatus: (state, action: PayloadAction<SyncStatusPayload>) => {
      state.status = action.payload.status;
    },
    setReconnectId: (state, action: PayloadAction<string>) => {
      state.reconnectId = action.payload;
    },
    removeReconnectId: (state) => {
      const { reconnectId, ...restOfState } = state;
      return restOfState;
    },
    setDuplicateId: (state, action: PayloadAction<string>) => {
      state.duplicateId = action.payload;
    },
  },
});

export const {
  setEnabled,
  setWorkspaceIdFromUrlParam,
  setProjectIdFromUrlParam,
  setSyncUrl,
  setWorkspace,
  updateQueue,
  updatePendingQueue,
  setSnapshot,
  setSequence,
  setStatus,
  setReconnectId,
  removeReconnectId,
  setDuplicateId,
} = syncSlice.actions;

export const selectIsEditMode = (state: RootState) =>
  state.sync.status === 'edit' || state.sync.status === 'pending';

export const selectWorkspace = (state: RootState) => state.sync.workspace;
export const selectWorkspaceId = (state: RootState) => state.sync.workspace?.id;
export const selectSnapshot = (): Snapshot => {
  return (
    window.snapshot || {
      canvas: null,
    }
  );
};
export const selectOriginalSnapshot = (): Snapshot | undefined => {
  return window.originalSnapshot;
};
export const selectWorkspaceIdFromUrlParam = (state: RootState) =>
  state.sync.passedWorkspaceId;
export const selectProjectIdFromUrlParam = (state: RootState) =>
  state.sync.passedProjectId;
export const selectDuplicateId = (state: RootState) =>
  state.sync.duplicateId || '';
export const selectEnabled = (state: RootState) => state.sync.enabled;
export const selectSyncStatus = (state: RootState) => state.sync.status;

export const { reducer } = syncSlice;
