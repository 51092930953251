import BaseAction from './BaseAction';
import { updateRotationHandleCursorAngle } from '@/Cursors/RotationHandleCursorHelper';

// utils
import { ROTATION_HANDLES } from '@/Constants/UI';
import {
  translate,
  invert,
  createMatrix33,
  createRotationMtx,
  getRotationAngleFromMtx,
  getDegreesFromMtx,
  Matrix33,
  multiplyMatrix33,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import { transform, subtract } from '@/Geometry/PointOps';
import UIState from '@/UILayer/State/UIState';

// selectors
import { selectGetGroupById } from '@/Redux/Slices/CanvasSlice';
import { ViewportState, selectViewport } from '@/Redux/Slices/ViewportSlice';
import { selectSelectionBounds } from '@/Redux/Slices/SelectionSlice';

import {
  selectGetPointChangesFromUpdateSet,
  selectMostRecentlyMovedPoint,
  selectSelectedLine,
} from '@/Redux/Slices/LineToolSlice';

// actions
import UpdateSvgGroupAction from '@/Actions/UpdateSvgGroup';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';
import { SvgGroup } from '@shapertools/sherpa-svg-generator/SvgGroup';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { SvgGroupUpdate, SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';
import { UpdateSvgGroupPayload } from '@/CanvasContainer/CanvasActions';
import SelectionBox from '@/Helpers/SelectionBoxHelper';

// probably find a better home later
const RAD_TO_DEG = 180 / Math.PI;

export type RotateByMatrixOptions = {
  updateDOM?: boolean;
  group?: SvgGroup;
};

// handles performing a translate for groups
export default class RotateGroupsAction extends BaseAction {
  static isAllowedHandle(handle: string) {
    return ROTATION_HANDLES.includes(handle);
  }

  selection: SvgGroup[];
  rotationOrigin: Point;
  startingAngle: number;
  rotatingAnchor?: string;
  selectionGroupCenter: Point;
  selectionCenterMtx: Matrix33;
  invSelectionCenterMtx: Matrix33;
  rotationMtx: Matrix33;
  initialRotationMtx: Matrix33;
  startingRotation: number;
  initialVector: Point;
  viewport: ViewportState;
  changes: { [key: string]: { deltaPosition: Point } };

  radians?: number;
  asAbsolute?: boolean;

  constructor(
    dispatch: AppDispatch,
    useSelector: UseSelector,
    selection: SvgGroup[],
    initialPointerScreenPosition?: Point,
    handle?: string
  ) {
    super(dispatch, useSelector);

    const viewport = useSelector(selectViewport);
    const selectionBounds = useSelector(selectSelectionBounds) as SelectionBox;

    const initialInteractionPosition = transform(
      initialPointerScreenPosition,
      viewport.screenToCanvasTransform
    );

    this.selection = selection;

    this.rotationOrigin = selectionBounds.anchor;
    this.startingAngle = Math.atan2(
      initialInteractionPosition.y - this.rotationOrigin.y,
      initialInteractionPosition.x - this.rotationOrigin.x
    );

    this.rotatingAnchor = handle;

    // ROTATION: We need to get the correct anchor point
    // to rotate around
    //JH 7/12/22 - I modified SelectionBoxHelper to account for rotation when computing selectionBounds.anchor
    const rotateAround = { ...selectionBounds.anchor };

    this.selectionGroupCenter = rotateAround;

    this.selectionCenterMtx = translate(
      createMatrix33(),
      this.selectionGroupCenter
    );

    this.invSelectionCenterMtx = invert(this.selectionCenterMtx);

    this.rotationMtx = createMatrix33();
    this.initialRotationMtx = createRotationMtx(selectionBounds.rotation);
    this.startingRotation = selectionBounds.rotation * RAD_TO_DEG;

    this.initialVector = subtract(
      initialInteractionPosition,
      this.selectionGroupCenter
    );
    this.viewport = viewport;

    this.changes = {};
  }

  setToRotation(radians: number) {
    const { useSelector } = this;
    this.radians = radians;
    this.asAbsolute = this.selection.length === 1;

    const selectionBounds = useSelector(selectSelectionBounds) as SelectionBox;
    const { rotation: current } = selectionBounds;
    const amount = -(current - radians);
    this.rotateByRadians(amount);
  }

  rotateByRadians(radians: number) {
    const mtx = createRotationMtx(radians);
    this.rotateByMatrix(mtx, { updateDOM: false });
  }

  rotateByRadiansWithGroup(radians: number, group: SvgGroup) {
    const mtx = createRotationMtx(radians);
    this.rotateByMatrix(mtx, { updateDOM: false, group });
  }

  // applies translations and updated existing layers
  rotateBy(interactionPointScreen: Point, constrainRotation: boolean) {
    const { useSelector } = this;

    const viewport = useSelector(selectViewport);

    //Get interaction point in canvas space
    const interactionPointCanvas = transform(
      interactionPointScreen,
      viewport.screenToCanvasTransform
    );
    const currentAngle = Math.atan2(
      interactionPointCanvas.y - this.rotationOrigin.y,
      interactionPointCanvas.x - this.rotationOrigin.x
    );
    let angle = currentAngle - this.startingAngle;
    let degrees = (180 / Math.PI) * angle;

    // check for snapping - compare this to the actively
    // displayed rotation and not relative to how much
    // rotation has been applied
    if (constrainRotation) {
      const activeRotation = degrees + this.startingRotation;

      // calculate offsets
      const offset = Math.abs(activeRotation % 45);
      const invertVal = activeRotation < 0 ? -1 : 1;
      const shift = (offset > 45 / 2 ? 45 - offset : -offset) * invertVal;

      // only shift if within 5 degrees of a 45 degree angle
      if (Math.abs(shift) < 5) {
        degrees += shift;
      }
    }

    const rotationMtx = createRotationMtx((Math.PI / 180) * degrees);
    this.rotateByMatrix(rotationMtx);
  }

  // apply rotation transforms
  rotateByMatrix(
    rotationMtx: Matrix33,
    options: RotateByMatrixOptions = {
      updateDOM: true,
    }
  ) {
    const { updateDOM, group } = options;
    const { useSelector } = this;
    const getGroupById = useSelector(selectGetGroupById);

    const selectedLine = this.useSelector(selectSelectedLine);
    const mostRecentlyMovedPoint = this.useSelector(
      selectMostRecentlyMovedPoint
    );
    let { selectionCenterMtx: center, invSelectionCenterMtx: invCenter } = this;
    const ui =
      updateDOM &&
      UIState.update({
        rotate: 0,
        groups: {},
      });

    // special rules for two points
    if (selectedLine && mostRecentlyMovedPoint) {
      const other = selectedLine.find((id) => id !== mostRecentlyMovedPoint);
      const point = this.selection.find(({ id }) => id === other)!;
      center = translate(createMatrix33(), point.position);
      invCenter = invert(center);
    }

    const rotationAngle = getRotationAngleFromMtx(rotationMtx);

    const selectionCenterTransformMtx = multiplyMatrix33(
      center,
      rotationMtx,
      invCenter
    );

    if (ui && this.selection.length > 1) {
      ui.isMultiSelection = true;
      ui.rotation = rotationAngle;
    }

    this.changes = {};

    // apply changes to each group
    const isSingleSelection = this.selection.length === 1;
    for (const selectedGroup of this.selection) {
      const groupId = selectedGroup.id;
      const reloadedSelection = group ?? getGroupById(groupId);
      if (
        groupId === undefined ||
        groupId === null ||
        reloadedSelection === null ||
        this.changes[groupId]
      ) {
        continue;
      }

      //  rotate position around selection center to get position offset
      const rotatedPositionDelta = subtract(
        transform(reloadedSelection.position, selectionCenterTransformMtx),
        reloadedSelection.position
      );

      // notify the UI update, if needed
      if (ui) {
        ui.rotate = rotationAngle;

        ui.translate = {
          x: isSingleSelection ? rotatedPositionDelta.x : 0,
          y: isSingleSelection ? rotatedPositionDelta.y : 0,
        };

        ui.groups[groupId] = {
          rotate: rotationAngle,
          translate: {
            x: rotatedPositionDelta.x,
            y: rotatedPositionDelta.y,
          },
        };

        ui.rotatingAnchor = this.rotatingAnchor;
      }

      // track the delta change
      this.changes[groupId] = {
        deltaPosition: rotatedPositionDelta,
      };
    }

    // update the UI, if needed
    if (ui) {
      ui.apply();

      // make the rotation cursor match
      const startingRotation = getDegreesFromMtx(this.initialRotationMtx);
      const currentRotation = getDegreesFromMtx(selectionCenterTransformMtx);

      updateRotationHandleCursorAngle(currentRotation + startingRotation);
    }

    // save the current rotation mtx
    this.rotationMtx = rotationMtx;
  }

  // creates a manifest of changes
  resolve() {
    const { rotationMtx, changes, radians, asAbsolute } = this;
    const getPointUpdates = this.useSelector(
      selectGetPointChangesFromUpdateSet
    );

    // reset rotation, if needed
    // when it's a group, clear the rotation
    if (this.selection.length > 1) {
      updateRotationHandleCursorAngle(0);
    }

    // clear UI changes
    UIState.reset();

    //Angle in radians
    const rotationAngle = getRotationAngleFromMtx(rotationMtx);

    // check for point changes
    const { points } = getPointUpdates(changes, {
      mutateChangeSet: true,
      src: 'deltaPosition',
    });

    // apply all changes
    const updates = Object.keys(changes).reduce(
      (obj, key) => ({
        ...obj,
        [key]: {
          deltaPosition: changes[key].deltaPosition,
          ...(asAbsolute
            ? {
                setRotation: radians || 0,
              }
            : {
                deltaRotation: rotationAngle,
              }),
        },
      }),
      {} as {
        [key: string]: {
          deltaPosition: Point;
        } & (
          | {
              deltaRotation: number;
              setRotation?: undefined;
            }
          | {
              deltaRotation?: undefined;
              setRotation: number;
            }
        );
      }
    );

    const updateBatch = Object.keys(updates).map(
      (groupChangeKey): UpdateSvgGroupPayload => ({
        id: groupChangeKey,
        update: {
          key: SvgGroupUpdateKey.RotationRelativePosition,
          value: updates[groupChangeKey],
        } as SvgGroupUpdate,
      })
    );

    Object.keys(points).forEach((point) => {
      updateBatch.push({
        id: point,
        update: {
          key: SvgGroupUpdateKey.AbsolutePosition,
          value: points[point] as Point,
        } as SvgGroupUpdate,
      });
    });

    // apply the update
    if (Object.keys(updateBatch).length) {
      const update = this.createAction(UpdateSvgGroupAction);
      update.apply(updateBatch);
    }
  }

  resolveWithoutUpdate() {
    const { rotationMtx, changes, radians, asAbsolute } = this;

    // reset rotation, if needed
    // when it's a group, clear the rotation
    if (this.selection.length > 1) {
      updateRotationHandleCursorAngle(0);
    }

    // clear UI changes
    UIState.reset();

    //Angle in radians
    const rotationAngle = getRotationAngleFromMtx(rotationMtx);

    // apply all changes
    const updates = Object.keys(changes).reduce(
      (obj, key) => ({
        ...obj,
        [key]: {
          deltaPosition: changes[key].deltaPosition,
          ...(asAbsolute
            ? {
                setRotation: radians || 0,
              }
            : {
                deltaRotation: rotationAngle,
              }),
        },
      }),
      {} as {
        [key: string]: {
          deltaPosition: Point;
        } & (
          | {
              deltaRotation: number;
              setRotation?: undefined;
            }
          | {
              deltaRotation?: undefined;
              setRotation: number;
            }
        );
      }
    );

    const updateBatch = Object.keys(updates).map(
      (groupChangeKey): UpdateSvgGroupPayload => ({
        id: groupChangeKey,
        update: {
          key: SvgGroupUpdateKey.RotationRelativePosition,
          value: updates[groupChangeKey],
        } as SvgGroupUpdate,
      })
    );

    return updateBatch;
  }
}
