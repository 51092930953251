import { convertUnitNumToMM } from './UnitOps';
import { add } from './PointOps';
import {
  getSizeAndCenterFromAABB,
  getAABBSize,
  isPointInAABB as originalIsPointInAABB,
  doAABBsIntersect,
  getAABBBounds,
  mergeAABBArray,
} from './AABBOps';
import { createPathAndTestClosed } from './PathOps';

import { getCutEncoding } from './SvgCutSettingImport';
import { AABB } from '@shapertools/sherpa-svg-generator/AABB';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import {
  isSourceSvgObject,
  Path,
} from '@shapertools/sherpa-svg-generator/Path';
import { BasePath } from '@shapertools/sherpa-svg-generator/BasePath';
import {
  closePath,
  transform as basePathTransform,
  updateSvgSourceTransform,
} from './BasePathOps';
import {
  Matrix33,
  multiplyMatrix33,
  createMatrix33,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import {
  CutParams,
  CutType,
} from '@shapertools/sherpa-svg-generator/CutParams';
import init, {
  js_add_font,
  js_get_font_faces,
  js_init_svg_parser,
  js_is_text_supported_by_font,
  js_font_families_used_to_shape_text,
  js_process_svg_str_to_usvg_str,
} from '@shapertools/bullet_wasm';
import { getTessellatedPathPointsFromUSVGElement } from './Tessellation';
import { repairPaths } from './SvgGroupOps';

class SvgOpsSingleton {
  parser: DOMParser;
  svgNode: Node | null;
  isInit: boolean = false;

  constructor() {
    this.parser = new DOMParser();

    //document node for processing svg
    this.svgNode = null;
  }

  async doInit() {
    try {
      await init();
      js_init_svg_parser();
      this.isInit = true;
    } catch {
      this.isInit = false;
    }
  }

  initShapeParser(domId: string) {
    this.svgNode = document.getElementById(domId);
  }

  addFontToSvgParser(fontData: Uint8Array, isSingleLine: boolean) {
    if (this.isInit) {
      js_add_font(fontData, isSingleLine);
    }
  }

  getSvgDomFromString(svgString: string) {
    return this.parser.parseFromString(svgString, 'image/svg+xml');
  }

  getSvgRootNode(svgDom: Document) {
    return [...svgDom.childNodes].find((n) => n.nodeName === 'svg');
  }

  getSvgViewBox(svgRootNode: ChildNode) {
    const svgNode = svgRootNode as Element;
    const viewBoxStr = svgNode.getAttribute('viewBox') || '';

    const viewBoxVals = viewBoxStr.split(' ').map((str: string) => Number(str));

    const areValsNaN = viewBoxVals.reduce(
      (result, val) => (result || isNaN(val) ? true : false),
      false
    );

    if (viewBoxVals.length !== 4 || areValsNaN) {
      throw new Error('Error - no viewBox found ');
    }

    return new AABB({
      minPoint: new Point(viewBoxVals[0], viewBoxVals[1]),
      maxPoint: new Point(
        viewBoxVals[0] + viewBoxVals[2],
        viewBoxVals[1] + viewBoxVals[3]
      ),
    });
  }

  getRootTransform(svgViewBox: AABB, svgSize: Point): Matrix33 {
    const { size, centerPosition } = getSizeAndCenterFromAABB(svgViewBox);
    if (size.x === 0) {
      return [
        [1, 0, -centerPosition.x],
        [0, svgSize.y / size.y, (-centerPosition.y * svgSize.y) / size.y],
        [0, 0, 1],
      ];
    }
    if (size.y === 0) {
      return [
        [svgSize.x / size.x, 0, (-centerPosition.x * svgSize.x) / size.x],
        [0, 1, -centerPosition.y],
        [0, 0, 1],
      ];
    }

    return [
      [svgSize.x / size.x, 0, (-centerPosition.x * svgSize.x) / size.x],
      [0, svgSize.y / size.y, (-centerPosition.y * svgSize.y) / size.y],
      [0, 0, 1],
    ];
  }

  getTransformedSimplifiedTessellatedPathPoints(
    el: SVGPathElement,
    clipBox: AABB,
    forceOpenPaths: boolean
  ) {
    const { pts, nodeTransform } = getTessellatedPathPointsFromUSVGElement(
      el,
      clipBox,
      forceOpenPaths
    );
    if (pts.length >= 2) {
      const path = new Path({
        points: pts,
        ...(forceOpenPaths ? { closed: false } : { testClosed: true }),
      });
      return { path, nodeTransform };
    }

    return { path: null };
  }

  clipPathAgainstAABB(path: Path, origClipAABB: AABB, eps = 1) {
    const clipMinPoint = add(origClipAABB.minPoint, new Point(-eps, -eps));
    const clipMaxPoint = add(origClipAABB.maxPoint, new Point(eps, eps));

    const clipAABB = new AABB({
      minPoint: clipMinPoint,
      maxPoint: clipMaxPoint,
    });

    //Convenience wrapper
    const isPointInAABB = function (point: Point) {
      return originalIsPointInAABB(clipAABB, point);
    };

    class Segment {
      p0: Point;
      p1: Point;

      constructor(p0: Point, p1: Point) {
        this.p0 = p0;
        this.p1 = p1;
      }

      isSegmentCrossingAABB() {
        //Segment crosses AABB boundary iff:
        //1) segment AABB overlaps clipAABB
        //AND
        //2) at least one segment endpoint is outside clipAABB

        const endPointOutside = !(
          isPointInAABB(this.p0) && isPointInAABB(this.p1)
        );
        const segmentAABB = new AABB({
          points: [this.p0, this.p1],
        });
        return endPointOutside && doAABBsIntersect(clipAABB, segmentAABB);
      }

      interpolateSegment(dimension: string, s: number) {
        const dy = this.p1.y - this.p0.y;
        const dx = this.p1.x - this.p0.x;

        if (dimension === 'X') {
          const intY = (dy / dx) * (s - this.p0.x) + this.p0.y;
          return new Point(s, intY);
        }

        if (dimension === 'Y') {
          const intX = (dx / dy) * (s - this.p0.y) + this.p0.x;
          return new Point(intX, s);
        }
      }

      intersectSegmentWithAABB() {
        const { left, right, top, bottom } = getAABBBounds(clipAABB);
        const equalX = this.p0.x === this.p1.x;
        const equalY = this.p0.y === this.p1.y;

        if (
          !equalX &&
          ((this.p0.x <= left && this.p1.x >= left) ||
            (this.p1.x <= left && this.p0.x >= left))
        ) {
          // crosses left
          return this.interpolateSegment('X', left);
        }

        if (
          !equalX &&
          ((this.p0.x <= right && this.p1.x >= right) ||
            (this.p1.x <= right && this.p0.x >= right))
        ) {
          // crosses right
          return this.interpolateSegment('X', right);
        }

        if (
          !equalY &&
          ((this.p0.y <= top && this.p1.y >= top) ||
            (this.p1.y <= top && this.p0.y >= top))
        ) {
          // crosses top
          return this.interpolateSegment('Y', top);
        }

        if (
          !equalY &&
          ((this.p0.y <= bottom && this.p1.y >= bottom) ||
            (this.p1.y <= bottom && this.p0.y >= bottom))
        ) {
          // crosses bottom
          return this.interpolateSegment('Y', bottom);
        }
      }
    }

    const clippedPaths = [];
    let currentPathPts: Point[] = [];

    let pathInsideState = isPointInAABB(path.points[0]);

    //Walk path from beginning
    //Generate segments
    //If current segment is inside clipAABB, add current point (segment.p0) to pointBuffer if inside clipAABB
    //If current segment is outside clipAABB, advance to next segment
    //If segment crosses clipAABB from inside to outside, clip, add segment.p0 and intersectPt to pointBuffer, and end current path
    //If segment crosses clipAABB from outside to inside, clip and start new path with clipped segment
    for (let idx = 0; idx < path.points.length; idx++) {
      const thisPt = path.points[idx];
      let nextPt;
      const pathEnd = idx === path.points.length - 1;
      if (pathEnd) {
        if (path.closed) {
          nextPt = path.points[0];
        } else {
          break;
        }
      } else {
        nextPt = path.points[idx + 1];
      }

      const currentSegment = new Segment(thisPt, nextPt);
      const segmentCrossing = currentSegment.isSegmentCrossingAABB();

      if (segmentCrossing) {
        if (pathInsideState) {
          //Path is going from inside to outside
          const endPoint = currentSegment.intersectSegmentWithAABB();
          if (endPoint) {
            currentPathPts.push(thisPt, endPoint);
          }
          clippedPaths.push(createPathAndTestClosed(currentPathPts));

          pathInsideState = false;
        } else {
          //Path is going from outside to inside
          const startPoint = currentSegment.intersectSegmentWithAABB();
          if (startPoint) {
            currentPathPts = [startPoint];
          }
          pathInsideState = true;
        }
      } else {
        if (pathInsideState) {
          currentPathPts.push(thisPt);
        }
      }
    }

    //Done looping, but need to close out the last unfinished path if we finished inside the clipAABB
    if (pathInsideState) {
      if (path.closed) {
        //Last path pt is the input path's first point repeated
        currentPathPts.push(path.points[0]);
      } else {
        //Last path pt is the input path's last point
        currentPathPts.push(path.points[path.points.length - 1]);
      }
      //Regardless, add these points as a new path to the output set
      clippedPaths.push(createPathAndTestClosed(currentPathPts));
    }

    return clippedPaths;
  }

  getClippedTransformedSimplifiedTessellatedPathPoints(
    el: SVGPathElement,
    clipBox: AABB,
    forceOpenPaths: boolean
  ) {
    const { path, nodeTransform } =
      this.getTransformedSimplifiedTessellatedPathPoints(
        el,
        clipBox,
        forceOpenPaths
      );
    if (path === null) {
      return { paths: null };
    }

    //If tp.AABB outside viewbox, discard
    //If tp.AABB completely inside VB, add
    //If partly in and out, then clip

    const pathAtLeastPartiallyInsideVB = doAABBsIntersect(
      path.AABB,
      clipBox,
      0.000001
    );
    const pathEntirelyInsideVB =
      pathAtLeastPartiallyInsideVB &&
      getAABBBounds(path.AABB).points.reduce(
        (insideAcc, pt) =>
          originalIsPointInAABB(clipBox, pt, 0.000001) && insideAcc,
        true
      );

    switch (true) {
      case pathEntirelyInsideVB:
        return { paths: [path], nodeTransform };

      case pathAtLeastPartiallyInsideVB:
        return {
          paths: this.clipPathAgainstAABB(path, clipBox),
          nodeTransform,
        };

      default:
        return { paths: null };
    }
  }
  /*
    Finds vector from (0,0) in SVG viewBox to center point of the viewBox. This vector is scaled to mm.

    This is used for importing Origin workpieces. Currently, Origin exports workpiece SVGs such the grid origin is at (0,0) in the SVG viewbox. This vector is used to offset the default positioning of the imported SVG so that the grid origin is at the Studio origin after the centeringTransform is applied. This offset is applied only to SVGs marked as being origin workpieces, so other SVG imports should not be affected by this.

    Note that viewbox 0,0 is not necessarily inside the AABB of the viewBox itself. For example, a workpiece may have all its shapes above and to the right of the grid origin.

  */
  getViewBoxCenterOffset(pathsAABB: AABB, svgSizeMm: Point) {
    //Dims in pixels
    const { x: width, y: height } = pathsAABB.size;
    const { x: xMin, y: yMin } = pathsAABB.minPoint;
    const xMid = xMin + width / 2;
    const yMid = yMin + height / 2;

    // Convert to mm
    const xMidMm = (xMid * svgSizeMm.x) / width;
    const yMidMm = (yMid * svgSizeMm.y) / height;
    return new Point(xMidMm, yMidMm);
  }

  convertSvgStrToPaths(
    rawSvgString: string,
    forceOpenPaths: boolean = false,
    originWorkpiece: boolean = false,
    repair: boolean = false
  ) {
    if (this.isInit) {
      try {
        const usvgString = this.getUsvgString(rawSvgString);

        const svgDom = this.getSvgDomFromString(usvgString);

        const svgRoot = this.getSvgRootNode(svgDom);
        if (svgRoot) {
          //Attach svgRoot to browser DOM to enable path API
          this.svgNode?.appendChild(svgRoot);

          const svgViewBox = this.getSvgViewBox(svgRoot);
          const nodeStack = [...svgRoot.childNodes];

          let cutPaths = [] as BasePath[];

          const pathRe = /[Mm][^Mm]+/gm;

          //Walk DOM until queue is empty
          while (nodeStack.length > 0) {
            let thisNode = nodeStack.pop() as SVGPathElement;

            //If path node and more than one path in statement, split into multiple nodes, and add new nodes to parent and to stack, each representing a single path.
            if (thisNode && thisNode.nodeName === 'path') {
              let pathData = thisNode.getAttribute('d');
              let pathSegData = pathData?.match(pathRe);

              if (pathSegData && pathSegData.length > 1) {
                //Need to split current path node into multiple nodes

                //First save the current node's transformation so that it can be copied to new nodes.
                const currentNodeSvgTransform =
                  thisNode.transform?.baseVal.consolidate();
                // eslint-disable-next-line guard-for-in
                for (const idx in pathSegData) {
                  if (idx === '0') {
                    thisNode.setAttribute('d', pathSegData[0]);
                    continue;
                  }
                  const newPathNode = document.createElementNS(
                    'http://www.w3.org/2000/svg',
                    'path'
                  );
                  newPathNode.setAttribute('d', pathSegData[idx]);

                  //Copy saved transformation to the newPathNode
                  if (currentNodeSvgTransform !== null) {
                    newPathNode?.transform?.baseVal.initialize(
                      currentNodeSvgTransform
                    );
                  }
                  thisNode.parentNode?.appendChild(newPathNode);
                  nodeStack.push(newPathNode);
                }
              }
            }

            //usvg bakes the entire transform chain down to a single transform on the terminal node, so all we need is the nodeTransform and the rootTransform to center the svg
            const { paths: newPaths, nodeTransform } =
              this.getClippedTransformedSimplifiedTessellatedPathPoints(
                thisNode,
                svgViewBox,
                forceOpenPaths
              ); //Returns path objects only, no simplePolys

            //Even after splitting pathData into multiple path nodes, each node may STILL generate multiple paths due to clipping
            //If a node produces multiple paths, we can't easily preserve the link between incoming source svg and tessellated path data.
            //This means we have to generate export svg from the tessellated geometry, not the original source SVG, and will incur a loss of quality
            //One alternative would be to cache source SVG for the entire group, but then we would lose the ability to pass on cut path encoding.
            //Another alternative would be to add a SVG <clippath> matching the clipping window for each subpath produced by clipping, but <clippath> is not supported on Origin and is supposed to be applied at the raster level anyways.
            //At any rate, any paths that need repair will likely also lose sourceSvg data too, because it's tricky enough to repair geometry without having to modify SVG data to match.
            if (newPaths !== null) {
              const sourceSvgData = (() => {
                if (newPaths.length === 1) {
                  const str = thisNode.getAttribute('d');
                  if (str) {
                    return {
                      svg: forceOpenPaths ? str.replaceAll(/(z|Z)/g, '') : str,
                      sourceTransform: nodeTransform || createMatrix33(),
                    };
                  }
                }
                return undefined;
              })();
              let cutParams;
              try {
                cutParams = getCutEncoding(thisNode, newPaths, originWorkpiece);
              } catch (e) {
                console.log('cut params encoding failed: ', e);
                cutParams = new CutParams();
              }

              // eslint-disable-next-line no-loop-func
              newPaths.forEach((p) => {
                const finalCutParams = p.closed
                  ? cutParams
                  : new CutParams({ ...cutParams, cutType: CutType.ONLINE });

                cutPaths.push(
                  new BasePath({
                    ...p,
                    cutParams: finalCutParams,
                    sourceSvg: sourceSvgData,
                  })
                );
              });
            }

            nodeStack.push(...thisNode.childNodes);
          }

          cutPaths = repair ? repairPaths(cutPaths) : cutPaths;

          //Remove svgRoot from document DOM
          svgRoot.remove();

          //Each svg is transformed so it is centered at 0,0 in it's local coordinate space
          const pathsAABB = mergeAABBArray(cutPaths.map((cp) => cp.AABB));
          const svgSize = this.getSvgSize(
            svgRoot as SVGSVGElement,
            svgViewBox,
            pathsAABB
          );

          const importPosition = this.getViewBoxCenterOffset(
            pathsAABB,
            svgSize
          );

          const centeringTransform = this.getRootTransform(pathsAABB, svgSize);
          cutPaths = cutPaths.map((cp) => {
            const sourceTransform = isSourceSvgObject(cp.sourceSvg)
              ? cp.sourceSvg.sourceTransform
              : createMatrix33();
            const newMatrix = multiplyMatrix33(
              centeringTransform,
              sourceTransform
            );
            const transformedPath = basePathTransform(cp, centeringTransform);

            if (isSourceSvgObject(cp.sourceSvg)) {
              if (forceOpenPaths) {
                return updateSvgSourceTransform(transformedPath, newMatrix);
              }

              return closePath(
                updateSvgSourceTransform(transformedPath, newMatrix)
              ) as BasePath;
            }
            return closePath(transformedPath) as BasePath;
          });
          return { cutPaths, svgSize, importPosition };
        }
        return { cutPaths: [], svgSize: null, importPosition: null };
      } catch (e) {
        console.log('e: ', e);
        return { cutPaths: [], svgSize: null, importPosition: null };
      }
    }
    return { cutPaths: [], svgSize: null, importPosition: null };
  }

  //Gets unit from dimStr
  //Returns null if unit is relative unit like %, em, ex, etc.
  getUnitFromDimensionStr(dimStr: string) {
    let unitMatches = dimStr.match(/\d+(?<unit>[a-zA-Z]{1,2})/);

    if (unitMatches === null || unitMatches.length === 0) {
      return null;
    }

    switch (unitMatches.groups?.unit) {
      case 'cm':
      case 'mm':
      case 'Q':
      case 'in':
      case 'pc':
      case 'pt':
      case 'px':
        return unitMatches.groups.unit;

      //Must be a relative unit or random garbage
      default:
        return null;
    }
  }

  // generates a bounding box rectangle from bounds
  createSvgGroupContainer(bounds: AABB) {
    const width = bounds.maxPoint.x - bounds.minPoint.x;
    const height = bounds.maxPoint.y - bounds.minPoint.y;
    return `<rect class="svg-group-container" width="${width}" height="${height}" x="-${
      width / 2
    }" y="-${height / 2}" />`;
  }

  getViewboxSizeMm(svgRootNode: SVGSVGElement) {
    //These are the dimensions provided in the svg root, which map viewbox values to units
    const rawWidth = svgRootNode.getAttribute('width') || '';
    const rawHeight = svgRootNode.getAttribute('height') || '';

    const widthUnit = this.getUnitFromDimensionStr(rawWidth);
    const heightUnit = this.getUnitFromDimensionStr(rawHeight);

    if (
      widthUnit === null ||
      heightUnit === null ||
      widthUnit !== 'mm' ||
      heightUnit !== 'mm'
    ) {
      throw new Error("usvg output doesn't include mm units: ");
    }

    const viewBoxMmWidth = convertUnitNumToMM(rawWidth);
    const viewBoxMmHeight = convertUnitNumToMM(rawHeight);
    return new Point(viewBoxMmWidth, viewBoxMmHeight);
  }

  //Converts viewbox units to mm e.g. viewbox="0 0 100 100" and size = (1in, 0.5in), then returns (.254, .127)
  getViewboxUnitsMm(viewBoxSizeMm: Point, svgViewBox: AABB) {
    const { size: viewBoxSize } = getSizeAndCenterFromAABB(svgViewBox);

    //Converts viewbox units to mm
    const viewBoxMmX =
      viewBoxSize.x !== 0 ? viewBoxSizeMm.x / viewBoxSize.x : 0;
    const viewBoxMmY =
      viewBoxSize.y !== 0 ? viewBoxSizeMm.y / viewBoxSize.y : 0;

    return new Point(viewBoxMmX, viewBoxMmY);
  }

  getSvgSize(
    svgRootNode: SVGSVGElement,
    svgViewBox: AABB,
    clippedPathsAABB: AABB | null = null
  ) {
    const { x: viewBoxMmX, y: viewBoxMmY } = this.getViewboxUnitsMm(
      this.getViewboxSizeMm(svgRootNode),
      svgViewBox
    );

    //Actual size of the svg content, excluding margin between svg content and viewbox boundaries, in viewBox units
    let svgContentWidth, svgContentHeight;

    if (clippedPathsAABB !== null) {
      const { x, y } = getAABBSize(clippedPathsAABB);
      svgContentWidth = x;
      svgContentHeight = y;
    } else {
      const { width, height } = svgRootNode.getBBox();
      svgContentWidth = width;
      svgContentHeight = height;
    }
    return new Point(
      viewBoxMmX * svgContentWidth,
      viewBoxMmY * svgContentHeight
    );
  }

  getUsvgString(
    rawSvgString: string,
    options: { forceOpenPaths: boolean } = { forceOpenPaths: false }
  ) {
    const usvgString = js_process_svg_str_to_usvg_str(rawSvgString);
    if (options.forceOpenPaths) {
      // instead of going through the more thorough logic of creating a create a proper BasePath, this
      // will just remove all the commands to close the path (aka the Z command).
      return usvgString.replaceAll(/(z|Z)/g, '');
    }
    return usvgString;
  }

  isTextSupportedByFont(text: string, fontFamily: string) {
    return js_is_text_supported_by_font(text, fontFamily);
  }

  getFontsUsedToShapeText(text: string, fontFamily: string): String[] {
    return js_font_families_used_to_shape_text(text, fontFamily);
  }

  getLoadedFontNames() {
    return js_get_font_faces();
  }
}

const SvgOps = new SvgOpsSingleton();

export { SvgOps };
