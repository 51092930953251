import BaseAction from './BaseAction';

// consts
import { RESIZE_HANDLES } from '@/Constants/UI';

// helpers
import {
  invert,
  createScaleMtx,
  createMatrix33,
  multiplyMatrix33,
} from '@shapertools/sherpa-svg-generator/Matrix33';
import { transform } from '@/Geometry/PointOps';
import SelectionBoxOps from '@/Helpers/SelectionBoxHelper';
import { PATH_TYPES } from '@shapertools/sherpa-svg-generator/PathTypes';
import { rotateAroundPoint } from '@/Utility/rotation';

// ui
import UIState from '@/UILayer/State/UIState';

// selectors
import {
  selectSelectionBounds,
  selectActiveAnchor,
} from '@/Redux/Slices/SelectionSlice';
import { selectViewport } from '@/Redux/Slices/ViewportSlice';
import { selectDisplayUnits } from '@/Redux/Slices/SherpaContainerSlice';

// actions
import UpdateSvgGroupAction from '@/Actions/UpdateSvgGroup';
import { selectSelectedGroups } from '@/Redux/Slices/SelectionSlice';
import { Point } from '@shapertools/sherpa-svg-generator/Point';
import { AppDispatch } from '@/Redux/store';
import { UseSelector } from './useAction';
import {
  isToolForShape,
  Shape,
  SvgGroup,
  Tool,
} from '@shapertools/sherpa-svg-generator/SvgGroup';

import AlignmentHelper from '@/Helpers/Alignment';
import { Anchor, DisplayUnits, Handle } from '@/@types/shaper-types';
import { SvgGroupUpdate, SvgGroupUpdateKey } from '@/Geometry/SvgGroupOps';
import { isCenterAnchor } from './Mirror';
import SelectionBox from '@/Helpers/SelectionBoxHelper';
import { createSvgForTool, getShearedRectAABB } from '@/Helpers/ShapeCreator';
import { UpdateSvgGroupPayload } from '@/CanvasContainer/CanvasActions';

type Options = {
  alignmentHelper: AlignmentHelper | null;
  applyTemporaryTranslations: boolean;
};

// handles performing a resize for groups
export default class ResizeGroupsAction extends BaseAction {
  static isAllowedHandle(handle: Handle) {
    return RESIZE_HANDLES.includes(handle);
  }

  selection: SvgGroup[];
  alignmentHelper: AlignmentHelper | null;
  selectionBounds: SelectionBox;
  displayUnits: DisplayUnits;
  options: Options;
  startingWidth: number;
  startingHeight: number;
  startingHandle?: Anchor;
  anchor: Anchor;
  resizeFromCenter: boolean;
  handle?: Anchor;
  lockWidth: boolean;
  lockHeight: boolean;
  didStartWithCornerResize: boolean;
  isResizeFromTop: boolean;
  isResizeFromLeft: boolean;
  isResizeFromCorner: boolean;
  includeHorizontal: boolean;
  includeVertical: boolean;
  resizeOrigin: string | undefined;
  changes: { [key: string]: SvgGroupUpdate } = {};

  constructor(
    dispatch: AppDispatch,
    useSelector: UseSelector,
    selectedGroups: SvgGroup[],
    handle: Anchor,
    options: Options = {
      alignmentHelper: null,
      applyTemporaryTranslations: true,
    }
  ) {
    super(dispatch, useSelector);
    const bounds = useSelector(selectSelectionBounds) as SelectionBox;
    const displayUnits = useSelector(selectDisplayUnits);

    this.selection = selectedGroups || [];
    this.alignmentHelper = options.alignmentHelper;
    this.selectionBounds = bounds;
    this.displayUnits = displayUnits;
    this.options = options;

    // save starting values
    this.startingWidth = bounds.width;
    this.startingHeight = bounds.height;

    // determine resize origin and handles
    let resizeFrom: Anchor | undefined = handle;

    // lock resizing relative to the anchor used
    let anchor = useSelector(selectActiveAnchor);
    if (anchor === 'center') {
      anchor = 'centroid';
    }

    // check for the correct anchor to resize from
    if (anchor && !isCenterAnchor(anchor)) {
      resizeFrom = SelectionBoxOps.getInverseHandle(anchor) as Handle;
    }

    // update handle information
    this.startingHandle = handle;
    this.anchor = anchor;
    this.resizeFromCenter = ['center', 'centroid'].includes(anchor);
    this.handle = resizeFrom;

    // constraints determined by the grabbed handle
    this.lockHeight = SelectionBoxOps.isHorizontalHandle(handle);
    this.lockWidth = SelectionBoxOps.isVerticalHandle(handle);

    this.didStartWithCornerResize = SelectionBoxOps.isCornerHandle(
      this.startingHandle
    );
    this.isResizeFromLeft = SelectionBoxOps.isLeftSideHandle(resizeFrom);
    this.isResizeFromTop = SelectionBoxOps.isTopSideHandle(resizeFrom);
    this.isResizeFromCorner = SelectionBoxOps.isCornerHandle(resizeFrom);
    this.includeHorizontal = ['lm', 'rm', 'tr', 'br', 'tl', 'bl'].includes(
      resizeFrom as any
    );
    this.includeVertical = ['tm', 'bm', 'tr', 'br', 'tl', 'bl'].includes(
      resizeFrom as any
    );

    // highlight the opposite side
    this.resizeOrigin = SelectionBoxOps.getInverseHandle(resizeFrom);
  }

  // Find vector from transform origin to group's position
  getScaledPositionChange = (
    resizeOriginX: number,
    resizeOriginY: number,
    groupPositionX: number,
    groupPositionY: number,
    scaleX: number,
    scaleY: number
  ) => {
    const unscaledRelativeX = groupPositionX - resizeOriginX;
    const unscaledRelativeY = groupPositionY - resizeOriginY;

    //Now scale relative X and Y based and X and Y scales
    const scaledRelativeX = unscaledRelativeX * scaleX;
    const scaledRelativeY = unscaledRelativeY * scaleY;

    //Relative move of group's position by the difference between scaled and unscaled position
    const dx = scaledRelativeX - unscaledRelativeX;
    const dy = scaledRelativeY - unscaledRelativeY;

    return { x: dx, y: dy };
  };

  getResizeOrigin = () => {
    const {
      isResizeFromLeft: isLeft,
      isResizeFromTop: isTop,
      anchor,
      startingHandle: handle,
      selectionBounds,
    } = this;

    let verticalScaling = 1;
    let horizontalScaling = 1;
    let resizeFromHorizontalCenter = false;
    let resizeFromVerticalCenter = false;

    // for the center anchor, all sides are resized from
    if (!anchor || isCenterAnchor(anchor)) {
      resizeFromHorizontalCenter = resizeFromVerticalCenter = true;
    }
    // when not the center, make a few adjustments
    // to what edge is resized from the center
    else {
      (
        [
          ['lm', [], ['tm', 'tr'], false, true],
          ['tm', ['lm', 'bl'], [], true, false],
          ['rm', [], ['tm', 'tl'], false, true],
          ['bm', ['lm', 'tl'], [], true, false],
        ] as const
      ).forEach(
        ([
          edge,
          invertScalingX,
          invertScalingY,
          horizontalCenter,
          verticalCenter,
        ]) => {
          if (edge === anchor) {
            if (!handle || !edge.includes(handle)) {
              resizeFromHorizontalCenter = horizontalCenter;
              resizeFromVerticalCenter = verticalCenter;

              // check for scaling inversion
              if ((invertScalingX as readonly any[]).includes(handle)) {
                horizontalScaling = -1;
              }

              if ((invertScalingY as readonly any[]).includes(handle)) {
                verticalScaling = -1;
              }
            }
          }
        }
      );
    }

    let x = isLeft
      ? selectionBounds.centerPosition.x + selectionBounds.width / 2
      : selectionBounds.centerPosition.x - selectionBounds.width / 2;
    let y = isTop
      ? selectionBounds.centerPosition.y + selectionBounds.height / 2
      : selectionBounds.centerPosition.y - selectionBounds.height / 2;

    if (resizeFromHorizontalCenter) {
      x = selectionBounds.centerPosition.x;
    }

    if (resizeFromVerticalCenter) {
      y = selectionBounds.centerPosition.y;
    }

    return {
      x,
      y,
      verticalScaling,
      horizontalScaling,
      resizeFromHorizontalCenter,
      resizeFromVerticalCenter,
    };
  };

  resizeByAbsolute(
    newWidth: number,
    newHeight: number,
    lockAspectRatio: boolean
  ) {
    const { selectionBounds, useSelector } = this;
    const selectedGroups = useSelector(selectSelectedGroups);
    const cancelResolve = selectedGroups.some(
      (sg) => PATH_TYPES[sg.type].selectability === false
    );

    // establish the updated sizing
    function calculateResize(value: number, original: number, altAxis: number) {
      const ratio = value / original;
      const altValue = altAxis * (lockAspectRatio ? ratio : 1);
      return [value, altValue];
    }
    if (!cancelResolve) {
      // Apply size change to each group in selection. Most groups are resized by scaling, but rounded rectangles need to be regenerated to keep corner radius constant
      // let { width = params.size, height = params.size } = params;
      let width = newWidth;
      let height = newHeight;

      // when matching, it doesn't matter, but just use width
      if (width && height) {
        [width, height] = calculateResize(
          width,
          selectionBounds.width,
          selectionBounds.height
        );
      }
      // has a width, not a height
      else if (width && !height) {
        [width, height] = calculateResize(
          width,
          selectionBounds.width,
          selectionBounds.height
        );
      }
      // has a height, not a width
      else if (height && !width) {
        [height, width] = calculateResize(
          height,
          selectionBounds.height,
          selectionBounds.width
        );
      }
      // shouldn't happen
      else {
        console.error('Resize attempted with no width/height values');
        return;
      }

      // next, calculate the adjusted scale
      const ratioX = width / selectionBounds.width;
      const ratioY = height / selectionBounds.height;

      // perform the resize
      this.resizeByScale(ratioX, ratioY);
    }
  }

  getResizeOffset = (currentScaleX: number, currentScaleY: number) => {
    const resizeOrigin = this.getResizeOrigin();
    const shearGroups = this.selection.length > 1;

    //For each group in selection
    for (const selectedGroup of this.selection) {
      //shearRotMtx = scalingMtx * rotationMtx
      let scaledDPos = this.getScaledPositionChange(
        resizeOrigin.x,
        resizeOrigin.y,
        selectedGroup.position.x,
        selectedGroup.position.y,
        currentScaleX,
        currentScaleY
      );

      if (shearGroups) {
        return new Point(scaledDPos.x, scaledDPos.y);
      }

      // check for a delta when resizing from
      // different anchors
      const deltaPos = { ...scaledDPos };
      if (selectedGroup.rotation) {
        const [x, y] = rotateAroundPoint(
          0,
          0,
          deltaPos.x,
          deltaPos.y,
          -selectedGroup.rotation
        );
        deltaPos.x = x;
        deltaPos.y = y;

        return deltaPos;
      }
      return deltaPos;
    }
  };

  resizeByScale = (
    currentScaleX: number,
    currentScaleY: number,
    {
      uiUpdate = null,
    }: {
      uiUpdate?: any;
    } = {}
  ) => {
    // reset the changes
    this.changes = {};

    //only compute if there are valid changes (1 indicates no change)
    if (
      Math.abs(currentScaleX - 1) === 0 &&
      Math.abs(currentScaleY - 1) === 0
    ) {
      return;
    }

    // const { resizeFromCenter } = this;
    const scaleCanvasSpaceMtx = createScaleMtx(currentScaleX, currentScaleY);
    const resizeOrigin = this.getResizeOrigin();
    const shearGroups = this.selection.length > 1;

    //For each group in selection
    for (const selectedGroup of this.selection) {
      //TODO - update comment
      //Scaling is aligned with canvas axes, but individual groups may be rotated. Therefore, scaling is really a shear operation.

      //Generate scaling mtx
      //shearRotMtx = scalingMtx * rotationMtx
      let scaledDPos = this.getScaledPositionChange(
        resizeOrigin.x,
        resizeOrigin.y,
        selectedGroup.position.x,
        selectedGroup.position.y,
        currentScaleX,
        currentScaleY
      );

      let innerShearMtx;
      if (shearGroups) {
        // const {group} = selection;

        const shearRotMtx = multiplyMatrix33(
          scaleCanvasSpaceMtx,
          selectedGroup.rotateMtx
        );

        // But transform hierarchy is rotMtx * innerShearMtx. We need to extract innerShear from ShearRotMtx.
        // ShearRotMtx = RotMtx * innerShearMtx, so innerShear = invRotMtx * ShearRotMtx.  Apply innershear to group inside rot mtx. For changes, innerShearMtx is used to bake new paths.

        innerShearMtx = multiplyMatrix33(
          invert(selectedGroup.rotateMtx),
          shearRotMtx
        );

        // hold onto these changes for when the request is applied
        this.changes[selectedGroup.id] = {
          key: SvgGroupUpdateKey.RelativeStretchRelativePosition,
          value: {
            deltaPos: new Point(scaledDPos.x, scaledDPos.y),
            stretchMtx: innerShearMtx,
          },
        };
      } else {
        innerShearMtx = scaleCanvasSpaceMtx;

        // check for a delta when resizing from
        // different anchors
        const deltaPos = { ...scaledDPos };
        if (selectedGroup.rotation) {
          const [x, y] = rotateAroundPoint(
            0,
            0,
            deltaPos.x,
            deltaPos.y,
            -selectedGroup.rotation
          );
          deltaPos.x = x;
          deltaPos.y = y;
        }

        // apply the change
        this.changes[selectedGroup.id] = {
          key: SvgGroupUpdateKey.RelativeStretchRelativePosition,
          value: {
            deltaPos: new Point(deltaPos.x, deltaPos.y), // Resize single groups around center
            stretchMtx: scaleCanvasSpaceMtx, // Single groups are resized along shape axes, not canvas
          },
        };
      }

      // update the UI layer, if needed
      if (uiUpdate) {
        // include this in the UI update
        uiUpdate.groups[selectedGroup.id] = {
          translate: {
            x: scaledDPos.x,
            y: scaledDPos.y,
          },
          resize: innerShearMtx,
        };
      }
    }

    return this.changes;
  };

  // applies resize and updated existing layers
  resizeBy = (
    dxInteraction: number,
    dyInteraction: number,
    {
      snapToGrid,
      snapToObjects,
      disableSmartAlignment,
      disableConstrain = false /* resizeFromCenter = false */,
    }: {
      snapToGrid: boolean;
      snapToObjects: boolean;
      disableSmartAlignment: boolean;
      disableConstrain: boolean;
    }
  ) => {
    const { useSelector, resizeFromCenter } = this;
    const viewport = useSelector(selectViewport);
    const selectedGroups = useSelector(selectSelectedGroups);
    const cancelResolve = selectedGroups.some(
      (sg) => PATH_TYPES[sg.type].selectability === false
    );

    // reset the changes
    this.changes = {};

    if (!cancelResolve) {
      const ui = UIState.update({
        groups: {},
        resize: {
          x: 0,
          y: 0,
          origin: resizeFromCenter ? 'center' : this.resizeOrigin,
          fromNearX: this.isResizeFromLeft,
          fromNearY: this.isResizeFromTop,
          fromCenter: resizeFromCenter,
        },
      });

      // adjust interaction when resizing from the center
      let dxScreen = dxInteraction;
      let dyScreen = dyInteraction;

      // check for rotations
      const isSingleObjectWithRotation = this.selection[0].rotation;

      // check for alignment
      let guides;
      if (
        this.alignmentHelper &&
        !disableSmartAlignment &&
        !this.isResizeFromCorner &&
        !isSingleObjectWithRotation
      ) {
        const snap = this.alignmentHelper.update(dxScreen, dyScreen, {
          snapToGrid,
          snapToObjects,
        });
        [dxScreen, dyScreen] = snap.applyTo(dxScreen, dyScreen);

        guides = snap.guides;
      }

      const resizeOrigin = this.getResizeOrigin();

      if (resizeOrigin.resizeFromHorizontalCenter) {
        dxScreen *= 2;
      }

      if (resizeOrigin.resizeFromVerticalCenter) {
        dyScreen *= 2;
      }

      //Get selection box boundaries in the canvas space (mm units)
      const { selectionBounds } = this;

      //Set transform origin and locks
      const {
        isResizeFromLeft: isLeft,
        isResizeFromTop: isTop,
        lockWidth,
        lockHeight,
      } = this;

      //Convert dx/dy from screen space (pixels) to canvas (mm units)
      //This is a relative position, so only need scaling factors from screenToCanvasTransform, not translations.
      const dxCanvas = viewport.screenToCanvasTransform[0][0] * dxScreen;
      const dyCanvas = viewport.screenToCanvasTransform[1][1] * dyScreen;

      //Width and Height of unscaled selection bounds
      const selectionBoundsWidth = selectionBounds.width;
      const selectionBoundsHeight = selectionBounds.height;

      //When dragging right side, positive dx increases scale factor
      //When dragging left side, positive dx DECREASES scale factor
      //Change sign of scale factor accordingly
      let scaleFactorSideX = isLeft ? -1 : 1;

      //When dragging bottom side, positive dy increases scale factor
      //When dragging top side, positive dy DECREASES scale factor
      //Change sign of scale factor accordingly
      let scaleFactorSideY = isTop ? -1 : 1;

      // apply additional scaling
      scaleFactorSideY *= resizeOrigin.verticalScaling;
      scaleFactorSideX *= resizeOrigin.horizontalScaling;

      let currentScaleX, currentScaleY;
      const shouldConstrain =
        (this.didStartWithCornerResize || this.isResizeFromCorner) &&
        !disableConstrain;

      if (this.selection.length === 1) {
        //Single group resize - using rotated selection box
        const selectedGroup = this.selection[0];

        const isRoundedRectangle = isToolForShape(
          selectedGroup.tool,
          Shape.ROUNDED_RECT
        );

        // transform dxCanvas,dyCanvas to local group space
        const invRotateMtx = invert(selectedGroup.rotateMtx);
        const dPosLocal = transform(
          new Point(dxCanvas, dyCanvas),
          invRotateMtx
        );

        // determine currentScaleX/Y in local space
        const localScaleRatioWidth = dPosLocal.x / selectionBoundsWidth;
        const localScaleRatioHeight = dPosLocal.y / selectionBoundsHeight;

        // Constrained resizing is determined in the group's rotated local space, not the canvas space
        let localScaleRatioX, localScaleRatioY;
        if (shouldConstrain) {
          //If constrained, use the scaling factor from either width or height for both x and y scaling ratios.
          const useLocalX = Math.abs(dPosLocal.x) > Math.abs(dPosLocal.y);
          if (useLocalX) {
            localScaleRatioX = localScaleRatioWidth;
            localScaleRatioY = localScaleRatioWidth;
            scaleFactorSideY = scaleFactorSideX;
          } else {
            localScaleRatioX = localScaleRatioHeight;
            localScaleRatioY = localScaleRatioHeight;
            scaleFactorSideX = scaleFactorSideY;
          }
        } else {
          //If unconstrained
          localScaleRatioX = localScaleRatioWidth;
          localScaleRatioY = localScaleRatioHeight;
        }

        currentScaleX = lockWidth ? 1 : 1 + scaleFactorSideX * localScaleRatioX;
        currentScaleY = lockHeight
          ? 1
          : 1 + scaleFactorSideY * localScaleRatioY;

        // compute dx,dy from local params
        // For single group selection, group position is always centerPosition, which is 0,0 in local frame

        if (isFinite(currentScaleX) && isFinite(currentScaleY)) {
          const localResizeOriginX = resizeOrigin.x - selectedGroup.position.x;
          const localResizeOriginY = resizeOrigin.y - selectedGroup.position.y;

          const groupPosDeltaLocal = this.getScaledPositionChange(
            localResizeOriginX,
            localResizeOriginY,
            0,
            0,
            currentScaleX,
            currentScaleY
          );

          const scaleMtx = createScaleMtx(currentScaleX, currentScaleY);
          const groupPosDeltaCanvas = transform(
            new Point(groupPosDeltaLocal.x, groupPosDeltaLocal.y),
            selectedGroup.rotateMtx
          );

          ui.translate = {
            x: groupPosDeltaCanvas.x,
            y: groupPosDeltaCanvas.y,
          };

          // include this in the UI update
          ui.groups[selectedGroup.id] = {
            resize: scaleMtx,
            translate: {
              x: groupPosDeltaCanvas.x,
              y: groupPosDeltaCanvas.y,
            },
          };

          if (isRoundedRectangle) {
            const stretchedSize = getShearedRectAABB(
              selectedGroup.tool.params,
              scaleMtx,
              createMatrix33()
            );

            const shapeParams = new Tool(selectedGroup.tool.type, {
              ...selectedGroup.tool.params,
              width: stretchedSize.x,
              height: stretchedSize.y,
            });

            const generated = createSvgForTool(shapeParams);

            this.changes[selectedGroup.id] = {
              key: SvgGroupUpdateKey.ToolSvg,
              value: {
                tool: shapeParams,
                rawSVG: generated.svg,
                deltaPos: groupPosDeltaCanvas,
              },
            };
          } else {
            // save changes
            this.changes[selectedGroup.id] = {
              key: SvgGroupUpdateKey.RelativeStretchRelativePosition,
              value: {
                deltaPos: groupPosDeltaCanvas,
                stretchMtx: scaleMtx,
              },
            };
          }
        }
      } else {
        let scaleRatioX, scaleRatioY;
        const scaleRatioWidth = dxCanvas / selectionBoundsWidth;
        const scaleRatioHeight = dyCanvas / selectionBoundsHeight;

        if (shouldConstrain) {
          const useCanvasX = Math.abs(dxCanvas) > Math.abs(dyCanvas);
          if (useCanvasX) {
            scaleRatioX = scaleRatioWidth;
            scaleRatioY = scaleRatioWidth;
            scaleFactorSideY = scaleFactorSideX;
          } else {
            scaleRatioY = scaleRatioHeight;
            scaleRatioX = scaleRatioHeight;
            scaleFactorSideX = scaleFactorSideY;
          }
        } else {
          scaleRatioX = scaleRatioWidth;
          scaleRatioY = scaleRatioHeight;
        }

        currentScaleX = lockWidth ? 1 : 1 + scaleFactorSideX * scaleRatioX;
        currentScaleY = lockHeight ? 1 : 1 + scaleFactorSideY * scaleRatioY;

        this.resizeByScale(currentScaleX, currentScaleY, {
          uiUpdate: ui,
        });
      }

      if (isFinite(currentScaleX) && isFinite(currentScaleY)) {
        // bounding box info
        ui.alignmentGuides = guides;
        ui.resize.changedHorizontal = currentScaleX !== 1;
        ui.resize.changedVertical = currentScaleY !== 1;
        ui.resize.x = currentScaleX;
        ui.resize.y = currentScaleY;

        // apply the ui update
        ui.apply();
      }
    }
  };

  // creates a manifest of changes
  resolve = () => {
    UIState.reset();
    const { changes } = this;
    if (changes) {
      const hasChanges = Object.keys(changes).length > 0;

      if (hasChanges) {
        // create all changes
        const updateBatch = Object.keys(changes || {}).map((groupChangeKey) => {
          const group = this.selection.find(
            (item) => item.id === groupChangeKey
          );

          let change: UpdateSvgGroupPayload = {
            id: groupChangeKey,
            update: {
              ...changes[groupChangeKey],
            },
          };

          // ignore resizing for shapes and points
          if ([Shape.POINT, Shape.SHAPE].includes(group?.tool?.type!)) {
            const value =
              'deltaPos' in changes[groupChangeKey]
                ? changes[groupChangeKey].deltaPos
                : changes[groupChangeKey].value.deltaPos;
            change = {
              ...change,
              update: {
                key: SvgGroupUpdateKey.RelativePosition,
                value,
              },
            };
          }

          return change;
        });

        // apply the update
        const update = this.createAction(UpdateSvgGroupAction);
        update.apply(updateBatch);
      }
    }
  };

  resolveWithoutUpdate = () => {
    UIState.reset();
    const { changes } = this;
    if (changes) {
      const hasChanges = Object.keys(changes).length > 0;

      if (hasChanges) {
        // create all changes
        const updateBatch = Object.keys(changes || {}).map((groupChangeKey) => {
          //For most groups, resize by changing transform and position
          return {
            id: groupChangeKey,
            update: {
              ...changes[groupChangeKey],
            },
          };
        });

        return updateBatch;
      }
    }
    return [];
  };
}
